import { useTheme } from '@emotion/react';
import HorizontalTabs from '../../../../components/tabs/HorizontalTabs';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext';
import { BackendConfigs } from '../../../../config.environment';
import { ProjectDataType } from '../../../../types/Dashboard';
import { APIResponse } from '../../../../types/Response';
import { ContentDialogBox } from '../../../../components/dialog-box';
import { Button, Card, CardActions, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import ProjectDescription from '../../Projects/Project/Description/Description';
import { toast } from 'react-toastify';
import { routes } from '../../../../routes/routes';
import { LessonDataType, LessonWithStudentUnlockStatusType } from '../../../../types/Lesson';
import TotalProgressComponent from '../../Lessons/TotalProgress';
import LeaderboardComponent from '../../Lessons/Leaderboard';
import EducCOBOTLockIcon from '../../../../assets/icons/LockIcon';
import { StudentDataResponseType } from '../../../../types/User';

export interface LessonsProps {
}


export default function StudentLessons(props: LessonsProps) {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const { userId, OTP, standard, division } = useParams()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [currentFilter, setCurrentFilter] = React.useState("all")
    const [currentGrade, setCurrentGrade] = React.useState(0)
    const [lessons, setLessons] = React.useState<LessonWithStudentUnlockStatusType[]>([])
    const [backupLessons, setBackupLessons] = React.useState<LessonWithStudentUnlockStatusType[]>([])
    const [searchText, setSearchText] = React.useState("")
    const [studentData, setStudentData] = React.useState<StudentDataResponseType | null>(null)
    const [reverseLessons, setReverseLessons] = React.useState<LessonWithStudentUnlockStatusType[]>([])
    // async function getStudentData() {
    //     if (!!user.userData) {
    //         try {
    //             const response = await fetch(`${BackendConfigs.educobotBackend}/blockly/get-user-details`, {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "Application/json"
    //                 },
    //                 body: JSON.stringify({
    //                     userId: user.userData?.id
    //                 })
    //             })
    //             const result = await response.json() as StudentDataResponseType
    //             if (!!result) {
    //                 // console.log(result);
    //                 // setStandard(result.student.sdClass)
    //                 // setDivision(result.student.sdDiv)
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }
    // useEffect(() => {
    //     if (user && user.userData) {
    //         getStudentData()
    //     }
    // }, [user])
    async function fetchLessons() {
        handleGlobalLoading(true, "Fetching lessons")
        setLessons([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLessonsWithStudentProgressAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId: userId,
                    schoolId: user.userData?.schoolId,
                    standard: standard,
                    division: division,
                    OTP: OTP
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    setLessons(result.data)
                    setBackupLessons(result.data)
                    setReverseLessons([...result.data].reverse())
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    React.useEffect(() => {
        if (user && user.userData && standard && division && OTP && userId) {
            fetchLessons()
        }
    }, [user, standard, division, OTP, userId])

    useEffect(() => {
        if (currentFilter === "all") {
            setLessons(backupLessons)
        } else {
            setLessons(backupLessons.filter((lesson) => !!lesson.editors.some((editor) => editor.includes(currentFilter))))
        }
    }, [currentFilter])

    useEffect(() => {
        if (searchText === "") {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) =>
                lesson.name.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.description.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.lessonNumber.toString().includes(searchText.toLowerCase()) ||
                lesson.lessonId.toString().includes(searchText.toLowerCase())
            ))
        }
    }, [searchText])
    useEffect(() => {
        if (currentGrade === 0) {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) => lesson.lessonLevel === currentGrade
            ))
        }
    }, [currentGrade])



    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // padding: "15px",
                gap: "10px",
            }}>
                {/* <LeaderboardComponent lessons={lessons as any} /> */}
                <TotalProgressComponent lessons={lessons as any} />

            </div>

            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: settings.screen === "mobile" ? "column-reverse" : "row",
                padding: "15px"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                }}>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "all" ? "contained" : "outlined"} onClick={() => setCurrentFilter("all")}>
                        All
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "theory" ? "contained" : "outlined"} onClick={() => setCurrentFilter("theory")}>
                        Theory
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "assembly" ? "contained" : "outlined"} onClick={() => setCurrentFilter("assembly")}>
                        Assembly
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "circuit" ? "contained" : "outlined"} onClick={() => setCurrentFilter("circuit")}>
                        Circuit
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "micropython" ? "contained" : "outlined"} onClick={() => setCurrentFilter("micropython")}>
                        Code
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "iot" ? "contained" : "outlined"} onClick={() => setCurrentFilter("iot")}>
                        IOT
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "ai" ? "contained" : "outlined"} onClick={() => setCurrentFilter("ai")}>
                        AI
                    </Button>
                </div>

            </div>



            <div style={{
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "start",
                flexWrap: "wrap",
            }}>
                {
                    lessons.map((lesson) => {
                        if (!lesson.isUnlocked) return null
                        return (
                            <LessonCard key={lesson._id} title={lesson.name} image={lesson.thumbnail} lesson={lesson} />
                        )
                    })
                }
                {
                    lessons.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>
                        <p style={{ textAlign: "center" }}>No Lesson Found</p>
                    </Card>
                }
            </div>
        </div>
    );
}

function LessonCard({ image, title, lesson }: { image: string, title: string, lesson: LessonWithStudentUnlockStatusType }) {
    const navigate = useNavigate()
    const [isHovering, setIsHovering] = React.useState(false)
    const { settings } = useContext(SettingContext)
    const [isReadMore, setIsReadMore] = React.useState(false)

    function openLesson() {
        navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
    }



    const getShadow = (lesson: LessonWithStudentUnlockStatusType) => {
        if (lesson.isArchived) {
            return "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)";
        } else if (
            lesson.nextLessonToUnlock
        ) {
            return "0px 8px 16px 0px rgba(51, 102, 255, 0.24)";
        } else
            return "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)";
    }

    const getButtonsJSX = (
        className: string,
    ) => {
        if (lesson.isCompleted) {
            return (
                <Button
                    className="openLessonBtn"
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={className == "blueCard" ? { marginY: "10px", borderRadius: "7px", } : { marginY: "10px", borderRadius: "7px" }}
                    onClick={() => openLesson()}
                >
                    Completed
                </Button>
            );
        }
        if (lesson.nextLessonToUnlock || lesson.isUnlocked) {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        variant="contained"
                        fullWidth
                        color="error"
                        sx={className == "blueCard" ? { marginY: "10px", borderRadius: "7px", } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => openLesson()}
                    >
                        Start Lesson
                    </Button>

                </>
            );
        }
        if (lesson.isInCurrentStandard && lesson.isUnlocked) {
            return (
                <>
                    <Button
                        className="unlockLessonBtn"
                        variant="contained"
                        fullWidth
                        color="error"
                        sx={{ marginY: "10px", borderRadius: "7px" }}
                    // onClick={() => openLesson()}
                    >
                        <EducCOBOTLockIcon fill1="#fff" fill2="#fff" width="20px" height="20px" />
                    </Button>

                </>
            );
        }
        if (lesson.isInCurrentStandard && !lesson.isUnlocked) {
            return (
                <>
                    <Button
                        className="unlockLessonBtn"
                        variant="contained"
                        fullWidth
                        color="error"
                        sx={{ marginY: "10px", borderRadius: "7px" }}
                    // onClick={() => openLesson()}
                    >
                        Not Unlocked
                    </Button>

                </>
            );
        }

        else {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        variant={lesson.isCompleted ? "contained" : "outlined"}
                        fullWidth
                        color="inherit"
                        sx={className == "blueCard" ? { marginY: "10px", backgroundColor: "#fff", color: "black", borderRadius: "7px" } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => openLesson()}
                    >
                        Open
                    </Button>
                </>
            );
        }
    };







    return (
        <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{
            width: "min(300px, 100%)",
            minHeight: "min(250px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "20px",
            margin: "15px",
            background: lesson.nextLessonToUnlock || lesson.nextLessonToUnlock ? "linear-gradient(135deg, #84A9FF 0%, #1939B7 100%)" : isHovering ? eduCOBOTColorScheme.green : undefined,
            color: lesson.nextLessonToUnlock || lesson.nextLessonToUnlock ? "white" : isHovering ? "white" : undefined,

            backdropFilter: "blur(2px)",
            padding: "20px 10px",
            transition: "all 0.2s ease-in-out",
            boxShadow: getShadow(lesson),
            opacity: lesson.isArchived || !lesson.isUnlocked ? 0.5 : 1,
        }}
            sx={{
                ":hover": {
                    scale: "1.1",
                }
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "2px",
                padding: "10px"
            }}>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    {
                        lesson.lessonNumber
                    }
                </div>
                <h4 style={{
                    width: "min(300px, 100%)",
                    // color: eduCOBOTColorScheme.black,
                    textAlign: "center",

                }} >
                    {title}
                </h4>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    G
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                gap: "2px"
            }}>
                {
                    lesson.tags.map((tag, index) => {
                        return (
                            <p key={index} style={{
                                textAlign: "center",
                                color: isHovering ? eduCOBOTColorScheme.black : eduCOBOTColorScheme.gray,
                                background: isHovering ? eduCOBOTColorScheme.white : "#F2F3F5",
                                borderRadius: "15px",
                                margin: "5px 2px",
                                padding: "3px 10px",
                                fontSize: "0.8em",
                            }} >
                                {tag}
                            </p>
                        )
                    })
                }
            </div>
            <img src={image} alt="" style={{
                maxWidth: "80%",
                maxHeight: "120px",
                margin: "10px auto"
            }} />

            <p style={{
                fontSize: "0.8em",
                // color: isHovering ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.gray,
                padding: "10px 0px",
                textAlign: "justify"
            }}>
                {isReadMore ? lesson.description : lesson.description.slice(0, 100)} <Button onClick={() => setIsReadMore(!isReadMore)} size='small' variant='text' sx={{ textTransform: "none" }}>
                    {isReadMore ? "Read Less" : "Read More"}
                </Button>
            </p>
            <div style={{
                width: "100%",
                padding: "0 10px",
                display: "flex",
                gap: "10px",
            }}>
                {getButtonsJSX("blueCard")}
                {/* <Button size='small' fullWidth onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
                }} variant={'outlined'} sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    color: settings.themeMode === "dark" ? eduCOBOTColorScheme.black : undefined,
                    background: eduCOBOTColorScheme.white,
                    ":hover": {
                        background: eduCOBOTColorScheme.white,
                    }
                }}>
                    Open
                </Button> */}
            </div>
        </Card>
    )
}




{/* <TotalProgressComponent lessons={lessons} progressData={{
                coins: {
                    gained: 50,
                    outof: 100,
                    maximumAchievableForYou: 80
                },
                lessons: {
                    completed: 15,
                    outof: 20,
                },
                assemblyProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                circuitProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                codeProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                iotProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                stats: {
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    },
                    fastestLesson: {
                        lessonID: "9dde0a0c-c9a5-4239-9085-9699f0ad0c65",
                        timeTakenInSeconds: 1000
                    },
                    slowestLesson: {
                        lessonID: "9dde0a0c-c9a5-4239-9085-9699f0ad0c65",
                        timeTakenInSeconds: 1000
                    },
                    lastInteraction: {
                        lessonID: "9dde0a0c-c9a5-4239-9085-9699f0ad0c65",
                        timeTakenInSeconds: 1000,
                        section: "assembly"
                    }
                }
            }} /> */}
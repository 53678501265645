import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext'
import { deployCode } from '../../../components/micropython-block-editor/RoboticHandler'
import MicroPythonEditor from '../../../components/micropython-block-editor'
import CodeEditor from '../../../components/code-editor'
import { ContentDialogBox } from '../../../components/dialog-box'
import { ConnectionContext, handleMachineCall } from '../../../context/ConnectionContext'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { toast } from 'react-toastify'
import { sendMessageToReactNative } from '../../../utils/ReactNativeHandler'
type Props = {}




export function getWiFi() {
    const isWiFi_data = localStorage.getItem("isWiFi")
    if (isWiFi_data) {
        if (isWiFi_data === "true") {
            return true
        } else {
            return false
        }
    }
    return false
}
export default function MicroPythonMobileEditorPage({ }: Props) {
    const [xml, setXml] = React.useState('')
    const [code, setCode] = React.useState(``)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(false)
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isMatrixCodeResolverOpen, setIsMatrixCodeResolverOpen] = React.useState(false)
    const [isExamplesOpen, setIsExamplesOpen] = React.useState(false)


    async function deployCodeOnMachine() {
        sendMessageToReactNative(code, "code")
    }



    return (
        <>
            <div>
                <div style={{
                    display: "flex",
                    height: "99vh",
                }}>
                    {
                        isBlockEditing ?
                            <MicroPythonEditor
                                setCode={(code) => {
                                    // console.log(code)
                                    isBlockEditing && setCode(code)
                                }}
                                setXml={(xml) => {
                                    // console.log(xml)
                                    setXml(xml)
                                }}
                                isLocalSave={true}
                                setNewBlocks={(value) => {
                                    // console.log(value);
                                    isControllerOpen && setNewBlocks(value)
                                }}
                            /> : <CodeEditor code={code}
                                setCode={setCode} />
                    }
                    <div style={{
                        position: "fixed",
                        width: "100%",
                        bottom: "0",
                        zIndex: 9999999999999,
                        background: settings.themeMode == "light" ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.black,
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "5vh",
                            zIndex: 999999999
                        }}>
                            <div>
                                <Tooltip title="Deploy">
                                    <Button style={{
                                        border: "none",
                                    }} onClick={() => {
                                        deployCodeOnMachine()
                                    }}>
                                        <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                            width: "20px"
                                        }} alt="" />
                                    </Button>
                                </Tooltip>

                                {/* <Tooltip title={isBlockEditing ? "Code Editor" : "Block Editor"}>
                                    <Button variant='outlined' onClick={() => {
                                        setIsBlockEditing(!isBlockEditing)
                                    }} style={{
                                        border: "none",
                                        width: "50px"
                                    }}>
                                        {
                                            isBlockEditing ?
                                                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Block_Coding.png" height={20} alt="" />
                                                : <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Syntax_Code.png" height={20} alt="" style={{
                                                    filter: "invert(1)",
                                                }} />
                                        }
                                    </Button>
                                </Tooltip> */}
                            </div>
                            {/* <Typography sx={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                <Tooltip title="Controller">
                                    <Button style={{
                                        border: "none",
                                    }} onClick={() => {
                                        setIsControllerOpen(!isControllerOpen)
                                    }}>
                                        <img src="https://storage.googleapis.com/innovator-resourses/assets/Game%20Pad.png" style={{
                                            width: "25px"
                                        }} alt="" />
                                    </Button>
                                </Tooltip>
                                {
                                    code.includes("max7219") &&
                                    <Tooltip title="Controller">
                                        <Button style={{
                                            border: "none",
                                        }} onClick={() => {
                                            setIsMatrixCodeResolverOpen(!isMatrixCodeResolverOpen)
                                        }}>
                                            <Grid4x4Icon />
                                        </Button>
                                    </Tooltip>
                                }
                            </Typography> */}
                        </div>
                        <div>

                        </div>
                    </div>

                </div>
                <ContentDialogBox
                    isOpen={isControllerOpen}
                    onClose={() => { setIsControllerOpen(false) }}
                    content={
                        <>
                            <ControllerComponent newBlocks={newBlocks} />
                        </>
                    }
                    title='Your Controller'
                    maxWidth="xs"
                />
                <ContentDialogBox
                    isOpen={isMatrixCodeResolverOpen}
                    onClose={() => { setIsMatrixCodeResolverOpen(false) }}
                    content={
                        <>
                            <MatrixDataResolver />
                        </>
                    }
                    title='ByteArray Producer'
                    maxWidth="lg"
                />
                <ContentDialogBox
                    isOpen={isExamplesOpen}
                    onClose={() => { setIsExamplesOpen(false) }}
                    isTransition={true}
                    transitionDirection='left'
                    content={
                        <>
                            <Typography style={{
                                display: "flex",
                            }}>
                                <Card style={{
                                    width: "250px",
                                    minHeight: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "15px",
                                    margin: "10px",
                                    borderRadius: "20px"
                                }}>
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                        maxWidth: "100px",
                                    }} alt="" />
                                    <h5 style={{
                                        margin: "10px"
                                    }}>Smart Car</h5>
                                    <Button variant='outlined' color='success' sx={{
                                        textTransform: "none",
                                        fontSize: "10px"
                                    }} size='small'>
                                        Try
                                    </Button>
                                </Card>
                                <Card style={{
                                    width: "250px",
                                    minHeight: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "15px",
                                    margin: "10px",
                                    borderRadius: "20px"
                                }}>
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                        maxWidth: "100px",
                                    }} alt="" />
                                    <h5 style={{
                                        margin: "10px"
                                    }}>Smart House</h5>
                                    <Button variant='outlined' color='success' sx={{
                                        textTransform: "none",
                                        fontSize: "10px"
                                    }} size='small'>
                                        Try
                                    </Button>
                                </Card>
                                <Card style={{
                                    width: "250px",
                                    minHeight: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "15px",
                                    margin: "10px",
                                    borderRadius: "20px"
                                }}>
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                        maxWidth: "100px",
                                    }} alt="" />
                                    <h5 style={{
                                        margin: "10px"
                                    }}>Keypad</h5>
                                    <Button variant='outlined' color='success' sx={{
                                        textTransform: "none",
                                        fontSize: "10px"
                                    }} size='small'>
                                        Try
                                    </Button>
                                </Card>
                                <Card style={{
                                    width: "250px",
                                    minHeight: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "15px",
                                    margin: "10px",
                                    borderRadius: "20px"
                                }}>
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                        maxWidth: "100px",
                                    }} alt="" />
                                    <h5 style={{
                                        margin: "10px"
                                    }}>DHT11</h5>
                                    <Button variant='outlined' color='success' sx={{
                                        textTransform: "none",
                                        fontSize: "10px"
                                    }} size='small'>
                                        Try
                                    </Button>
                                </Card>
                            </Typography>
                        </>
                    }
                    actions={<></>}
                    title='Samples'
                    fullScreen
                />
            </div>
        </>

    )
}


export function MatrixDataResolver() {
    const [row, setRow] = useState(8)
    const [column, setColumn] = useState(8)
    const [DataArray, setDataArray] = useState<string[]>([])
    const [isChanged, setIsChanged] = useState(false)
    useEffect(() => {
        setDataArray(Array(row * column).fill("0"));
    }, [])
    return <>
        <Typography display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
            <Typography>


            </Typography>
            <Typography style={{
                display: "grid",
                gridTemplateColumns: Array(column).fill("1fr").join(" "),
            }}>
                {
                    Array(row * column).fill(1).map((_, i) => {
                        return <div key={i} style={{
                            width: "30px",
                            height: "30px",
                            border: "1px solid black",
                            cursor: "pointer",
                            background: DataArray[i] === "1" ? "black" : "white",
                        }}
                            onClick={() => {
                                console.log(i);
                                // console.log(DataArray[i]);
                                setIsChanged(!isChanged);
                                setDataArray((prev) => {
                                    const newArray = [...prev]
                                    newArray[i] = prev[i] === "1" ? "0" : "1";
                                    return newArray
                                })
                            }}
                        ></div>
                    })
                }

            </Typography>
            <Typography key={isChanged ? "1" : "0"} style={{
                display: "grid",
                gridTemplateColumns: Array(column).fill("1fr").join(" "),
                position: "relative",
                marginTop: "20px",
            }}>
                {
                    Array(row * column).fill(1).map((_, i) => {
                        return <>
                            <div key={i} style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                                textAlign: "center",
                            }}
                            >{DataArray[i]} </div>
                            {
                                (i + 1) % 8 === 0 && <Button onClick={() => {
                                    navigator.clipboard.writeText(DataArray.slice(i - 8 + 1, i + 1).join(""));
                                    toast.info(DataArray.slice(i - 8 + 1, i + 1).join("") + " is copied to clipboard")
                                }} sx={{
                                    // width: "20px",
                                    margin: 0,
                                    padding: 0,
                                    // minWidth: "20px",
                                    position: "absolute", right: "-60px",
                                    top: `${Math.floor(i / 8) * 20}px`,
                                }}>
                                    <ContentCopyIcon sx={{
                                        width: "15px"
                                    }} />
                                </Button>
                            }
                        </>
                    })
                }

            </Typography>
        </Typography>
    </>
}


export function ControllerComponent({
    newBlocks
}: {
    newBlocks: string[]
}) {

    const { connection } = useContext(ConnectionContext)
    async function handleButtonClick(value: string) {
        handleMachineCall(connection, value)
    }
    return (
        <Typography style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            rowGap: 50,
        }}>
            {
                newBlocks.map(block => {
                    const newBlock = block.replaceAll("'", "")
                    return <Button variant='contained' color={'info'} style={{
                        width: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        borderRadius: "15px"
                    }} sx={{
                        textTransform: "none"
                    }} onClick={() => {
                        handleButtonClick(newBlock)
                    }} >
                        {newBlock}
                    </Button>
                })
            }
        </Typography>
    )
}
import React, { useContext, useEffect, useState } from 'react'
import MicroPythonEditor from '../../../../../../components/micropython-block-editor'
import { ProjectDataType } from '../../../../../../types/Dashboard'
import CodeEditor from '../../../../../../components/code-editor'
import { motion } from 'framer-motion'
import { framerAnimation } from '../../../../../../theme/animation/MotionConfigs'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { deployCode, getWiFi } from '../../../../../../components/micropython-block-editor/RoboticHandler'
import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import MicroPythonToolBox from '../../../../../../components/micropython-block-editor/configs/micropython-toolbox';
import { addDynamicTextReturningBlocks } from '../../../../../../components/micropython-block-editor/configs/code-generator'
import { toast } from 'react-toastify'
import { ConnectionContext, handleMachineCall } from '../../../../../../context/ConnectionContext'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { ContentDialogBox } from '../../../../../../components/dialog-box'
import { PythonIDEContextContext } from '../../../../../../context/PythonIDEContextContext'

type Props = {
    xml: string
    setXml: (value: string) => void
    code: string
    setCode: (value: string) => void
    project: ProjectDataType
    setIsCircuitDesignerOpen: (value: boolean) => void
    keys?: {
        [key: string]: string
    },
    setNewBlocks?: (newBlocks: string[]) => void,
    saveMicropythonCode?: () => {}
}


function CodeGenerator({
    code,
    setCode,
    xml,
    setXml,
    project,
    keys,
    setNewBlocks,
    saveMicropythonCode
}: Props) {
    const [initialXML, setInitialXML] = React.useState<string>(xml)
    const [microPythonToolBox, setMicroPythonToolBox] = React.useState<typeof MicroPythonToolBox>(MicroPythonToolBox)
    const [newBlocks, setNewControllerBlocks] = useState<string[]>([])

    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(false)
    const [isWiFi, setIsWiFi] = React.useState<boolean>(getWiFi())
    const { toggleTerminal, TerminalButton, RestartButton, DeployCodeOnIDEButton, PermanentStorageButton, ControllerButton, MatrixCodeResolverButton } = useContext(PythonIDEContextContext)

    const [isRunning, setIsRunning] = React.useState<boolean>(false)
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isMatrixCodeResolverOpen, setIsMatrixCodeResolverOpen] = React.useState(false)
    const { connection, handleConnectionTab } = useContext(ConnectionContext)
    const [isConnectionWarning, setIsConnectionWarning] = React.useState(false)
    useEffect(() => {
        localStorage.setItem("isWiFi", String(isWiFi))
    }, [isWiFi])
    useEffect(() => {
        // if(project.project.isCreatedFromBlueprint){
        //     setIsBlockEditing(true)
        // }
    }, [])


    useEffect(() => {
        if (keys) {
            setMicroPythonToolBox(addDynamicTextReturningBlocks(
                Object.keys(keys).map((key) => {
                    return {
                        name: key,
                        value: keys[key]
                    }
                }) as { name: string; value: string }[],
                microPythonToolBox,
                "IOT-Resources",
                "red",
                "IOT"
            ))
        }
    }, [keys])

    useEffect(() => {
        if (project) {
            // if (project.project.variables.length > 0)

            setMicroPythonToolBox((microPythonToolBox) => {
                return addDynamicTextReturningBlocks(
                    project.project.variables.map((variable) => {
                        return {
                            name: variable.name,
                            value: variable.name
                        }
                    }) as { name: string; value: string }[],
                    microPythonToolBox,
                    "IOT-Variables",
                    "red",
                    "IOT"
                )
            })
        }
    }, [project])


    return (
        <motion.div
            initial={framerAnimation.right2left.initial}
            animate={framerAnimation.right2left.animate}
            exit={framerAnimation.right2left.exit}
            transition={framerAnimation.right2left.transition}
        >
            <div
                style={{
                    height: "100dvh",
                    width: "100vw",
                    display: "flex",
                }}
            >
                <MicroPythonEditor
                    key={initialXML}
                    setCode={(code) => {
                        // console.log(code)
                        isBlockEditing && setCode(code)
                    }}
                    setXml={(xml) => {
                        // console.log(xml)
                        setXml(xml)
                    }}
                    initialXML={initialXML}
                    setNewBlocks={(value) => {
                        // console.log(value);
                        setNewBlocks && setNewBlocks(value)
                        isControllerOpen && setNewControllerBlocks(value)
                    }} />
                <div style={{
                    height: "100dvh",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "6vh",
                        zIndex: 999999999
                    }}>
                        <div>

                            <DeployCodeOnIDEButton code={code} />
                            <Tooltip title={isBlockEditing ? "Code Editor" : "Block Editor"}>

                                <Button id='CodeOrBlockToggler' variant='outlined' onClick={() => {
                                    setIsBlockEditing(!isBlockEditing)
                                }} style={{
                                    border: "none",
                                    width: "50px"
                                }}>
                                    {
                                        isBlockEditing ?
                                            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Block_Coding.png" height={20} alt="" />
                                            : <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Syntax_Code.png" height={20} alt="" style={{
                                                filter: "invert(1)",
                                            }} />
                                    }
                                </Button>
                            </Tooltip>
                            <Button
                                id='saveCodeButton'
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    saveMicropythonCode && saveMicropythonCode()
                                }}
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    fontWeight: "bolder",
                                    fontSize: "10px"
                                }}
                            >
                                Save Code
                            </Button>
                        </div>
                        <Typography sx={{
                            display: "flex",
                            alignItems: "center",
                            gap:"10px"
                        }}>
                            <RestartButton />
                            <TerminalButton />
                            <PermanentStorageButton  code={code}/>
                            <ControllerButton  />
                            <MatrixCodeResolverButton code={code} />
                        </Typography>


                    </div>
                    <div style={{
                        height: "94dvh",
                    }}>
                        <CodeEditor code={code}
                            setCode={setCode} isDisabled={isBlockEditing} />

                    </div>
                </div>
                {/* {
                    settings.globalLoading.loading && isRunning && <>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            position: "fixed",
                            bottom: "0",
                            right: "0",
                            background: "#224FBC",
                            zIndex: 9999999999999,
                        }}>
                            <h4 style={{ textAlign: "center", width: "100%", background: "#B9B9B9", padding: "10px" }}>
                                Terminal
                            </h4>
                            <div style={{

                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center",
                            }}>


                                <Card style={{
                                    width: "100%",
                                    display: "flex", flexDirection: "column",
                                    margin: "auto",
                                    height: "25dvh",
                                    padding: "20px",
                                    overflow: "auto",
                                    background: "#224FBC",
                                }}>
                                    {
                                        console_messages && console_messages.map((message, index) => <p key={index} style={{
                                            color: "#B9B9B9"
                                        }}>&gt; {message}</p>)
                                    }

                                </Card>
                            </div>
                        </div>
                    </>
                } */}

            </div>
        </motion.div>
    )
}

export default CodeGenerator

import { Finger, FingerCurl, FingerDirection, GestureDescription } from 'fingerpose';

export const indexFinger = new GestureDescription('index-finger');
indexFinger.addCurl(Finger.Index, FingerCurl.NoCurl, 1.0)
indexFinger.addDirection(Finger.Index, FingerDirection.VerticalUp, 0.9);
for (let finger of [Finger.Middle, Finger.Ring, Finger.Pinky, Finger.Thumb]) {
    indexFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    indexFinger.addDirection(finger, FingerDirection.VerticalDown, 0.50);
}


export const middleFinger = new GestureDescription('middle-finger');
middleFinger.addCurl(Finger.Middle, FingerCurl.NoCurl, 1.0)
middleFinger.addDirection(Finger.Middle, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Index, Finger.Ring, Finger.Pinky, Finger.Thumb]) {
    middleFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    middleFinger.addDirection(finger, FingerDirection.VerticalDown, 0.50);
}

export const ringFinger = new GestureDescription('ring-finger');
ringFinger.addCurl(Finger.Ring, FingerCurl.NoCurl, 1.0)
ringFinger.addDirection(Finger.Ring, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Index, Finger.Middle, Finger.Pinky, Finger.Thumb]) {
    ringFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    ringFinger.addDirection(finger, FingerDirection.VerticalDown, 0.50);
}

export const pinkyFinger = new GestureDescription('pinky-finger');
pinkyFinger.addCurl(Finger.Pinky, FingerCurl.NoCurl, 1.0)
pinkyFinger.addDirection(Finger.Pinky, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Index, Finger.Middle, Finger.Ring, Finger.Thumb]) {
    pinkyFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    pinkyFinger.addDirection(finger, FingerDirection.VerticalDown, 0.50);
}

export const thumbFinger = new GestureDescription('thumb-finger');
thumbFinger.addCurl(Finger.Thumb, FingerCurl.NoCurl, 1.0)
thumbFinger.addDirection(Finger.Thumb, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Index, Finger.Middle, Finger.Ring, Finger.Pinky]) {
    thumbFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    thumbFinger.addDirection(finger, FingerDirection.VerticalDown, 0.99);
}



export const indexAndMiddleFinger = new GestureDescription('index-and-middle-finger');
indexAndMiddleFinger.addCurl(Finger.Index, FingerCurl.NoCurl, 1.0)
indexAndMiddleFinger.addDirection(Finger.Index, FingerDirection.VerticalUp, 0.9);
indexAndMiddleFinger.addCurl(Finger.Middle, FingerCurl.NoCurl, 1.0)
indexAndMiddleFinger.addDirection(Finger.Middle, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Ring, Finger.Pinky, Finger.Thumb]) {
    indexAndMiddleFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    indexAndMiddleFinger.addDirection(finger, FingerDirection.VerticalDown, 0.9);
}


export const indexAndMiddleAndRingFinger = new GestureDescription('index-and-middle-and-ring-finger');
indexAndMiddleAndRingFinger.addCurl(Finger.Index, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingFinger.addDirection(Finger.Index, FingerDirection.VerticalUp, 0.9);
indexAndMiddleAndRingFinger.addCurl(Finger.Middle, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingFinger.addDirection(Finger.Middle, FingerDirection.VerticalUp, 0.9);
indexAndMiddleAndRingFinger.addCurl(Finger.Ring, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingFinger.addDirection(Finger.Ring, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Pinky, Finger.Thumb]) {
    indexAndMiddleAndRingFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    indexAndMiddleAndRingFinger.addDirection(finger, FingerDirection.VerticalDown, 0.9);
}

export const indexAndMiddleAndRingAndPinkyFinger = new GestureDescription('index-and-middle-and-ring-and-pinky-finger');
indexAndMiddleAndRingAndPinkyFinger.addCurl(Finger.Index, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingAndPinkyFinger.addDirection(Finger.Index, FingerDirection.VerticalUp, 0.9);
indexAndMiddleAndRingAndPinkyFinger.addCurl(Finger.Middle, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingAndPinkyFinger.addDirection(Finger.Middle, FingerDirection.VerticalUp, 0.9);
indexAndMiddleAndRingAndPinkyFinger.addCurl(Finger.Ring, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingAndPinkyFinger.addDirection(Finger.Ring, FingerDirection.VerticalUp, 0.9);
indexAndMiddleAndRingAndPinkyFinger.addCurl(Finger.Pinky, FingerCurl.NoCurl, 1.0)
indexAndMiddleAndRingAndPinkyFinger.addDirection(Finger.Pinky, FingerDirection.VerticalUp, 0.9);

for (let finger of [Finger.Thumb]) {
    indexAndMiddleAndRingAndPinkyFinger.addCurl(finger, FingerCurl.FullCurl, .9);
    indexAndMiddleAndRingAndPinkyFinger.addDirection(finger, FingerDirection.VerticalDown, 0.9);
}

import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import LEDOnHand from './LEDOnHand'
import { SettingContext } from '../../../../../context/SettingsContext'
import { ConnectionContext, handleMachineCall } from '../../../../../context/ConnectionContext'
import { getWiFi } from '../../../../Tools/MicropythonEditor'
import { deployCode } from '../../../../../components/micropython-block-editor/RoboticHandler'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

type Props = {}




function LEDsONFingerPage({ }: Props) {
    const [userCurrentChoice, setUserCurrentChoice] = useState<string | null>(null)
    const [executedChoice, setExecutedChoice] = useState<string | null>(null)
    const [code, setCode] = React.useState(`import network
import socket
import json
import time
import machine
import machine
from machine import Pin
from machine import Pin

IndicatorPin = machine.Pin(2, machine.Pin.OUT)
ssid = 'Rajtaru'
password = '1234554322'
wlan = network.WLAN(network.STA_IF)
ipaddress=""

def connectToWiFi():
    global ipaddress
    wlan.active(True)
    print('Attempting to connect to the network...')
    wlan.connect(ssid, password)        
    max_wait = 10
    while max_wait > 0 and not wlan.isconnected():
        max_wait -= 1
        print('waiting for connection...')
        time.sleep(1)
    if not wlan.isconnected():
        print('Network Connection has failed')
    else:
        print('Connected to the network successfully.')
        IndicatorPin.value(1)
        time.sleep(0.2)
        IndicatorPin.value(0)
        time.sleep(0.2)
        IndicatorPin.value(1)
        time.sleep(0.2)
        IndicatorPin.value(0)
        status = wlan.ifconfig()
        ipaddress=status[0]
        with open("config.json", "w") as f:
            json.dump({
            "wifiName":ssid,
            "wifiPassword":password,
            "IPAddress":ipaddress
            }, f)
        print( 'Enter this address in browser = ' + status[0] )

led13 = Pin(13, Pin.OUT)
led12 = Pin(12, Pin.OUT)
led14 = Pin(14, Pin.OUT)
led2 = Pin(2, Pin.OUT)
def turnOnLed(first,second,third,forth):
    led13.value(first)
    led12.value(second)
    led14.value(third)
    led2.value(forth)
connectToWiFi()
addr = socket.getaddrinfo('0.0.0.0', 80)[0][-1]
s = socket.socket()
s.setblocking(0)
s.bind(addr)
s.listen(1)
header = """HTTP/1.0 200 OK
Content-Type: text/html; charset=utf-8
Access-Control-Allow-Origin: *;

"""

while True:
    try:
        cl, addr = s.accept()
        print('client connected from', addr)
        request = cl.recv(1024)
        print(request)
        request = str(request)
        response = 'LED_ON_HAND'
        if request.find('/'+'index-finger')==6:
            turnOnLed(1,0,0,0)
        elif request.find('/'+'index-and-middle-finger')==6:
            turnOnLed(1,1,0,0)
        elif request.find('/'+'index-and-middle-and-ring-finger')==6:
            turnOnLed(1,1,1,0)
        elif request.find('/'+'index-and-middle-and-ring-and-pinky-finger')==6:
            turnOnLed(1,1,1,1)
        else:
            turnOnLed(0,0,0,0)
        cl.send(header)
        cl.sendall(response)
        cl.close()
    except Exception as e:
        print("Error: " + str(e))
        pass
`)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const { connection, handleConnectionTab } = useContext(ConnectionContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(true)
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isWiFi, setIsWiFi] = React.useState<boolean>(getWiFi())

    useEffect(() => {
        localStorage.setItem("isWiFi", String(isWiFi))
    }, [isWiFi])
    const [currentProcessMessage, setCurrentProcessMessage] = useState<string>("")
    const [console_messages, setConsole_messages] = useState<string[]>([])
    async function getProcessMessages() {
        try {
            const response = await fetch("http://localhost:8080/process_message", { method: "POST" })
            const json = await response.json()
            setCurrentProcessMessage(json.message)
        } catch (error) {
            // console.log(error);
        }
    }
    async function getConsoleMessages() {
        try {
            const response = await fetch("http://localhost:8080/console_outputs", { method: "POST" })
            const json = await response.json()
            // console.log(json.outputs);

            setConsole_messages(json.outputs.replace("[", "").replace("]", "").split(",").reverse())
        } catch (error) {
            // console.log(error);
        }
    }

    async function deployCodeOnMachine() {
        handleGlobalLoading(true)
        await deployCode(code, isWiFi)
        handleGlobalLoading(false)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            // console.log("hello");

            // if(settings.globalLoading) {
            // console.log("loading console");

            getProcessMessages()
            getConsoleMessages()
            // }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    const [LED_STATUS, setLED_STATUS] = useState<boolean[]>([
        false, false, false, false
    ])
    const [digitalPins, setDigitalPins] = useState<number[]>([13, 12, 14, 2])
    function setUserChoice(choice: string) {
        console.log("choice", choice);
        setUserCurrentChoice(choice)
        if (choice === "index-finger") {
            console.log("index-finger on");
            setLED_STATUS((preValue) => [true, false, false, false])
        }
        else if (choice === "index-and-middle-finger") {
            console.log("index-and-middle-finger on");
            setLED_STATUS((preValue) => [true, true, false, false])
        }
        else if (choice === "index-and-middle-and-ring-finger") {
            console.log("index-and-middle-and-ring-finger on");
            setLED_STATUS((preValue) => [true, true, true, false])
        }
        else if (choice === "index-and-middle-and-ring-and-pinky-finger") {
            console.log("index-and-middle-and-ring-and-pinky-finger on");
            setLED_STATUS((preValue) => [true, true, true, true])
        } else {
            console.log("turn off");
            setLED_STATUS((preValue) => [false, false, false, false])
        }
    }
    useEffect(() => {
        if (userCurrentChoice !== executedChoice && !!userCurrentChoice) {
            setExecutedChoice(userCurrentChoice)
            console.log("executedChoice", executedChoice);
            if (connection.wifi.isConnected) {
                console.log("calling on machine");
                handleMachineCall(connection, userCurrentChoice as string)
            }
        }
    }, [userCurrentChoice])
    return (
        <div style={{
            padding: '20px',
        }}>
            <div style={{
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "5vh",
                    zIndex: 999999999
                }}>
                    <div>
                        <Tooltip title="Deploy">
                            <Button style={{
                                border: "none",
                            }} onClick={() => {
                                deployCodeOnMachine()
                            }}>
                                <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                    width: "20px"
                                }} alt="" />
                            </Button>
                        </Tooltip>
                    </div>
                    <Typography sx={{
                        display: settings.screen==="mobile"?"none":"flex",
                        alignItems: "center",
                    }}>
                        <Tooltip title="Connection">
                            <Button variant='outlined' onClick={() => {
                                handleConnectionTab(!connection.isConnectionTab)
                            }} style={{
                                border: "none",
                                width: "50px",
                                marginRight: 30
                            }}>
                                <SettingsInputAntennaIcon />
                            </Button>
                        </Tooltip>
                        <h6>Cable</h6>
                        <Switch defaultChecked={isWiFi} onChange={() => setIsWiFi(!isWiFi)} checked={isWiFi} />
                        <h6>Wi-Fi</h6>
                    </Typography>
                </div>
                <div>

                </div>
            </div>
            {/* <Typography variant="h4" style={{
                textAlign: 'center',
                marginBottom: '20px',
            }}>{userCurrentChoice}</Typography> */}

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: "70dvh",
                flexWrap: 'wrap'
            }}>
                {
                    LED_STATUS.map((value, index) => {
                        return (
                            <Card key={index} elevation={5} style={{
                                width: settings.screen==="mobile"?"60px": '100px',
                                height: settings.screen==="mobile"?"87ps":'150px',
                                margin: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <img src={
                                    value ? "https://cdn-icons-png.flaticon.com/512/2338/2338767.png" : "https://cdn-icons-png.flaticon.com/512/2338/2338838.png"
                                } alt="" style={{
                                    width: '100%',
                                    height: '100%',
                                }} />
                                D{
                                    digitalPins[index]
                                }
                            </Card>
                        )
                    })
                }
            </div>
            <LEDOnHand setUserChoice={setUserChoice} />


            {
                settings.screen === "mobile" ? <></> :
                    <img src="https://storage.googleapis.com/educobot-robotics/images/logo/innov8r_Single_Line.png" style={{
                        maxWidth: "200px",
                        position: 'fixed',
                        bottom: '20px',
                        left: '20px',
                    }} alt="" />
            }

        </div>
    )
}

export default LEDsONFingerPage
import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import HardwareComponentCard from './ComponentCard';
import HardwareComponents from './Components';
import { Box, Typography } from '@mui/material';
import HardwarePackages from './Packages';
import GetCardColor from '../../../utils/GetCardColor';
// import MyCourses from './MyCourses';

export type HardwareComponentsPageProps = {
}

export default function HardwareComponentsPage(props: HardwareComponentsPageProps) {
    const colors = ["#FFC0CB" , "#FFD700" , "#00FF00" , "#87CEEB"]
    return (
        <div>
            <DashboardLayout title=''>
                <h1>Hardware Components</h1>
                <Typography component={'div'} style={{ width:"100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: 2, gap: 5  }}>
                    {HardwareComponents.map((component,index) => (
                        <HardwareComponentCard key={component.name} 
                            name={component.name}
                            description={component.description}
                            image={component.image}
                            type={component.type}
                            backgroundColor={GetCardColor(index+2)}
                            price={component.price}
                            amazonLink={component.amazonLink}
                        />
                    ))}
                </Typography>
                <h1>Hardware Packages</h1>
                <Typography component={'div'} style={{ width:"100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: 2, gap: 5  }}>
                    {HardwarePackages.map((component,index) => (
                        <HardwareComponentCard key={component.name} 
                            name={component.name}
                            description={component.description}
                            image={component.image}
                            type={component.type}
                            backgroundColor={GetCardColor(index+3)}
                            price={component.price}
                            amazonLink={component.amazonLink}
                        />
                    ))}
                </Typography>

            </DashboardLayout>
        </div>
    );
}

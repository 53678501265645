export const routes = {
    ROOT: '/',
    DASHBOARD: {
        MAIN: "/dashboard/main",
        PROJECTS: "/dashboard/projects",
        PROJECT: {
            CREATE: "/dashboard/project/create",
            DESCRIPTION: (projectId: string) => "/dashboard/project/description/" + projectId,
            SETTINGS: (projectId: string) => "/dashboard/project/settings/" + projectId,
            WORKSPACE: {
                CIRCUIT: (projectId: string) => "/dashboard/project/workspace/circuit/" + projectId,
                MONITOR: (projectId: string) => "/dashboard/project/workspace/monitor/" + projectId,
                MICROPYTHON: (projectId: string) => "/dashboard/project/workspace/micropython/" + projectId,
                AI: (projectId: string) => "/dashboard/project/workspace/ai/" + projectId,
                LOGIC_GATE: (projectId: string) => "/dashboard/project/workspace/logic-gate/" + projectId,
            }
        },
        HARDWARE_COMPONENTS:"/dashboard/hardware-components",
        MY_COURSES: "/dashboard/my-courses",
        HOW_IT_WORKS: "/dashboard/how-it-works",
        LESSONS: "/dashboard/lessons",
        MATERIALS: "/dashboard/materials",
        LESSON_OTP_SECTION: (userId: string, OTP: string, standard: string, division: string) => `/dashboard/lesson-otp-section/${userId}/${OTP}/${standard}/${division}`,
        LESSON: {
            DASHBOARD: (lessonId: string) => "/dashboard/lesson/overview/" + lessonId,
            SETTINGS: (lessonId: string) => "/dashboard/lesson/settings/" + lessonId,
            SUBMIT: (lessonId: string) => "/dashboard/lesson/submit/" + lessonId,
            WORKSPACE: {
                THEORY: (lessonId: string) => "/dashboard/lesson/workspace/theory/" + lessonId,
                ASSEMBLY: (lessonId: string) => "/dashboard/lesson/workspace/assembly/" + lessonId,
                CIRCUIT: (lessonId: string) => "/dashboard/lesson/workspace/circuit/" + lessonId,
                MICROPYTHON: (lessonId: string) => "/dashboard/lesson/workspace/micropython/" + lessonId,
                MONITOR: (lessonId: string) => "/dashboard/lesson/workspace/monitor/" + lessonId,
                AI: (lessonId: string) => "/dashboard/lesson/workspace/ai/" + lessonId,
                MCQ: (lessonId: string) => "/dashboard/lesson/workspace/quiz/" + lessonId,
                EXAM: (lessonId: string) => "/dashboard/lesson/workspace/exan/" + lessonId,
                BREADBOARD: (lessonId: string) => "/dashboard/lesson/workspace/breadboard/" + lessonId,
                COMPONENT_INFORMATIVE: (lessonId: string) => "/dashboard/lesson/workspace/component-informative/" + lessonId,
                LOGIC_GATE: (lessonId: string) => "/dashboard/lesson/workspace/logic-gate/" + lessonId,
            }
        },
        LESSON_BUILDER_LESSON_LIST: "/dashboard/lesson-builder-lessons",
        LESSON_BUILDER_LESSON_CREATE: "/dashboard/lesson-builder-lesson/create",
        LESSON_BUILDER: {
            WORKSPACE: (lessonId: string) => "/dashboard/lesson-builder/overview/" + lessonId,
        },
        BLUEPRINTS: "/dashboard/blueprints",
        BLUEPRINT: {
            CREATE: "/dashboard/project/create",
            DESCRIPTION: (blueprintId: string) => "/dashboard/project/description/" + blueprintId,
            SETTINGS: (blueprintId: string) => "/dashboard/project/settings/" + blueprintId,
            WORKSPACE: {
                CIRCUIT: (blueprintId: string) => "/dashboard/project/workspace/circuit/" + blueprintId,
                MONITOR: (blueprintId: string) => "/dashboard/project/workspace/monitor/" + blueprintId,
                MICROPYTHON: (blueprintId: string) => "/dashboard/project/workspace/micropython/" + blueprintId,
                AI: (blueprintId: string) => "/dashboard/project/workspace/ai/" + blueprintId,
                LOGIC_GATE: (blueprintId: string) => "/dashboard/project/workspace/logic-gate/" + blueprintId,
            }
        },
        TOOLS: "/dashboard/tools",
        MODELS: "/dashboard/models",
        MODEL: {
            CREATE: "/dashboard/model/create",
            WORKSPACE: (modelId: string) => "/dashboard/model/workspace/" + modelId,
        },
        SETTINGS: "/dashboard/settings",
    },
    SHARE: {
        PROJECT_MONITOR_SHARE: (projectId: string) => "/sharing/project/monitor/" + projectId
    },
    FUN_WITH_AI: {
        ROOT: "/fun-with-ai",
        LESSONS: {
            PLANT_DISEASE_DETECTOR: "/fun-with-ai/plant-disease-detector",
            AI_HAND_DRAWER: "/fun-with-ai/ai-hand-drawer",
            ROCK_PAPER_SCISSOR: "/fun-with-ai/rock-paper-scissor",
            LED_ON_HAND: "/fun-with-ai/leds-on-hand",
        },
    },
    FUN_WITH_ROBOTICS: {
        ROOT: "/fun-with-robotics",
        LESSON: (slug: string) => "/fun-with-robotics/lesson/" + slug,
        LESSONS: {
            ONE_TO_ZERO_PRINT_ON_SEVEN_SEGMENT: "/fun-with-robotics/lesson/one-to-zero-print-on-seven-segment",
            WIRELESS_LED_CONTROL: "/fun-with-robotics/lesson/wireless-led-control",
        },
    },
    FUN_WITH_GAMING: {
        ROOT: "/fun-with-gaming",
        LESSON: (slug: string) => "/fun-with-gaming/lesson/" + slug,
        LESSONS: {
            SNAKE_GAME_USING_PYGAME: "/fun-with-gaming/lesson/snake-game-using-pygame",
        },
    },

    TOOLS: {
        MICROPYTHON_EDITOR: "/tools/micropython-editor",
        CIRCUIT_DESIGNER: "/tools/circuit-designer",
        AIEditor: "/tools/ai-editor",
        MONITOR_DESIGNER: "/tools/monitor-designer",
        LOGIC_GATE_SIMULATOR: "/tools/logic-gate-simulator",
        BREAD_BOARD_SIMULATOR: "/tools/bread-board-simulator",
        MOBILE_APP_CODE_EDITOR: "/tools/mobile-app-code-editor",
    },
    MODELS: {
        PRE_TRAINED: {
            OBJECT_DETECTION: "/models/pre-trained/object-detection",
            FACE_DETECTION: "/models/pre-trained/face-detection",
            POSE_DETECTION: "/models/pre-trained/pose-detection",
            HAND_GESTURE_DETECTION: "/models/pre-trained/face-detection",
        },
        CUSTOM_MODELS: "/models/custom-models/:modelID",
    },
    NOTFOUND: '/404',
    WILDCARD: "*",
}
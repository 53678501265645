import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Chip, Box, Grow } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { isReactNative } from '../../../utils/ReactNativeHandler';

interface CourseCardProps {
    image: string;
    title: string;
    description: string;
    isFree: boolean;
    onOpen: () => void;
    rating: number;
    category: string;
    backgroundColor: string;
    icon: React.ReactNode;
    link: string;
}

const CourseCard: React.FC<CourseCardProps> = ({ image, title, description, isFree, onOpen, rating, category, backgroundColor, icon, link }) => {
    return (
        <Grow in timeout={1000}>
            <Card
                sx={{
                    maxWidth: !isReactNative()? 345: undefined,
                    width: '100%',
                    boxShadow: 3,
                    backgroundColor,
                    color: "black",
                    transition: 'all 0.5s',
                    '&:hover': {
                        boxShadow: 6,
                        scale: "1.05",
                    },
                }}
            >
                <CardMedia component="img" height="250" image={image} alt={title} />
                <CardContent sx={{
                    padding: 2,
                }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {[...Array(5)].map((_, index) => (
                            <StarIcon key={index} sx={{ color: index < rating ? 'gold' : 'grey.400' }} />
                        ))}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                            ({rating})
                        </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        {description}
                    </Typography>
                    <Chip variant='filled' label={category} color="secondary" sx={{ mr: 1 }} />
                    <Chip variant='filled' label={isFree ? "Free" : "Paid"} color={isFree ? "success" : "primary"} />
                </CardContent>
                <CardActions sx={{
                    padding: 2,
                }}>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        // href={link}
                        // target="_blank"
                        onClick={onOpen}
                        startIcon={icon}
                        endIcon={<PlayArrowIcon />}
                        sx={{
                            transition: 'background-color 0.5s, transform 0.5s',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        Open
                    </Button>
                </CardActions>
            </Card>
        </Grow>
    );
};

export default CourseCard;
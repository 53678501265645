import React from 'react'
import { SettingContext } from '../../../context/SettingsContext';
import { UserContext } from '../../../context/UserContext';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import { Container, Typography, Button, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemText, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fade, Slide } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import FunWithRoboticsLessons from './FunWithRobotics';

type Props = {}
function openLesson(url: string) {
    window.location.href = url;
}
function FunWithRoboticsPage({ }: Props) {
    const { settings, handleGlobalLoading } = React.useContext(SettingContext)
    const { user } = React.useContext(UserContext)
    const [currentLevel, setCurrentLevel] = React.useState(0);
    const [completedStep, setCompletedStep] = React.useState(0);
    const [screen, setScreen] = React.useState<"Level" | "Lesson">("Level");
    const navigate = useNavigate();
    if (!user || !user.userData) return null;
    return (
        <div>
            <DashboardLayout title={`Fun With AI`}>
                <Container maxWidth="xl" style={{ fontFamily: 'Arial, sans-serif' }}>
                    <header style={{ textAlign: 'center', margin: '20px 0', padding: '20px', backgroundColor: '#00FF00', color: 'white', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                        <Fade in={true} timeout={1000}>
                            <Typography variant="h2" component="h1">Fun With Robotics</Typography>
                        </Fade>
                        <Fade in={true} timeout={1500}>
                            <Typography variant="body1" component="p">
                                Explore the fascinating world of Robotics with hands-on lessons and projects. 
                            </Typography>
                        </Fade>
                    </header>


                    <section id="lessons">
                        <Grid container spacing={4}>
                            {
                                FunWithRoboticsLessons.map((tool, index) => (
                                    <Grid item xs={12} sm={6} md={3} key={index}>
                                        <Card style={{ maxWidth: 345, padding: "10px", margin: '10px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '200px',
                                                borderTopLeftRadius: '10px',
                                                borderTopRightRadius: '10px',
                                                overflow: 'hidden'
                                            }}>

                                                <img
                                                    src={tool.thumbnail}
                                                    alt="Hand Gesture Drawing Thumbnail"
                                                    style={{ height: '180px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', objectFit: 'cover' }}
                                                />
                                            </div>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {tool.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {tool.description}
                                                </Typography>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginTop: '10px', transition: 'background-color 0.3s ease-in-out' }}
                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#388E3C'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                                                    onClick={() => {
                                                        navigate(tool.link)
                                                    }}
                                                >
                                                    Open
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </section>
                </Container>
            </DashboardLayout>
        </div>
    );
}

export default FunWithRoboticsPage
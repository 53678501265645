
export function sendMessageToReactNative(data: any, type: string) {
    // @ts-ignore
    if (window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({ data, type }))
    }
}
export function isReactNative() {
    // @ts-ignore
    // alert(window.ReactNativeWebView)
    // @ts-ignore
    return window.ReactNativeWebView ? true : false
}
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext'
import { deployCode } from '../../../components/micropython-block-editor/RoboticHandler'
import MicroPythonEditor from '../../../components/micropython-block-editor'
import CodeEditor from '../../../components/code-editor'
import { ContentDialogBox } from '../../../components/dialog-box'
import { ConnectionContext, handleMachineCall } from '../../../context/ConnectionContext'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';

import { toast } from 'react-toastify'
import { PythonIDEContextContext } from '../../../context/PythonIDEContextContext'
type Props = {}

export function getWiFi() {
    const isWiFi_data = localStorage.getItem("isWiFi")
    if (isWiFi_data) {
        if (isWiFi_data === "true") {
            return true
        } else {
            return false
        }
    }
    return false
}
export default function MicroPythonEditorPage({ }: Props) {
    const [xml, setXml] = React.useState('')
    const [code, setCode] = React.useState('')
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const { connection, handleConnectionTab } = useContext(ConnectionContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(true)
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isWiFi, setIsWiFi] = React.useState<boolean>(getWiFi())
    const { toggleTerminal, TerminalButton, RestartButton, DeployCodeOnIDEButton, PermanentStorageButton, ControllerButton, MatrixCodeResolverButton } = useContext(PythonIDEContextContext)

    useEffect(() => {
        localStorage.setItem("isWiFi", String(isWiFi))
    }, [isWiFi])


    return (
        <>
            <div>
                <div className="header" style={{
                    minHeight: "5vh",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 20px",
                    borderBottom: "1px solid gray",
                    paddingLeft: "20px",
                }}>
                    <h3 style={{
                    }}>Codebot Lab</h3>
                </div>
                <div style={{
                    display: "flex",
                    height: "95vh",
                }}>
                    <MicroPythonEditor
                        setCode={(code) => {
                            // console.log(code)
                            isBlockEditing && setCode(code)
                        }}
                        setXml={(xml) => {
                            // console.log(xml)
                            setXml(xml)
                        }}
                        isLocalSave={true}
                        setNewBlocks={(value) => {
                            // console.log(value);
                            isControllerOpen && setNewBlocks(value)
                        }}
                    />
                    <div style={{
                        height: "90vh",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "5vh",
                            zIndex: 999999999
                        }}>
                            <div>
                                <DeployCodeOnIDEButton code={code} />

                                <Tooltip title={isBlockEditing ? "Code Editor" : "Block Editor"}>
                                    <Button variant='outlined' onClick={() => {
                                        setIsBlockEditing(!isBlockEditing)
                                    }} style={{
                                        border: "none",
                                        width: "50px"
                                    }}>
                                        {
                                            isBlockEditing ?
                                                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Block_Coding.png" height={20} alt="" />
                                                : <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Syntax_Code.png" height={20} alt="" style={{
                                                    filter: "invert(1)",
                                                }} />
                                        }
                                    </Button>
                                </Tooltip>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "10px"
                            }}>
                                <PermanentStorageButton code={code} />
                                <RestartButton />
                                <TerminalButton />
                                <ControllerButton  />
                                <MatrixCodeResolverButton code={code} />
                            </div>
                        </div>
                        <div>

                        </div>
                        <CodeEditor code={code}
                            setCode={setCode} />
                    </div>
                </div>
                
            </div>
        </>

    )
}


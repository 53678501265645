import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Box,
    Divider,
} from "@mui/material";
import { motion } from "framer-motion";

const AnimatedLoadingCard= ({
    message
}: { 
    message: string
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<string>("");

    return (
        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
                width: "100%",
            }}
        >
            <Card 
                sx={{

                    width:"100%",
                    margin: "auto",
                    mt: 5,
                    boxShadow: 3,
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundColor: "#ff6f00",
                    color: "#fff",
                    minHeight: "30vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                }}
            >
                <CardContent>
                    <motion.div
                        initial={{ scale: 0.8 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            textAlign="center"
                            gutterBottom
                        >
                            {message}
                        </Typography>
                    </motion.div>
                    {loading ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height={100}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Typography
                                variant="body1"
                                textAlign="center"
                            >
                                {data}
                            </Typography>
                        </motion.div>
                    )}
                </CardContent>
            </Card>
        </motion.div>
    );
};

export default AnimatedLoadingCard;
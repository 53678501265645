import { Button, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ContentDialogBox } from "../dialog-box";
import CloseIcon from '@mui/icons-material/Close'

export function MatrixDataResolver( props: {
    isOpen: boolean,
    onClose: () => void,
} ) {
    const [row, setRow] = useState(8)
    const [column, setColumn] = useState(8)
    const [DataArray, setDataArray] = useState<string[]>([])
    const [isChanged, setIsChanged] = useState(false)
    useEffect(() => {
        setDataArray(Array(row * column).fill("0"));
    }, [])
    return <>
        <ContentDialogBox
            isOpen={props.isOpen}
            onClose={props.onClose}
            title="Matrix Data Resolver"
            isTransition={true}
            transitionDirection="right"
            content={
                <Typography display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                        <Button onClick={props.onClose} sx={{ position: "absolute", right: 0, top: 0 }}>
                            <CloseIcon />
                        </Button>
                    <Typography>


                    </Typography>
                    <Typography style={{
                        display: "grid",
                        gridTemplateColumns: Array(column).fill("1fr").join(" "),
                    }}>
                        {
                            Array(row * column).fill(1).map((_, i) => {
                                return <div key={i} style={{
                                    width: "30px",
                                    height: "30px",
                                    border: "1px solid black",
                                    cursor: "pointer",
                                    background: DataArray[i] === "1" ? "black" : "white",
                                }}
                                    onClick={() => {
                                        console.log(i);
                                        // console.log(DataArray[i]);
                                        setIsChanged(!isChanged);
                                        setDataArray((prev) => {
                                            const newArray = [...prev]
                                            newArray[i] = prev[i] === "1" ? "0" : "1";
                                            return newArray
                                        })
                                    }}
                                ></div>
                            })
                        }

                    </Typography>
                    <Typography key={isChanged ? "1" : "0"} style={{
                        display: "grid",
                        gridTemplateColumns: Array(column).fill("1fr").join(" "),
                        position: "relative",
                        marginTop: "20px",
                    }}>
                        {
                            Array(row * column).fill(1).map((_, i) => {
                                return <>
                                    <div key={i} style={{
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                        textAlign: "center",
                                    }}
                                    >{DataArray[i]} </div>
                                    {
                                        (i + 1) % 8 === 0 && <Button onClick={() => {
                                            navigator.clipboard.writeText(DataArray.slice(i - 8 + 1, i + 1).join(""));
                                            toast.info(DataArray.slice(i - 8 + 1, i + 1).join("") + " is copied to clipboard")
                                        }} sx={{
                                            // width: "20px",
                                            margin: 0,
                                            padding: 0,
                                            // minWidth: "20px",
                                            position: "absolute", right: "-60px",
                                            top: `${Math.floor(i / 8) * 20}px`,
                                        }}>
                                            <ContentCopyIcon sx={{
                                                width: "15px"
                                            }} />
                                        </Button>
                                    }
                                </>
                            })
                        }

                    </Typography>
                </Typography>
            }
        />
    </>
}
import React, { useContext, useEffect, useState } from 'react'
import { BackendConfigs } from '../../../config.environment'
import { UserContext } from '../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from '../../../types/Response'
import { routes } from '../../../routes/routes'
import { Button, Card, Checkbox, TextField, Typography } from '@mui/material'
import { ContentDialogBox } from '../../../components/dialog-box'
import { FileUploader } from 'react-drag-drop-files'
import { uploadFileOnS3 } from '../../../services/S3Storage/Upload'
import TheoryComponent from './BuilderComponents/Theory'
import CircuitBuildingComponent from './BuilderComponents/Circuit'
import CodeBuildingComponent from './BuilderComponents/Coding'
import IOTBuildComponent from './BuilderComponents/IOT'
import { WidgetDataType } from '../../../types/Dashboard'

type Props = {}

const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG", "HEIC"];

function CreateLessonBuilderLessonPage({ }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [lessonName, setLessonName] = useState<string>("")
    const [lessonDescription, setLessonDescription] = useState<string>("")
    const [selectedEditors, setSelectedEditors] = useState<string[]>([])
    const [lessonType, setLessonType] = useState<string>("")
    const [theory, setTheory] = useState<{
        type: "theory" | "mcq",
        question: string,
        correctAnswer: string,
        options: string[]
    }[]>([])
    const [circuitInstructions, setCircuitInstructions] = useState<string[]>([])
    const [circuitJSON, setCircuitJSON] = useState<string>("{}")
    const [micropythonInstructions, setMicropythonInstructions] = useState<string[]>([])
    const [micropythonXML, setMicropythonXML] = useState<string>("")
    const [monitorInstructions, setMonitorInstructions] = useState<string[]>([])
    const [monitorJSON, setMonitorJSON] = useState<string>("[]")

    const [aiInstructions, setAIInstructions] = useState<string[]>([])
    const [aiLogicXML, setAILogicXML] = useState<string>("")
    const [aiModelID, setAIModelID] = useState<string>("")






    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const [isImageWindowOpen, setIsImageWindowOpen] = React.useState(true)
    const [image, setImage] = React.useState<string | null>(null)

    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate()






    const handleChange = async (file: File) => {
        handleGlobalLoading(true, "Uploading Image")
        const extension = file.name.split(".").pop();
        const newName = `${Date.now()}`;
        console.log(file, extension, newName);
        setFile(file);
        if (!!file) {

            try {
                const response = await uploadFileOnS3(file, newName) as {
                    location: string,
                    bucket: string,
                    key: string
                };
                console.log(response);
                if (!!response.location) {
                    setImage(response.location)
                    setIsImageWindowOpen(false)
                    toast.info("Image uploaded successfully")
                }
            } catch (error) {
                console.log(error);
            }
        }
        handleGlobalLoading(false, "Uploading Image")
    };
    async function createLesson() {
        handleGlobalLoading(true, "Creating Lesson : " + lessonName)
        if (!lessonName) {
            toast.error("Lesson Name is required")
            handleGlobalLoading(false)
            return
        }
        if (!lessonDescription) {
            toast.error("Lesson Description is required")
            handleGlobalLoading(false)
            return
        }

        try {
            const response = await fetch(`${BackendConfigs.url}/CreateLessonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: lessonName,
                    description: lessonDescription,
                    thumbnail: image,
                    lessonType: lessonType,
                    editors: selectedEditors,
                    createdBy: user.userData?.id,
                    theory,
                    circuitInstructions,
                    circuitJSON,
                    micropythonInstructions,
                    micropythonXML,
                    monitorInstructions,
                    monitorJSON,
                    aiInstructions,
                    aiLogicXML
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                console.log(result);

                if (result.success) {
                    toast.success(result.message)
                    setIsCreateSuccess(true)
                    setTimeout(() => {
                        navigate(routes.DASHBOARD.LESSON_BUILDER_LESSON_LIST)
                    }, 3000);
                } else {
                    toast.error(result.message)
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        if (selectedEditors.includes("iot-monitor") || selectedEditors.includes("ai-editor")) {
            if (!selectedEditors.includes("micropython-editor")) {
                setSelectedEditors([...selectedEditors, "micropython-editor"])
            }
            if (!selectedEditors.includes("circuit-designer")) {
                setSelectedEditors([...selectedEditors, "circuit-designer"])
            }
        }

    }, [selectedEditors])

    return (
        <>
            <div style={{
                minHeight: "90vh",
                display: 'flex',
                margin: "0 auto",
                justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
                width: "95%"
            }}>

                <Card sx={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // margin: "10px 5px",
                    flexDirection: "column",
                    width: "90%",
                    margin: "10px auto",
                    height: "100%"
                }}>
                    {/* <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 0"
                    }}>
                        <img src="https://educobot-statics.s3.ap-south-1.amazonaws.com/Educobot_Main_Hosting/EduCobot_OLD/assets/images/form_img.png" style={{
                            maxWidth: "80px"
                        }} alt="" />
                        <h2>
                            Create Robotics curriculum that suits your needs.
                        </h2>
                    </div> */}
                    <div>
                        <img src="https://educobot-statics.s3.ap-south-1.amazonaws.com/Educobot_Main_Hosting/EduCobot_OLD/assets/images/form_img.png" style={{
                            maxWidth: "150px"
                        }} alt="" />
                    </div>
                    <div id='ProjectCreateContainer' style={{
                        padding: 10,
                        width: "100%",
                        display: settings.screen == "mobile" ? "flex" : "grid",
                        gridTemplateColumns: "1fr 1fr",
                        flexWrap: "wrap",
                        gap: "30px",
                    }}>
                        <div>
                            <TextField
                                fullWidth
                                label='Enter Lesson Name'
                                style={{
                                    margin: 10
                                }}
                                value={lessonName}
                                onChange={(e) => {
                                    setLessonName(e.target.value)
                                }}
                            />
                            <TextField

                                fullWidth
                                label='Enter Description'
                                style={{
                                    margin: 10
                                }}
                                value={lessonDescription}
                                onChange={(e) => {
                                    setLessonDescription(e.target.value)
                                }}
                            />
                            <h5>Select Lesson Type</h5>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "85%",
                                margin: "10px 0",
                                gap: 50
                            }}>
                                <Button onClick={() => {
                                    setLessonType("Debug")
                                }} variant={lessonType == "Debug" ? "contained" : "outlined"} style={{
                                    flexGrow: 0.5,
                                    marginLeft: 200

                                    // margin: 40,
                                }}>
                                    Debug
                                </Button>
                                <Button onClick={() => {
                                    setLessonType("Test")
                                }} variant={lessonType == "Test" ? "contained" : "outlined"} style={{
                                    flexGrow: 0.5,
                                    marginRight: 150

                                    // margin: 40,
                                }}>
                                    Test
                                </Button>
                            </div>
                            <div>
                                <h4>Select your required Development Environment (min. 1)</h4>
                                <div>
                                    <Checkbox
                                        checked={selectedEditors.length === 5}
                                        onChange={(e) => {
                                            if (selectedEditors.length === 5) {
                                                setSelectedEditors([])
                                            } else {
                                                setSelectedEditors(["theory", "micropython-editor", "ai-editor", "circuit-designer", "iot-monitor"])
                                            }
                                        }}
                                        id='all'
                                    />
                                    <label htmlFor="all">Select All</label>
                                </div>
                                <div>
                                    <Checkbox
                                        checked={selectedEditors.includes("theory")}
                                        onChange={(e) => {
                                            selectedEditors.includes("theory") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "theory")) : setSelectedEditors([...selectedEditors, "theory"])
                                        }}
                                        id='theory'
                                    />
                                    <label htmlFor="theory">Theory</label>
                                </div>
                                <div>
                                    <Checkbox
                                        checked={selectedEditors.includes("micropython-editor")}
                                        onChange={(e) => {
                                            selectedEditors.includes("micropython-editor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "micropython-editor")) : setSelectedEditors([...selectedEditors, "micropython-editor"])

                                        }}
                                        id='micropython-editor'
                                    />
                                    <label htmlFor="micropython-editor">Robotics</label>
                                </div>

                                {/* <div>
                                    <Checkbox
                                        checked={selectedEditors.includes("circuit-designer")}
                                        onChange={(e) => {
                                            selectedEditors.includes("circuit-designer") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "circuit-designer")) : setSelectedEditors([...selectedEditors, "circuit-designer"])
                                        }}
                                        id='circuit-designer'
                                    />
                                    <label htmlFor="circuit-designer">Circuit Designer</label>
                                </div> */}
                                <div>
                                    <Checkbox
                                        checked={selectedEditors.includes("iot-monitor")}
                                        onChange={(e) => {
                                            selectedEditors.includes("iot-monitor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "iot-monitor")) : setSelectedEditors([...selectedEditors, "iot-monitor"])

                                        }}
                                        id='iot-monitor'
                                    />
                                    <label htmlFor="iot-monitor">IOT Monitor</label>
                                </div>
                                <div>
                                    <Checkbox
                                        checked={selectedEditors.includes("ai-editor")}
                                        onChange={(e) => {
                                            selectedEditors.includes("ai-editor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "ai-editor")) : setSelectedEditors([...selectedEditors, "ai-editor"])

                                        }}
                                        id='ai-editor'
                                    />
                                    <label htmlFor="ai-editor">AI Editor</label>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div style={{
                                margin: "10px 0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}>
                                <p>
                                    Upload Lesson Thumbnail
                                </p>
                                {
                                    isImageWindowOpen ?

                                        <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} style={{ width: "min(500px,90%)" }} classes="file_uploader" /> : image && <img src={image} alt="" style={{
                                            maxWidth: "200px",
                                        }} />
                                }
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "10px"
                            }}>
                                {
                                    selectedEditors.includes("theory") && <TheoryComponent theory={
                                        theory
                                    } setTheory={(theory_) => {
                                        setTheory(theory_)
                                    }} />
                                }
                                {
                                    selectedEditors.includes("micropython-editor") && <CodeBuildingComponent instructions={micropythonInstructions} setInstructions={setMicropythonInstructions} xml={micropythonXML} setXml={setMicropythonXML} />
                                }
                                {
                                    selectedEditors.includes("circuit-designer") && <CircuitBuildingComponent instructions={circuitInstructions} setInstructions={setCircuitInstructions} referenceCircuitJSON={circuitJSON} setReferenceCircuitJSON={setCircuitJSON} />
                                }
                                {
                                    selectedEditors.includes("iot-monitor") && <IOTBuildComponent instructions={
                                        monitorInstructions
                                    } setInstructions={setMonitorInstructions} monitorJSON={monitorJSON} setMonitorJSON={setMonitorJSON}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px 0"
                    }}>
                        <Button id='createLessonButton' variant="contained" color="success" sx={{
                            width: "70%",
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            // fontSize: "10px",
                            padding: "10px 50px",
                            fontSize: "1.5rem",
                        }} onClick={() => {
                            createLesson()
                        }}>
                            Create Lesson
                        </Button>
                    </div>

                </Card>
                <Card style={{
                    padding: "20px"
                }}>

                    <h2 style={{
                        textAlign: "center"
                    }}> Instructions</h2>
                    <div style={{
                        margin: "10px 0"
                    }}>
                        <span style={{
                            color: eduCOBOTColorScheme.gray
                        }}>
                            Lesson Name :
                        </span> {lessonName == "" ? "Assign a distinctive and meaningful name related to the activity." : lessonName}
                    </div>
                    <div style={{
                        margin: "10px 0"
                    }}>
                        <span style={{
                            color: eduCOBOTColorScheme.gray
                        }}>
                            Lesson Description :
                        </span> {lessonDescription == "" ? "Provide a concise paragraph outlining the lesson's purpose, objectives, and expected outcomes." : lessonDescription}
                    </div>

                    <div style={{
                        margin: "10px 0"
                    }}>
                        <span style={{
                            color: eduCOBOTColorScheme.gray
                        }}>
                            Lesson Type :
                        </span> {lessonType == "" ? "Choose the appropriate category—Debug or Test Lesson." : lessonType + " Lesson"}
                    </div>
                    {
                        selectedEditors.includes("theory") && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "10px"
                            }}> Following Theory you have added</h4>
                            {
                                theory.length === 0 && <>
                                    <Card style={{
                                        padding: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: eduCOBOTColorScheme.gray
                                    }}>
                                        You have not added any Theory Content


                                    </Card>

                                </>
                            }
                            {theory.map((question, index) => {

                                if (question.type === "mcq") {
                                    return <div key={index} style={{
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            gap: "1rem",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "0 1rem",
                                                fontWeight: "bolder",
                                            }}>
                                                {index + 1}.
                                            </div>
                                            <TextField
                                                fullWidth
                                                variant='standard'
                                                label="Question"
                                                value={question.question}
                                                placeholder={`Question ${index + 1}`}
                                                disabled
                                                multiline
                                            />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                        }}>
                                            {
                                                question.options.map((option, optionIndex) => (
                                                    <div key={optionIndex} style={{
                                                        width: "100%",
                                                        margin: "0.5rem 0"
                                                    }}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Option ${optionIndex + 1}`}
                                                            value={option}
                                                            disabled
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                else if (question.type === "theory") {
                                    return <div key={index} style={{
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            gap: "1rem",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "0 1rem",
                                                fontWeight: "bolder",
                                            }}>
                                                {index + 1}.
                                            </div>
                                            <TextField
                                                fullWidth
                                                variant='standard'
                                                label="Question"
                                                value={question.question}
                                                disabled
                                                placeholder={`Question ${index + 1}`}
                                                multiline
                                            />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            margin: "0.5rem 0"
                                        }}>
                                            <TextField
                                                fullWidth
                                                label="Correct Answer"
                                                value={question.correctAnswer}
                                                multiline
                                                disabled
                                            />
                                        </div>
                                    </div>
                                }
                            }
                            )}
                        </div>
                    }
                    {
                        selectedEditors.includes("micropython-editor") && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "20px"
                            }}> Following Instructions you have added in Robotics Coding</h4>
                            {
                                micropythonInstructions.length === 0 && <>
                                    <Card style={{
                                        padding: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: eduCOBOTColorScheme.gray,
                                        textAlign: "center",

                                    }}>
                                        You have not added any Robotic Coding Instructions
                                    </Card>

                                </>
                            }
                            <Typography component={'div'} style={{
                                // backgroundColor: "#83cceb",
                                display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                gap: "1rem",
                                padding: "1rem",
                                flexDirection: "column",
                            }}>
                                {
                                    micropythonInstructions.map((instruction, index) => {
                                        return <div style={{
                                            display: "flex",
                                            gap: "1rem",
                                            width: "100%",
                                            alignItems: "center",
                                        }}>
                                            <TextField
                                                key={index}
                                                value={instruction}
                                                disabled
                                                fullWidth
                                                multiline
                                                label={`Instruction ${index + 1}`}
                                                variant='standard'
                                                sx={{
                                                }}
                                            />
                                        </div>
                                    })
                                }


                            </Typography>
                        </div>
                    }
                    {
                        selectedEditors.includes("circuit-designer") && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "20px"
                            }}> Following Instructions you have added in Circuitry Design</h4>
                            {
                                circuitInstructions.length === 0 && <>
                                    <Card style={{
                                        padding: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: eduCOBOTColorScheme.gray,
                                        textAlign: "center"
                                    }}>
                                        You have not added any Circuit Design Instructions
                                    </Card>
                                </>
                            }
                            <Typography component={'div'} style={{
                                // backgroundColor: "#83cceb",
                                display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                gap: "1rem",
                                padding: "1rem",
                                flexDirection: "column",
                            }}>
                                {
                                    circuitInstructions.map((instruction, index) => {
                                        return <div style={{
                                            display: "flex",
                                            gap: "1rem",
                                            width: "100%",
                                            alignItems: "center",
                                        }}>
                                            <TextField
                                                key={index}
                                                value={instruction}
                                                disabled
                                                fullWidth
                                                multiline
                                                label={`Instruction ${index + 1}`}
                                                variant='standard'
                                                sx={{
                                                }}
                                            />
                                        </div>
                                    })
                                }
                            </Typography>
                        </div>
                    }
                    {
                        selectedEditors.includes("iot-monitor") && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                            width: "100%"
                        }}>
                            <h4 style={{
                                margin: "20px"
                            }}> Following Instructions you have added in Monitor Design</h4>
                            {
                                monitorInstructions.length === 0 && <>
                                    <Card style={{
                                        padding: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: eduCOBOTColorScheme.gray,
                                        textAlign: "center"
                                    }}>
                                        You have not added any Monitor Design Instructions
                                    </Card>
                                </>
                            }
                            <Typography component={'div'} style={{
                                // backgroundColor: "#83cceb",
                                display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",
                                gap: "1rem",
                                padding: "1rem",
                                flexDirection: "column",
                            }}>
                                {
                                    monitorInstructions.map((instruction, index) => {
                                        return <div style={{
                                            display: "flex",
                                            gap: "1rem",
                                            width: "100%",
                                            alignItems: "center",
                                        }}>
                                            <TextField
                                                key={index}
                                                value={instruction}
                                                disabled
                                                fullWidth
                                                multiline
                                                label={`Instruction ${index + 1}`}
                                                variant='standard'
                                                sx={{
                                                }}
                                            />
                                        </div>
                                    })
                                }
                            </Typography>
                        </div>
                    }




                </Card>
            </div>
            <ContentDialogBox
                isOpen={isCreateSuccess}
                onClose={() => {
                    // setIsCreateSuccess(false)
                    // setIsCreateDialogOpen(false)
                    // setIsDataChanged(true)
                }}
                title={<>
                    <h4 style={{
                        color: eduCOBOTColorScheme.gray,
                        marginLeft: "15px",
                    }}>
                        Lesson Created Successfully
                    </h4>

                </>}
                backdropStyle={{
                    backgroundColor: "transparent"
                }}
                paperStyle={{
                    background: "transparent",
                    borderRadius: 20,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    // alignItems: "center",
                    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.05)"

                }}
                content={<>
                    <div style={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        <div style={{
                            padding: "100px 20px"
                        }}>
                            <div className="loader1" ></div>
                        </div>
                        <h4 style={{
                            color: eduCOBOTColorScheme.gray,
                        }}>Redirecting to Lessons....!</h4>
                    </div>
                </>}
                maxWidth={"sm"}
            />
        </>

    )
}











export default CreateLessonBuilderLessonPage
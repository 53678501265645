import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { ContentDialogBox } from '../../../../../../components/dialog-box'
import { Button, Card, Tooltip, Typography } from '@mui/material'
import StopIcon from '@mui/icons-material/Stop';
import JavascriptBlockToolBox, { JavascriptBlockToolBoxWithFaceDetection } from '../../../../../../components/javascript-block-editor/configs/javascript-toolbox'
import { generateBlocklyBlocksForMicroPythonDynamically, generateBlocklySBlocksForModelOutputDynamically, javascriptGenerator } from '../../../../../../components/javascript-block-editor/configs/code-generator'
import ReactApexChart from 'react-apexcharts'
import { BackendConfigs } from '../../../../../../config.environment'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { ModelType, WorkspaceType } from '../../../../../../types/Database'
import { Hourglass } from 'react-loader-spinner'
import CodeEditor from '../../../../../../components/code-editor'
import { ConnectionContext } from '../../../../../../context/ConnectionContext'
import { JavascriptAudioPlayer } from '../../../../../../components/javascript-block-editor/audio'
import GoogleTeachableExecuter from '../../../../../Tools/GoogleTeachableExecuter/GoogleTeachableExecuter'
import JavaScriptBlockEditor from '../../../../../../components/javascript-block-editor'
import { ProjectBottomBarComponent } from '../../Common/BottomBar'
import { toast } from 'react-toastify'
import { ProjectDataType } from '../../../../../../types/Dashboard'
import { APIResponse } from '../../../../../../types/Response'
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';


type Props = {}

export default function AIEditorPage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme, handleGlobalLoading } = useContext(SettingContext)
    const audioPlayer = new JavascriptAudioPlayer()
    const navigate = useNavigate()
    const { connection } = useContext(ConnectionContext)
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)

    const [currentToolBox, setCurrentToolBox] = useState<any>(JavascriptBlockToolBox)
    const [code, setCode] = useState("")
    const [xml, setXml] = useState("")
    const [backupXML, setBackupXML] = useState("")
    const [isExtensionDialogOpen, setIsExtensionDialogOpen] = useState(false)
    const [models, setModels] = useState<ModelType[]>([])
    const [prediction, setPrediction] = useState<any>()
    const [finalPrediction, setFinalPrediction] = useState<string>("")
    const [isPreviewOn, setIsPreviewOn] = useState(false)
    const [modelConnectSuccess, setModelConnectSuccess] = useState(true)

    const [isImageShowing, setIsImageShowing] = useState(false)
    const [imageURL, setImageURL] = useState("http://localhost:3000/imgs/eduCOBOTSingleLine.svg")
    function setNewBlocks(blocks: string[]) {
        const new_tool_box = generateBlocklyBlocksForMicroPythonDynamically(blocks, currentToolBox, "machine")
        setCurrentToolBox(new_tool_box)
    }
    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Fetching Project")
            setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse

                    setNewBlocks((result.data as ProjectDataType).micropython.externalControllerBlocks)
                    setProjectData(result.data as ProjectDataType)
                    setCode((result.data as ProjectDataType).aiWorkspace.workspace.javaScriptCode)
                    setXml((result.data as ProjectDataType).aiWorkspace.workspace.javaScriptXML)
                    setBackupXML((result.data as ProjectDataType).aiWorkspace.workspace.javaScriptXML)
                    setModelConnectSuccess(!!(result.data as ProjectDataType).aiWorkspace.model)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Project")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    async function saveAIWorkspace() {
        handleGlobalLoading(true, "Saving Progress")
        try {
            const response = await fetch(`${BackendConfigs.url}/UpdateAIWorkspaceAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    javaScriptXML: xml,
                    javaScriptCode: code,
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving Blocks")
        }
        handleGlobalLoading(false)
    }


    useEffect(() => {
        getProject()
    }, [user, projectId])
    useEffect(() => {

    }, [])
    function showImage(url: string) {
        setImageURL(url)
        setIsImageShowing(true)
    }
    function hideImage() {
        setIsImageShowing(false)
        setImageURL("")
    }



    // const [showOutput, setShowOutput] = useState(false)
    const [runningInterval, setRunningInterval] = useState<any>()
    const [isRunning, setIsRunning] = useState(false)
    const [extensionData, setExtensionData] = useState<{
        current_type: "extension" | "model",
        model_data?: {
            model_name: string,
            classes: string[],
            model_url: string
        },
        extension_data?: {
            name: string,
        }
    }>()
    const [idLoadingDialog, setIsLoadingDialog] = useState(false)

    useEffect(() => {
        // const savingInterval = setInterval(() => {
        //     if (user.isLogin) {
        //         saveAllData()
        //     }
        // }, 5000)
        // return () => {
        //     clearInterval(savingInterval)
        // }
    }, [user])

    function getFinalPrediction() {
        return finalPrediction
    }

    function startRunningCode() {
        // console.log("running code");
        // const interval = setInterval(() => {
        //     console.log(getFinalPrediction());
        // }, 200)
        // setRunningInterval(interval)
    }

    function stopRunningCode() {
        // console.log("stop running code");
        // clearInterval(runningInterval)
    }



    async function call_machine_function_on_callback(endPoint: string) {
        try {
            await fetch(`http://localhost:8080/make_request_on_esp32`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ IPAddress: connection.wifi.connectedModuleIP, endpoint: endPoint }),
                })
            return true
        } catch (error) {
            console.log(error);
            return false
        }
    }


    useEffect(() => {
        if (isRunning) {
            console.log("running code");

            eval(code)
        }
    }, [finalPrediction])



    function getComponent() {
        if (extensionData?.current_type === "extension") {

        } else if (extensionData?.current_type === "model") {
            return <>
                <div style={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                    overflow: "hidden",
                    position: "relative",
                }}>
                    <GoogleTeachableExecuter key={extensionData.model_data?.model_name} model_url={extensionData.model_data?.model_url} size={350} setPredictionValue={setPrediction} setFinalPrediction={setFinalPrediction} />
                    <Button size='small' variant='contained' sx={{
                        margin: "10px",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        zIndex: 9999,
                        background: eduCOBOTColorScheme.white,
                        color: eduCOBOTColorScheme.gray,
                    }}>{getOutput(prediction)}</Button>
                </div>
            </>
        } else {
            return <></>
        }
    }


    async function getWorkspaceData() {
        handleGlobalLoading(true)
        try {

        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }

    function setPredictionValue(value: any) {
        setPrediction(value)
    }


    useEffect(() => {
        getWorkspaceData()
    }, [user])



    function stopRunning() {
        // stopRunningCode()
        setIsRunning(false)
    }
    function startRunning() {

        if (!extensionData) {
            console.log('Running Once');

            eval(code)
        }
        // startRunningCode()
        setIsRunning(true)
    }

    const [currentModelTab, setCurrentModelTab] = useState<"educobot" | "google">('educobot')
    function getOutput(preview: any) {
        if (preview && preview.forEach) {

            let maxPercentage = 0
            let output = ""
            preview.forEach((previewItem: any) => {
                if (previewItem.probability > maxPercentage) {
                    maxPercentage = previewItem.probability
                    output = previewItem.className
                }
            })
            return output

        }
    }
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                {/* <div style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <h3>
                        {
                            projectData && projectData.project.name
                        }
                    </h3>
                    <div>


                    </div>
                </div> */}
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    width: "99vw",
                    // border: "1px solid gray",
                    minHeight: "100dvh",
                    height: "100dvh",
                    position: "relative",
                }}>
                    <div style={{
                        // border: "1px solid gray",
                        width: "50vw"
                    }}>
                        <JavaScriptBlockEditor
                            key={currentToolBox + backupXML}
                            JavascriptBlockToolBox={currentToolBox}
                            setCode={setCode}
                            setXml={setXml}
                            initialXML={backupXML}
                        />
                    </div>
                    <div style={{
                        minWidth: "49vw",
                        // border: "0.5px solid #00000055",
                        height: "100%",
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "6dvh",
                        }}>
                            <div style={{
                                width: "70%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }}>
                                {
                                    isRunning ? <Button onClick={stopRunning
                                    }>  <StopIcon />



                                    </Button> : <Button id='AIRunBUtton' onClick={startRunning} style={{
                                        border: "none",
                                    }}>
                                        <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                            width: "20px"
                                        }} alt="" />
                                    </Button>
                                }
                                {
                                    projectData?.aiWorkspace.model &&
                                    <Button
                                        id='ModelRunButton'
                                        variant='contained'
                                        sx={{
                                            margin: "0 10px",
                                            textTransform: "none"
                                        }}
                                        onClick={() => {
                                            handleGlobalLoading(true, "Loading Model...")
                                            setExtensionData({
                                                current_type: "model",
                                                model_data: {
                                                    model_name: projectData?.aiWorkspace.model.name,
                                                    model_url: projectData?.aiWorkspace.model.fileUrls.modelJson.replace("model.json", ""),
                                                    classes: projectData?.aiWorkspace.model.labels
                                                }
                                            });
                                            const new_toolbox = generateBlocklySBlocksForModelOutputDynamically(projectData?.aiWorkspace.model.labels, currentToolBox, "model output")
                                            setIsExtensionDialogOpen(false)
                                            setCurrentToolBox(new_toolbox)
                                            setTimeout(() => {
                                                handleGlobalLoading(false)
                                            }, 5000)



                                        }
                                        }
                                        size='small'
                                    >
                                        <img src="https://storage.googleapis.com/innovator-resourses/innovator-new/extension_Setting%20(1).png" style={{
                                            width: "20px"
                                        }} alt="" />
                                    </Button>
                                }
                                <Tooltip title={"Save Workspace"} placement='bottom'>
                                    <Button id='SaveAIWorkspaceButton' style={{}} onClick={() => {
                                        saveAIWorkspace()
                                        // setIsSavingTab(!isSavingTab)
                                    }}>
                                        <SaveIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                            <div style={{
                                minWidth: "20%",
                                display: "flex",
                                justifyContent: "end"
                            }}>
                                <Button id='CameraPreview' variant='outlined' onClick={() => {
                                    setIsPreviewOn(!isPreviewOn);
                                }} sx={{
                                    textTransform: "none",
                                    margin: "10px",
                                }} size='small'>
                                    {
                                        isPreviewOn ? "Code Editor" : "Preview Off"
                                    }
                                </Button>
                                {/* <Button variant='outlined' onClick={() => {
                                    setShowOutput(!showOutput);
                                }} sx={{
                                    textTransform: "none",
                                    margin: "10px"
                                }} size='small'>
                                    {
                                        showOutput ? "Output Showing" : "Output Hidden"
                                    }
                                </Button> */}
                            </div>
                        </div>
                        <div style={{
                            display: isPreviewOn ? "flex" : "none",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            maxWidth: "49vw",
                        }}>
                            {
                                getComponent()
                            }
                        </div>
                        <div style={{
                            // height: "100%",
                        }}>
                            <Card style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                margin: "auto",
                                maxHeight: "500px",
                                height: "100%",
                            }}>
                                {
                                    isPreviewOn && prediction && extensionData && extensionData.current_type === "model" && prediction.map &&
                                    <ReactApexChart type="bar" width={"100%"} height={300} series={[{
                                        name: 'Model Predictions',
                                        data: prediction.map((value: any) => { return parseInt((value.probability * 100).toFixed(2)) })
                                    }]}
                                        options={{
                                            chart: {
                                                type: 'bar',
                                                height: 250
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            stroke: {
                                                show: true,
                                                width: 2,

                                                colors: ['transparent']
                                            },
                                            xaxis: {
                                                categories: prediction.map((value: any) => { return value.className }),
                                            },
                                            yaxis: {
                                                title: {
                                                    text: '% probability'
                                                }
                                            },
                                            fill: {
                                                opacity: 1
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                        return val + " percentage"
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                }
                            </Card>
                        </div>
                        {
                            !isPreviewOn && <div style={{
                                height: "94dvh",
                            }}>
                                <CodeEditor language='javascript' code={code}
                                    setCode={setCode} />
                            </div>
                        }
                    </div>

                </div>

                <ContentDialogBox
                    isOpen={idLoadingDialog}
                    onClose={() => { }}
                    title="Loading Model"
                    transitionDirection="down"
                    isTransition={true}
                    fullScreen={false}
                    content={
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100px",
                            }}>
                                <Hourglass />
                            </div>

                        </>
                    }
                />
                <ContentDialogBox
                    isOpen={isImageShowing}
                    onClose={() => {
                        setIsImageShowing(false);
                    }}
                    title="Image Output"
                    transitionDirection="down"
                    maxWidth="lg"
                    content={<>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // height: "100px",
                            minHeight: "60vh",
                            maxHeight: "90vh",
                        }}>
                            <img src={imageURL} alt="" style={{
                                maxWidth: "100%",
                                maxHeight: "600px",
                            }} />
                        </div>

                    </>}
                />
                <ContentDialogBox
                    isOpen={!modelConnectSuccess}
                    onClose={() => {
                        setModelConnectSuccess(true)
                    }}
                    title={"Model Not Connected"}
                    content={<>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            {/* <img src="https://cdn-icons-png.flaticon.com/512/1372/1372569.png" width={"250px"} alt="" /> */}
                            <h3>Go back to settings and reconnect the model.</h3>
                        </div>
                    </>}
                    maxWidth={"sm"}

                />
                <ContentDialogBox
                    isOpen={isExtensionDialogOpen}
                    onClose={() => setIsExtensionDialogOpen(false)}
                    title="Your Models"
                    transitionDirection="down"
                    isTransition={true}
                    maxWidth="lg"
                    content={<>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}>
                            {
                                models && models.length === 0 && <>
                                    <Card style={{
                                        width: "100%",
                                        minHeight: "100px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        borderRadius: "20px",
                                        padding: "20px",
                                    }}
                                    >
                                        <h3 style={{
                                            textAlign: "center",
                                        }}>No Models found</h3>
                                    </Card>
                                </>
                            }
                            {
                                models && models.length > 0 && models.map((model, index) => (
                                    <Card key={index} style={{
                                        width: "300px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "20px",
                                        margin: "10px",
                                        paddingBottom: "20px",
                                        background: eduCOBOTColorScheme.light_gray,
                                        color: eduCOBOTColorScheme.gray,
                                    }}
                                        onClick={() => {
                                            setExtensionData({
                                                current_type: "model",
                                                model_data: {
                                                    model_name: model.name,
                                                    model_url: model.fileUrls.modelJson.replace("model.json", ""),
                                                    classes: model.labels
                                                }
                                            });
                                            const new_toolbox = generateBlocklySBlocksForModelOutputDynamically(model.labels, currentToolBox, "model output")
                                            setIsExtensionDialogOpen(false)
                                            setCurrentToolBox(new_toolbox)
                                            setIsLoadingDialog(true)
                                            setTimeout(() => {
                                                setIsLoadingDialog(false)
                                            }, 5000)
                                        }}>
                                        <div style={{
                                            background: "white",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}>
                                            <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" alt="" style={{
                                                width: "150px",
                                                background: "white"
                                            }} />
                                        </div>
                                        <h5 style={{
                                            marginTop: "10px",
                                            padding: "5px",
                                        }}>
                                            {
                                                model.name
                                            }
                                        </h5>
                                    </Card>
                                ))
                            }
                        </div>
                    </>}
                />

            </div>
            {
                projectData &&
                <ProjectBottomBarComponent projectData={projectData} />
            }
        </>

    )
}
import React, { useContext, useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions } from '@mui/material';
import { eduCOBOTColorScheme } from '../../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { UserContext } from '../../../context/UserContext';
import { SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { APIResponse } from '../../../types/Response';
import { toast } from 'react-toastify';
import { ProjectDataType } from '../../../types/Dashboard';

import { ModelType } from '../../../types/Database';
import { AlertDialogBox } from '../../../components/dialog-box';
import ModelCard from './ModelCard';

type Props = {
}


function ModelsListComponent({ }: Props) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [modelsList, setModelsList] = React.useState<ModelType[]>([])

    async function fetchModels() {
        handleGlobalLoading(true, "Fetching Projects")
        setModelsList([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetAllModelsByOwnerIdAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setModelsList(result.data)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        fetchModels()
    }, [user])
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: settings.screen === "mobile" ? "center" : "start",
                alignItems: "start",
                flexWrap: "wrap"
            }}>
                {
                    modelsList && modelsList.map((model,index) => {
                        return <ModelCard key={model._id} data={model} reFetch={fetchModels} index={index+6}/>
                    })
                }
                {
                    modelsList.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>

                        <p style={{ textAlign: "center" }}>No Models Found</p>
                    </Card>
                }
            </div>
        </div>
    )
}




// function ModelCard({ data, reFetch }: {
//     data: ModelType,
//     reFetch: () => void
// }) {
//     const navigate = useNavigate()
//     const { user } = useContext(UserContext)
//     const { settings, handleGlobalLoading } = useContext(SettingContext)
//     const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
//     const [deletingModelData, setDeletingModelData] = useState<ModelType>()

//     async function deleteModel(modelId: string) {
//         if (user.isLogin && modelId) {
//             try {
//                 const response = await fetch(`${BackendConfigs.url}/DeleteModelAPI`, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify({
//                         userId: user.userData?.id,
//                         modelId: modelId,
//                     })
//                 })
//                 if (response.status === 200) {
//                     toast.info("Model deleted successfully")
//                     setDeleteDialogOpen(false)
//                     setDeletingModelData(undefined)
//                     reFetch()
//                 } else {
//                     toast.error("Access Denied")
//                 }
//             } catch (error) {
//                 console.log(error);
//                 toast.error("failed to delete model")
//             }
//         }
//     }


//     return (
//         <>
//             <Card style={{
//                 width: "min(350px, 100%)",
//                 minHeight: "min(250px, 500px)",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 cursor: "pointer",
//                 borderRadius: "20px",
//                 margin: "10px",
//                 // background: "#ffffffCD",
//                 // backdropFilter: "blur(10px)",
//                 transition: "all 0.3s",
//                 paddingBottom: "50px",
//             }}
//                 sx={{
//                     ":hover": {
//                         scale: "1.1",
//                     }
//                 }}
//             >
//                 <DeleteIcon sx={{
//                     position: "absolute",
//                     top: 10,
//                     right: 10,
//                     transition: "all 0.5s",
//                     ":hover": {
//                         scale: "1.5",
//                     }
//                 }} color='error' onClick={() => {
//                     setDeletingModelData(data);
//                     setDeleteDialogOpen(true);
//                 }} />
//                 <img src={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png"} alt="" style={{
//                     maxWidth: "100px",
//                     maxHeight: "100px",
//                     // marginBottom: "20px",
//                 }} />
//                 <CardActions style={{
//                     width: "100%",
//                     background: "#0000003E",
//                     textAlign: "center",
//                     marginBottom: "0px",
//                     padding: "15px",
//                     position: "absolute",
//                     bottom: "0px",
//                     height: "50px"
//                 }} onClick={() => {

//                     navigate(routes.DASHBOARD.MODEL.WORKSPACE(data._id))
//                 }}>
//                     <h3 style={{
//                         width: "100%",
//                         // color: eduCOBOTColorScheme.black,
//                         textAlign: "center",
//                     }} >
//                         {
//                             data.name
//                         }
//                     </h3>
//                 </CardActions>
//             </Card>

//             <AlertDialogBox
//                 isOpen={deleteDialogOpen}
//                 onClose={() => {
//                     setDeleteDialogOpen(false)
//                     setDeletingModelData(undefined)
//                 }}
//                 title={'Delete Model-' + deletingModelData?.name + "?"}
//                 successAction={<>
//                     <Button size='small' sx={{
//                         textTransform: "none",
//                         borderRadius: "6px",
//                         fontWeight: "bolder",
//                         fontSize: "10px"
//                     }} variant='contained' color='error' onClick={() => {
//                         if (deletingModelData) {
//                             deleteModel(deletingModelData._id)
//                         }
//                     }}>
//                         Delete
//                     </Button>
//                 </>}
//             />

//         </>

//     )
// }

export default ModelsListComponent
import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import WorkCardComponent from './WorkCard';
import { ContentDialogBox } from '../../../components/dialog-box';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { HowItWorksResources } from './Resources';

export type HowItWorksPageProps = {
}

export default function HowItWorksPage(props: HowItWorksPageProps) {
    const [htmlInformationDialogInfo, setHtmlInformationDialogInfo] = React.useState({
        open: false,
        url: '',
    });
    const [bookInformationDialogInfo, setBookInformationDialogInfo] = React.useState({
        open: false,
        url: '',
    });
    return (
        <div>
            <DashboardLayout title='How It Works'>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "20px",
                    padding: "20px",
                }}>
                    {
                        HowItWorksResources.map((resource, index) => (
                            <WorkCardComponent
                                image={resource.image}
                                title={resource.title}
                                description={resource.description}
                                onOpen={() => {
                                    if (resource.type === "Information") {
                                        setHtmlInformationDialogInfo({
                                            open: true,
                                            url: resource.url,
                                        });
                                    } else if (resource.type === "Cheat sheet") {
                                        setBookInformationDialogInfo({
                                            open: true,
                                            url: resource.url,
                                        });
                                    }
                                }}
                                type={resource.type}
                                index={index+1}
                            />
                        ))
                    }
                </div>
                <ContentDialogBox
                    isOpen={htmlInformationDialogInfo.open}
                    onClose={() => setHtmlInformationDialogInfo({ ...htmlInformationDialogInfo, open: false })}
                    isTransition={true}
                    transitionDirection='left'
                    fullScreen
                    contentStyle={{
                        padding: 0,
                        margin: 0,
                    }}
                    content={<>
                        <Button variant='contained' color='warning' sx={{
                            position: "fixed",
                            top: "10px",
                            right: "10px",
                            zIndex: 9999999,
                        }} onClick={() => {
                            setHtmlInformationDialogInfo({ ...htmlInformationDialogInfo, open: false })
                        }}>
                            <CloseIcon />
                        </Button>
                        <iframe src={htmlInformationDialogInfo.url} style={{
                            width: "100%",
                            height: "100vh",
                            border: "none",
                            overflow: "hidden",
                        }}></iframe>
                    </>}
                />
                <ContentDialogBox
                    isOpen={bookInformationDialogInfo.open}
                    onClose={() => setBookInformationDialogInfo({ ...bookInformationDialogInfo, open: false })}
                    isTransition={true}
                    transitionDirection='right'
                    fullScreen
                    contentStyle={{
                        padding: 0,
                        margin: 0,
                    }}
                    content={<>
                        <Button variant='contained' color='warning' sx={{
                            position: "fixed",
                            top: "10px",
                            right: "10px",
                            zIndex: 9999999,
                        }} onClick={() => {
                            setBookInformationDialogInfo({ ...bookInformationDialogInfo, open: false })
                        }}>
                            <CloseIcon />
                        </Button>
                        <iframe src={bookInformationDialogInfo.url} style={{
                            width: "100%",
                            height: "100vh",
                            border: "none",
                            overflow: "hidden",
                        }}></iframe>
                    </>}
                />
            </DashboardLayout>
        </div>
    );
}

import React, { useState } from "react";
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Box } from "@mui/material";

interface CardProps {
    image: string;
    title: string;
    description: string;
    onOpen: () => void;
    type?: string;
}

const SubmitButtonCardCard: React.FC<CardProps> = ({ image, title, description, onOpen, type }) => {
    const [flipped, setFlipped] = useState(false);

    return (
        <Box
            sx={{
                perspective: "1000px",
                display: "inline-block",
            }}
            onMouseEnter={() => setFlipped(true)}
            onMouseLeave={() => setFlipped(false)}
        >
            <Box
                sx={{
                    position: "relative",
                    width: 200,
                    minHeight: 250,
                    transformStyle: "preserve-3d",
                    transition: "transform 0.6s",
                    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
                }}
            >
                {/* Front Side */}
                <Card
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "200px",
                        backfaceVisibility: "hidden",
                        borderRadius: 3,
                        boxShadow: 3,
                        padding: 2,
                    }}
                >
                    {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> */}
                        <img src={image} style={{
                            width: "200px",
                            left: 0,
                            top: 0,
                            margin: "auto",
                            position:"absolute",
                            maxHeight: "200px",
                        }} alt="" />
                    {/* </div> */}
                    <CardContent sx={{ textAlign: "center", background:"#00000066", color:"white" }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 0,color:"white" }}>
                            {description.slice(0, 150)}
                        </Typography>
                    </CardContent>
                </Card>

                {/* Back Side */}
                <Card
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "200px",
                        // backgroundColor: "info.main",
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "rotateY(180deg)",
                        backfaceVisibility: "hidden",
                        borderRadius: 3,
                        boxShadow: 3,
                        background: `url(${image}) center/cover no-repeat`,
                    }}
                >
                    {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                        <img src={"https://storage.googleapis.com/educobot-robotics/images/set-1/cute-artificial-intelligence-robot-isometric-icon.png"} style={{
                            maxWidth: "300px",
                            maxHeight: "200px",
                        }} alt="" />
                    </div> */}
                    {/* <Typography variant="h5" sx={{ fontWeight: "bold", color: "white", position: "absolute", top: "20px", maxWidth: "80%", textAlign: "center" }}>
                        {title}
                    </Typography> */}
                    <Button
                        size="large"
                        variant="contained"
                        color="info"
                        sx={{ borderRadius: "5px", textTransform: "none", fontWeight: "bold", position: "absolute", bottom: "30px", padding: "5px 50px", fontSize: "1.2rem", width: "80%" }}
                        onClick={onOpen}
                    >
                        Submit
                    </Button>
                </Card>
            </Box>
        </Box>
    );
};

export default SubmitButtonCardCard;
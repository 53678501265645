import React, { useContext, useEffect, useState } from 'react'
import { BackendConfigs } from '../../../../config.environment'
import { UserContext } from '../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from '../../../../types/Response'
import { routes } from '../../../../routes/routes'
import { Button, Card, Checkbox, Container, TextField, Typography } from '@mui/material'
import { ContentDialogBox } from '../../../../components/dialog-box'
import { FileUploader } from 'react-drag-drop-files'
import { uploadFileOnS3 } from '../../../../services/S3Storage/Upload'
import TheoryComponent from './SubmittionsComponents/Theory'
import CircuitBuildingComponent from './SubmittionsComponents/Circuit'
import CodeBuildingComponent from './SubmittionsComponents/Coding'
import IOTBuildComponent from './SubmittionsComponents/IOT'
import { LessonBuilderStudentLessonType } from '../../../../types/Lesson'
import TheoryAttemptComponent from './SubmittionsComponents/Theory'
import CodeBuildingAttemptComponent from './SubmittionsComponents/Coding'
import CircuitBuildingAttemptComponent from './SubmittionsComponents/Circuit'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import IOTBuildingAttemptComponent from './SubmittionsComponents/IOT'

type Props = {}

const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG", "HEIC"];

function LessonBuilderLessonPage({ }: Props) {
    const { user } = useContext(UserContext)
    const { lessonId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();







    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isReviewTab, setIsReviewTab] = useState(false)
    const [lessonName, setLessonName] = useState<string>("")
    const [lessonDescription, setLessonDescription] = useState<string>("")
    const [selectedEditors, setSelectedEditors] = useState<string[]>([])
    const [lessonType, setLessonType] = useState<string>("")
    const [theory, setTheory] = useState<{
        type: "theory" | "mcq",
        question: string,
        correctAnswer: string,
        options: string[]
    }[]>([])
    const [circuitInstructions, setCircuitInstructions] = useState<string[]>([])
    const [circuitJSON, setCircuitJSON] = useState<string>("{}")
    const [micropythonInstructions, setMicropythonInstructions] = useState<string[]>([])
    const [micropythonXML, setMicropythonXML] = useState<string>("")
    const [monitorInstructions, setMonitorInstructions] = useState<string[]>([])
    const [monitorJSON, setMonitorJSON] = useState<string>("[]")
    const [aiInstructions, setAIInstructions] = useState<string[]>([])
    const [aiLogicXML, setAILogicXML] = useState<string>("")
    const [aiModelID, setAIModelID] = useState<string>("")
    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const [isImageWindowOpen, setIsImageWindowOpen] = React.useState(true)
    const [image, setImage] = React.useState<string | null>(null)

    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate()
    const [studentData, setStudentData] = useState<LessonBuilderStudentLessonType>()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isReviewed, setIsReviewed] = useState(false)
    const [isEditable, setIsEditable] = useState(false)

    async function getLessonData() {
        console.log("Getting Lesson Data", lessonId, searchParams);
        try {
            const response = await fetch(`${BackendConfigs.url}/GetStudentLessonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lessonId: lessonId,
                    userId: searchParams.get("userId")
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (!!result.data) {
                    const lessonData = result.data as LessonBuilderStudentLessonType
                    setStudentData(result.data)
                    console.log(lessonData);
                    setIsSubmitted(lessonData.submission && lessonData.submission.isSubmitted)
                    setIsReviewed(lessonData.submission && lessonData.submission.isReviewed)
                    setLessonName(lessonData.lesson.name)
                    setLessonDescription(lessonData.lesson.description)
                    setLessonType(lessonData.lesson.lessonType)
                    setSelectedEditors(lessonData.lesson.editors)
                    setMonitorInstructions(lessonData.lesson.iotInstructions.instructions)
                    setMicropythonInstructions(lessonData.lesson.codeInstructions.instructions)
                    setCircuitInstructions(lessonData.lesson.circuitInstructions.instructions)
                    setAIInstructions(lessonData.lesson.aiLogicInstructions.instructions)
                    // console.log(lessonData.submission.theory.length > 0);
                    if (!!lessonData.submission && !lessonData.submission.isSubmitted) {
                        setIsEditable(true)
                    }
                    if (!!lessonData.submission && !!lessonData.submission.theory && lessonData.submission.theory.length > 0) {
                        setTheory(lessonData.submission.theory)
                    } else {
                        console.log("Setting Theory");
                        setTheory(lessonData.lesson.theory.map((question) => {
                            if (question.type === "mcq") {
                                return {
                                    type: "mcq",
                                    question: question.question,
                                    correctAnswer: " ",
                                    options: question.options
                                }
                            } else {
                                return {
                                    type: "theory",
                                    question: question.question,
                                    correctAnswer: " ",
                                    options: []
                                }
                            }
                        }))
                    }
                    // console.log(lessonData.submission.circuitInstructions.circuit);
                    if (!!lessonData.submission && !!lessonData.submission.circuitInstructions && lessonData.submission.circuitInstructions.circuit && lessonData.submission.circuitInstructions.circuit !== "{}") {
                        setCircuitJSON(lessonData.submission.circuitInstructions.circuit)
                    }
                    else {
                        console.log("lessonData.lesson.circuitInstructions.circuitJSON", lessonData.lesson.circuitInstructions.circuitJSON);

                        setCircuitJSON(lessonData.lesson.circuitInstructions.circuitJSON)
                    }
                    if (!!lessonData.submission && !!lessonData.submission.codeInstructions && lessonData.submission.codeInstructions.xml.length > 0) {
                        setMicropythonXML(lessonData.submission.codeInstructions.xml || "< xml ></xml>")
                    }
                    else {
                        setMicropythonXML(lessonData.lesson.codeInstructions.micropythonXML || "< xml ></xml>")
                    }
                    if (!!lessonData.submission && !!lessonData.submission.iotInstructions && lessonData.submission.iotInstructions.monitorJSON.length > 2) {
                        setMonitorJSON(lessonData.submission.iotInstructions.monitorJSON || "[]")
                    }
                    else {
                        setMonitorJSON(lessonData.lesson.iotInstructions.monitorJSON || "[]")
                    }
                    if (!!lessonData.submission && !!lessonData.submission.aiLogicInstructions && lessonData.submission.aiLogicInstructions.logicXML.length > 2) {
                        setAILogicXML(lessonData.submission.aiLogicInstructions.logicXML || "< xml ></xml>")
                    }
                    else {
                        setAILogicXML(lessonData.lesson.aiLogicInstructions.aiLogicXML || "< xml ></xml>")
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        console.log(searchParams.get("userId"));

        if (!!user.userData && lessonId) {
            getLessonData()
        }
    }, [user, lessonId, searchParams])


    async function saveLesson() {
        handleGlobalLoading(true, "Saving Lesson")
        try {
            const response = await fetch(`${BackendConfigs.url}/SaveStudentLessonProgressAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lessonId: lessonId,
                    userId: searchParams.get("userId"),
                    theory: theory,
                    circuitInstructions: {
                        circuit: circuitJSON
                    },
                    codeInstructions: {
                        xml: micropythonXML
                    }
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {

                    toast.success("Lesson Saved Successfully")
                } else {
                    toast.error("Failed to save lesson")
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false, "Saving Lesson")
    }

    async function submitLesson() {
        handleGlobalLoading(true, "Submitting Lesson")
        try {
            await saveLesson()
            const response = await fetch(`${BackendConfigs.url}/SubmitStudentLessonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lessonId: lessonId,
                    userId: searchParams.get("userId"),
                    imageSubmissionURL: image,

                })
            })

            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    setIsSubmitted(true)
                    toast.success("Lesson Submitted Successfully")
                } else {
                    toast.error("Failed to submit lesson")
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false, "Submitting Lesson")
    }


    const handleChange = async (file: File) => {
        handleGlobalLoading(true, "Uploading Image")
        const extension = file.name.split(".").pop();
        const newName = `${Date.now()}`;
        console.log(file, extension, newName);
        setFile(file);
        if (!!file) {

            try {
                const response = await uploadFileOnS3(file, newName) as {
                    location: string,
                    bucket: string,
                    key: string
                };
                console.log(response);
                if (!!response.location) {
                    setImage(response.location)
                    setIsImageWindowOpen(false)
                    toast.info("Image uploaded successfully")
                }
            } catch (error) {
                console.log(error);
            }
        }
        handleGlobalLoading(false, "Uploading Image")
    };
    if (!studentData) {
        return <></>
    }
    return (
        <>
            <div style={{
                minHeight: "90vh",
                display: 'flex',
                margin: "0 auto",
                // justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
                width: "95%"
            }}>

                <Card style={{
                    padding: "20px"
                }}>

                    {
                        !searchParams.get("teacherId") && !isReviewed && !isSubmitted &&
                        <h2 style={{
                            textAlign: "center"
                        }}> Attempt Lesson Here</h2>
                    }
                    <div style={{
                        margin: "10px 0"
                    }}>
                        <span style={{
                            color: eduCOBOTColorScheme.gray
                        }}>
                            Lesson Name :
                        </span> {lessonName == "" ? "Name is required Please Provide Lesson Name" : lessonName}
                    </div>
                    <div style={{
                        margin: "10px 0"
                    }}>
                        <span style={{
                            color: eduCOBOTColorScheme.gray
                        }}>
                            Lesson Description :
                        </span> {lessonDescription == "" ? "Description is required Please Provide Lesson Name" : lessonDescription}
                    </div>

                    <div style={{
                        margin: "10px 0"
                    }}>
                        <span style={{
                            color: eduCOBOTColorScheme.gray
                        }}>
                            Lesson Type :
                        </span> {lessonType == "" ? "Description is required Please Provide Lesson Name" : lessonType + " Lesson"}
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: settings.screen == "mobile" ? "column" : "row",
                        gap: "10px"
                    }}>
                        {
                            selectedEditors.includes("theory") && studentData && studentData.lesson.theory.length > 0 && <TheoryAttemptComponent theory={
                                theory
                            } setTheory={(theory_) => {
                                setTheory(theory_)
                            }} isEditable={isEditable} />
                        }
                        {
                            selectedEditors.includes("micropython-editor") && studentData && studentData.lesson.codeInstructions && studentData.lesson.codeInstructions.micropythonXML != "" && <CodeBuildingAttemptComponent instructions={micropythonInstructions} setInstructions={setMicropythonInstructions} xml={micropythonXML} setXml={setMicropythonXML} />
                        }
                        {
                            selectedEditors.includes("circuit-designer") && studentData && studentData.lesson.circuitInstructions && studentData.lesson.circuitInstructions.circuitJSON !== "{}" && <CircuitBuildingAttemptComponent instructions={circuitInstructions} setInstructions={setCircuitInstructions} referenceCircuitJSON={circuitJSON} setReferenceCircuitJSON={setCircuitJSON} />
                        }
                        {
                            selectedEditors.includes("iot-monitor") && studentData && studentData.lesson.iotInstructions && studentData.lesson.iotInstructions.monitorJSON !== "{}" && <IOTBuildingAttemptComponent instructions={monitorInstructions} setInstructions={() => { }} monitorJSON={monitorJSON} setMonitorJSON={setMonitorJSON} />
                        }
                    </div>

                </Card>
                {
                    isSubmitted && studentData.submission.imageSubmissionURL && <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        margin: "10px 0",
                        border: "1px solid gray",
                    }}>
                        <h4>
                            Submitted Image
                        </h4>
                        <img src={studentData.submission.imageSubmissionURL} alt="" style={{
                            maxWidth: "300px",
                            margin: "10px 0"
                        }} />
                    </div>
                }
                {
                    !isSubmitted ? searchParams.get("userId") && !searchParams.get("teacherId") && <>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            padding: "10px 0"
                        }}>
                            <Button variant="contained" color="info" sx={{
                                textTransform: "none",
                                borderRadius: "6px",
                                fontWeight: "bolder",
                                // fontSize: "10px",
                                // padding: "10px 50px",
                                fontSize: "1.5rem",
                            }} onClick={() => {
                                saveLesson()
                            }}>
                                Save Assignment
                            </Button>
                        </div>


                        <Card sx={{
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // margin: "10px 5px",
                            flexDirection: "column",
                            width: "90%",
                            margin: "10px auto",
                            height: "100%"
                        }}>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "10px 0"
                            }}>
                                <img src="https://cdn-icons-png.flaticon.com/512/10550/10550854.png" style={{
                                    maxWidth: "80px"
                                }} alt="" />
                                <h2>
                                    Please Perform All tasks that teacher assigned you
                                </h2>
                                <p>Once submitted, it will not be editable</p>
                            </div>
                            <div id='ProjectCreateContainer' style={{
                                padding: 10,
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                gap: "30px",
                            }}>
                                <div style={{
                                    margin: "10px 0",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}>
                                    <p>
                                        Upload Image for submission (Optional)
                                    </p>
                                    {
                                        isImageWindowOpen ?

                                            <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} style={{ width: "min(500px,90%)" }} classes="file_uploader" /> : image && <img src={image} alt="" style={{
                                                maxWidth: "200px",
                                            }} />
                                    }
                                </div>

                            </div>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                padding: "10px 0"
                            }}>
                                <Button variant="contained" color="success" sx={{
                                    width: "70%",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    fontWeight: "bolder",
                                    // fontSize: "10px",
                                    padding: "10px 50px",
                                    fontSize: "1.5rem",
                                }} onClick={() => {
                                    submitLesson()
                                }}>
                                    Submit Assignment
                                </Button>
                            </div>

                        </Card>

                    </> : <>
                        {
                            !isReviewed && <>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                }}>
                                    <h1>Submitted</h1>
                                    <div
                                        style={{
                                            width: 'min(80%,400px)',
                                        }}
                                    >

                                        <DotLottieReact
                                            src="/animations/Submitted2.lottie"
                                            autoplay
                                        />
                                    </div>
                                </div>



                            </>
                        }
                        {
                            searchParams.get("teacherId") && searchParams.get("teacherId") == user.userData?.id
                                ? <>

                                    <CoinAssignmentComponent studentData={studentData} />

                                </> : isReviewed && <>
                                    <h2 style={{
                                        textAlign: "center",
                                        marginTop: "30px"
                                    }}>

                                        You have assigned {(Object.values(studentData.submission.coins).reduce((acc, value) => acc + value) / Object.values(studentData.submission.coins).filter(value => value != 0).length || 1).toFixed(2)} average coins, previously
                                    </h2>

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        {
                                            Object.keys(studentData.submission.coins).map((key: string, index) => {
                                                if (key === "theory" && !studentData.lesson.editors.includes("theory")) return <></>
                                                if (key === "circuit" && !studentData.lesson.editors.includes("circuit-designer")) return <></>
                                                if (key === "code" && !studentData.lesson.editors.includes("micropython-editor")) return <></>
                                                if (key === "iot" && !studentData.lesson.editors.includes("iot")) return <></>
                                                if (key === "aiLogic" && !studentData.lesson.editors.includes("ai-logic")) return <></>
                                                if (key === "assembly") return <></>

                                                let coin = studentData.submission.coins[key as keyof typeof studentData.submission.coins]
                                                return <div key={index} style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    margin: "10px",
                                                    padding: "10px",
                                                    border: "1px solid white",
                                                    borderRadius: "10px"
                                                }}>
                                                    <h4 style={{
                                                        width: "100px"
                                                    }}>{key.toUpperCase()}</h4>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: "10px"
                                                    }}>

                                                        {
                                                            [0, 0, 0].map((item, index) => {
                                                                return index < coin ?
                                                                    <div key={index}>
                                                                        <img src={"https://storage.googleapis.com/open-editor-assets/website-statics/100-coin.png"} alt="" style={{
                                                                            cursor: "pointer",
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            margin: "10px",

                                                                        }} />
                                                                    </div> : <div key={index}>
                                                                        <img src={"https://storage.googleapis.com/open-editor-assets/website-statics/0-coin.png"} alt="" style={{
                                                                            cursor: "pointer",
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            margin: "10px",
                                                                        }} />
                                                                    </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div>


                                </>
                        }
                    </>
                }

            </div>
            <ContentDialogBox
                isOpen={isCreateSuccess}
                onClose={() => {
                    // setIsCreateSuccess(false)
                    // setIsCreateDialogOpen(false)
                    // setIsDataChanged(true)
                }}
                title={<>
                    <h4 style={{
                        color: eduCOBOTColorScheme.gray,
                        marginLeft: "15px",
                    }}>
                        Lesson Created Successfully
                    </h4>

                </>}
                backdropStyle={{
                    backgroundColor: "transparent"
                }}
                paperStyle={{
                    background: "transparent",
                    borderRadius: 20,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    // alignItems: "center",
                    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.05)"

                }}
                content={<>
                    <div style={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        <div style={{
                            padding: "100px 20px"
                        }}>
                            <div className="loader1" ></div>
                        </div>
                        <h4 style={{
                            color: eduCOBOTColorScheme.gray,
                        }}>Redirecting to Lessons....!</h4>
                    </div>
                </>}
                maxWidth={"sm"}
            />
        </>

    )
}



function CoinAssignmentComponent({ studentData }: {
    studentData: LessonBuilderStudentLessonType
}) {
    const { handleGlobalLoading } = useContext(SettingContext)
    const [coins, setCoins] = useState<{
        circuit: number
        code: number
        iot: number
        aiLogic: number
        theory: number
    }>({
        circuit: 0,
        code: 0,
        iot: 0,
        aiLogic: 0,
        theory: 0
    })
    useEffect(() => {
        if (studentData && studentData.submission && studentData.submission.coins) {
            setCoins(studentData.submission.coins)
        }
    }, [studentData])
    async function assignCoins() {

        handleGlobalLoading(true, "Reviewing Lesson")
        try {
            const response = await fetch(`${BackendConfigs.url}/ReviewStudentLessonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lessonId: studentData.lesson.lessonId,
                    userId: studentData.user.userID,
                    coins: coins
                })
            })

            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success("Lesson Reviewed Successfully")
                } else {
                    toast.error("Failed to Review lesson")
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false, "Reviewing Lesson")



    }
    return <>
        {
            studentData && studentData.submission ? <>
                <Container>

                    <h1>Assign Coins</h1>
                    <hr style={{ border: "1px solid white" }} />
                    <div style={{
                        textAlign: "left"
                    }}>

                        {
                            studentData && studentData.user && <>
                                <p>
                                    Name: {studentData.user.euName}
                                </p>
                                <p>
                                    Class: {studentData.user.euClass}
                                </p>
                            </>
                        }
                    </div>
                    <hr style={{ border: "1px solid white" }} />
                    {
                        studentData && studentData.submission && <>
                            <p>
                                Lesson Name: {studentData.lesson.name}
                            </p>
                            <p>
                                Lesson Description: {studentData.lesson.description}
                            </p>
                        </>
                    }
                    <hr style={{ border: "1px solid white" }} />
                    {
                        studentData && studentData.submission && <>

                            <p>
                                Submitted on: {studentData.submission.createdAt}
                            </p>


                        </>
                    }
                    <hr style={{ border: "1px solid white" }} />
                    {
                        studentData && studentData.submission && Object.values(studentData.submission.coins).reduce((acc, value) => acc + value) > 0 ? <p> You have assigned {(Object.values(studentData.submission.coins).reduce((acc, value) => acc + value) / Object.values(studentData.submission.coins).filter(value => value != 0).length || 1).toFixed(2)} average coins, previously </p> : <p style={{
                            textAlign: "center"
                        }}>Assign Coin to {studentData && studentData.user && studentData.user.euName}</p>
                    }
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        {
                            Object.keys(coins).map((key: string, index) => {
                                if (key === "theory" && !studentData.lesson.editors.includes("theory")) return <></>
                                if (key === "circuit" && !studentData.lesson.editors.includes("circuit-designer")) return <></>
                                if (key === "code" && !studentData.lesson.editors.includes("micropython-editor")) return <></>
                                if (key === "iot" && !studentData.lesson.editors.includes("iot")) return <></>
                                if (key === "aiLogic" && !studentData.lesson.editors.includes("ai-logic")) return <></>
                                if (key === "assembly") return <></>
                                let coin = coins[key as keyof typeof coins]
                                return <div key={index} style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "10px",
                                    padding: "10px",
                                    border: "1px solid white",
                                    borderRadius: "10px"
                                }}>
                                    <h4 style={{
                                        width: "100px"
                                    }}>{key.toUpperCase()}</h4>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px"
                                    }}>
                                        {
                                            [0, 0, 0].map((item, index) => {
                                                return index < coin ?
                                                    <div key={index}>
                                                        <img src={"https://storage.googleapis.com/open-editor-assets/website-statics/100-coin.png"} alt="" style={{
                                                            cursor: "pointer",
                                                            width: "50px",
                                                            height: "50px",
                                                            margin: "10px",
                                                        }} onClick={() => {
                                                            setCoins({
                                                                ...coins,
                                                                [key]: index + 1
                                                            })
                                                        }} />
                                                    </div> : <div key={index}>
                                                        <img src={"https://storage.googleapis.com/open-editor-assets/website-statics/0-coin.png"} alt="" style={{
                                                            cursor: "pointer",
                                                            width: "50px",
                                                            height: "50px",
                                                            margin: "10px",
                                                        }} onClick={() => {
                                                            setCoins({
                                                                ...coins,
                                                                [key]: index + 1
                                                            })
                                                        }} />
                                                    </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }


                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Button

                            variant="contained"
                            color="warning"
                            onClick={() => { studentData && studentData.submission && Object.values(coins).reduce((acc, value) => acc + value) > 0 && assignCoins() }}
                            style={{
                                margin: "10px",
                                width: "min(200px,80%)"
                            }}
                        >
                            Assign {studentData && studentData.submission.isReviewed ? "Again" : "Coins"}
                        </Button>
                    </div>
                </Container>
            </> :
                <>
                    <h2>Student Not Submitted the Assignment</h2>
                </>
        }

    </>


}








export default LessonBuilderLessonPage
import { ConnectorModel, NodeConstraints, NodeModel } from "@syncfusion/ej2-react-diagrams";
import { ADXL345Ports, BOMotorPorts, breadBoardPorts, BuzzerComponentPorts, BuzzerPorts, CeramicCapacitorPorts, DCMotorPorts, dht11Ports, DotMatrixPorts, ElectrolyticCapacitorPorts, esp32Ports, GreenLEDPorts, IRPorts, KeypadPorts, LCDPorts, LDRPorts, LEDPorts, LightDependentResistorPorts, newShieldPorts, OLEDPorts, OneKRegisterPorts, OneMRegisterPorts, PNPTransistorPorts, PushButtonComponentPorts, PushButtonPorts, RedLEDPorts, RFIDPorts, RGB_LED_Ports, SensorModulePorts, ServoPorts, SevenSegmentPorts, shieldPorts, SlideSwitchPorts, SmokeSensorPorts, SoilMoisturePorts, TenKRegisterPorts, TouchPorts, TransistorPorts, ultrasonicPorts, WhiteLEDPorts, YellowLEDPorts } from "./Ports";



export const firmwares: NodeModel[] = [
    {
        constraints: NodeConstraints.Default & NodeConstraints.InConnect, id: "Breadboard-400-Pins", width: 632, height: 409, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Breadboard_400_Pin.svg ", }, ports: breadBoardPorts, tooltip: {
            content: 'Breadboard',
            relativeMode: 'Mouse',
        },
    },
    {
        constraints: NodeConstraints.Default, id: "New-eduCOBOT-Shield", rotateAngle: 180, width: 522, height: 559, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/New_Sheild.svg", }, ports: newShieldPorts, tooltip: {
            content: 'New eduCOBOT Shield with ESP32',
            relativeMode: 'Mouse',
        }
    },
    {
        constraints: NodeConstraints.Default, id: "esp32", rotateAngle: 180, width: 198, height: 371, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg", }, ports: esp32Ports, tooltip: {
            content: 'ESP32',
            relativeMode: 'Mouse',
        }
    },
];

export const inputSensors: NodeModel[] = [

    {
        constraints: NodeConstraints.Default, rotateAngle: 90, id: "IR-Sensor", width: 362 * 0.38, height: 633 * 0.38, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/IR%20Sensor.svg", }, ports: IRPorts, tooltip: {
            content: 'IR Sensor',
            relativeMode: 'Mouse',
        }
    },
    {
        constraints: NodeConstraints.Default, id: "Touch-Sensor", rotateAngle: -90, width: 362 * 0.38, height: 513 * 0.38, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Touch+Sensor.svg", }, ports: TouchPorts, tooltip: {
            content: 'Touch Sensor',
            relativeMode: 'Mouse',
        }
    },

    {
        constraints: NodeConstraints.Default, id: "LDR-Sensor", rotateAngle: 90, height: 0.335 * 812, width: 0.335 * 476, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/LDR_PCB.svg", }, ports: LDRPorts, tooltip: {
            content: 'LDR Sensor',
            relativeMode: 'Mouse',
        }
    },
    {
        constraints: NodeConstraints.Default, id: "Tactile-Switch", width: 275, height: 350, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Switch.svg", }, ports: PushButtonPorts, tooltip: {
            content: 'Push Button',
            relativeMode: 'Mouse',
        }
    },
    {
        constraints: NodeConstraints.Default, rotateAngle: 90, id: "DHT11-Sensor", width: 0.5 * 149, height: 0.5 * 374, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DHT11.svg", }, ports: dht11Ports, tooltip: {
            content: 'DHT11 Sensor',
            relativeMode: 'Mouse',
        }
    },
    { constraints: NodeConstraints.Default, id: "MQ2-Sensor", height: 0.6 * 252, width: 0.6 * 142, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Smoke-Sensor.png", }, ports: SmokeSensorPorts },

    { constraints: NodeConstraints.Default, id: "UltraSonic", rotateAngle: 180, width: 0.6 * 529, height: 0.6 * 293, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Ultrasonic.svg", }, ports: ultrasonicPorts },

    { constraints: NodeConstraints.Default, id: "RFID", width: 0.5 * 383, height: 0.5 * 648, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RFID.svg", }, ports: RFIDPorts },

    { constraints: NodeConstraints.Default, id: "Keypad", rotateAngle: 180, width: 373, height: 605, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Key_Pad.svg", }, ports: KeypadPorts },
    { constraints: NodeConstraints.Default, id: "Soil-Moisture", width: 83, height: 405, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Soil-Moisture.png", }, ports: SoilMoisturePorts },
]

export const outputSensors: NodeModel[] = [
    // { constraints: NodeConstraints.Default, id: "LED", width: 0.3 * 883, height: 0.3 * 623, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LED_PCB.svg", }, ports: LEDPorts },
    { constraints: NodeConstraints.Default, id: "DC-Motor", width: 0.3 * 523, height: 0.3 * 653, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DC+Motor.svg", }, ports: DCMotorPorts },
    { constraints: NodeConstraints.Default, id: "RGB-LED", rotateAngle: -90, width: 0.3 * 523, height: 0.3 * 673, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RGB_LED.svg", }, ports: RGB_LED_Ports },
    { constraints: NodeConstraints.Default, id: "OLED", width: 0.6 * 257, height: 0.6 * 233, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/OLED_1.svg", }, ports: OLEDPorts },


    // { constraints: NodeConstraints.Default, id: "Buzzer", width: 0.33 * 771, height: 0.33 * 578, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Buzzer_PCB.svg", }, ports: BuzzerPorts },

    { constraints: NodeConstraints.Default, id: "LCD", width: 0.65 * 662, height: 0.65 * 265, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/LCD.png", }, ports: LCDPorts },
    // {constraints:NodeConstraints.Default , id: "8x8-Matrix", width: 50, height: 80, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32.png", } },
    { constraints: NodeConstraints.Default, id: "Accelerometer", width: 0.6 * 156, height: 0.6 * 114, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ADXL345.svg", }, ports: ADXL345Ports },
    { constraints: NodeConstraints.Default, rotateAngle: 90, id: "Dot-Matrix", width: 0.6 * 659, height: 0.6 * 357, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Dot-matrix.png", }, ports: DotMatrixPorts },
    { constraints: NodeConstraints.Default, id: "BO-Motor", width: 0.6 * 219, height: 0.6 * 617, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/BO%20Motor.svg", }, ports: BOMotorPorts },
    { constraints: NodeConstraints.Default, id: "Servo", width: 0.6 * 335, height: 0.6 * 250, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Servo.svg", }, ports: ServoPorts },
    { constraints: NodeConstraints.Default, id: "7-Segment", width: 0.6 * 335, height: 0.6 * 800, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Seven%20Segment.svg", }, ports: SevenSegmentPorts },
    { constraints: NodeConstraints.Default, id: "Sensor-Module", width: 0.6 * 335, height: 0.6 * 450, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Sensor_Module.svg", }, ports: SensorModulePorts },
]


export const BreadBoardComponents: NodeModel[] = [
    // { constraints: NodeConstraints.Default, id: "LED", width: 0.3 * 883, height: 0.3 * 623, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LED_PCB.svg", }, ports: LEDPorts },
    { constraints: NodeConstraints.Default, id: "Green-LED", width: 0.15 * 183, height: 0.15 * 953, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Green%20LED.svg", }, ports: GreenLEDPorts },
    
    { constraints: NodeConstraints.Default, id: "RED-LED", width: 0.15 * 183, height: 0.15 * 953, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Red%20LED.svg", }, ports: RedLEDPorts },
    
    { constraints: NodeConstraints.Default, id: "White-LED", width: 0.15 * 183, height: 0.15 * 953, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/White%20LED.svg", }, ports: WhiteLEDPorts },
    
    
    { constraints: NodeConstraints.Default, id: "Electrolytic-Capacitor", width: 0.15 * 323, height: 0.15 * 953, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Electrolytic%20Capacitor.svg", }, ports: ElectrolyticCapacitorPorts },
    { constraints: NodeConstraints.Default, id: "Yellow-LED", width: 0.15 * 183, height: 0.15 * 953, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Yellow%20LED.svg", }, ports: YellowLEDPorts },
    { constraints: NodeConstraints.Default, id: "Push-Button", width: 0.15 * 93, height: 0.15 * 203, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Push%20Button.svg", }, ports: PushButtonComponentPorts },
    { constraints: NodeConstraints.Default, id: "Ceramic-Capacitor", width: 0.15 * 523, height: 0.15 * 953, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Ceremic%20Capacitor.svg", }, ports: CeramicCapacitorPorts },
    { constraints: NodeConstraints.Default, id: "10K-Register", width: 0.15 * 523, height: 0.15 * 353, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/10K%20Ohm%20Resistor.svg", }, ports: TenKRegisterPorts },

    { constraints: NodeConstraints.Default, id: "1K-Register", width: 0.15 * 523, height: 0.15 * 353, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/1K%20Ohm%20Resistor.svg", }, ports: OneKRegisterPorts },

    { constraints: NodeConstraints.Default, id: "1M-Register", width: 0.15 * 523, height: 0.15 * 353, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/1M%20Ohm%20Resistor.svg", }, ports: OneMRegisterPorts },
    { constraints: NodeConstraints.Default, id: "Light-Dependent-Resistor", width: 0.15 * 323, height: 0.15 * 353, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/LDR.svg", }, ports: LightDependentResistorPorts },






    // { constraints: NodeConstraints.Default, id: "Transistor", width: 0.15 * 223, height: 0.15 * 653, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Transistor.svg", }, ports: TransistorPorts },

    { constraints: NodeConstraints.Default, id: "PNP-Transistor", width: 0.15 * 333, height: 0.15 * 803, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/PNP%20Transistor%20(2).svg", }, ports: PNPTransistorPorts },

    { constraints: NodeConstraints.Default, id: "Slide-Switch", width: 0.15 * 523, height: 0.15 * 653, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Slide_Switch.svg", }, ports: SlideSwitchPorts },
    { constraints: NodeConstraints.Default, id: "Buzzer-Component", width: 0.15 * 523, height: 0.15 * 553, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Buzzer.svg", }, ports: BuzzerComponentPorts },
    { constraints: NodeConstraints.Default, id: "PNP-Transistor-3", width: 0.15 * 323, height: 0.15 * 653, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/PNP%20Transistor%20(3).svg", }, ports:PNPTransistorPorts },

    { constraints: NodeConstraints.Default, id: "DIP-IC", width: 0.15 * 940, height: 0.15 * 403, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/DIP%20IC.svg", }, },
    { constraints: NodeConstraints.Default, id: "AND-GATE", width: 0.15 * 940, height: 0.15 * 403, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/AND%20Gate.svg", }, },
    { constraints: NodeConstraints.Default, id: "OR-GATE", width: 0.15 * 940, height: 0.15 * 403, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/OR%20Gate.svg", }, },
    { constraints: NodeConstraints.Default, id: "NOT-GATE", width: 0.15 * 940, height: 0.15 * 403, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/NOT%20GATE.svg", }, },


]



//Initializes connector symbols for the symbol palette
export const connectorSymbols: ConnectorModel[] = [
    {
        id: "VCC-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        allowNodeOverlap: false,
        style: { strokeWidth: 5, strokeColor: 'red' },
        targetDecorator: { shape: "None" },
        connectionPadding: 10,
        connectorSpacing: 10,
        tooltip: {
            content: "VCC Pin",
        }
    },
    {
        id: "GND-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        style: { strokeWidth: 5, strokeColor: 'black' },
        targetDecorator: { shape: "None" },
        tooltip: {
            content: "GND Pin",
        }
    },
    {
        id: "Digital-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        style: { strokeWidth: 5, strokeColor: 'blue' },
        targetDecorator: { shape: "None" },
        tooltip: {
            content: "Digital Pin",
        }
    },
    {
        id: "Analog-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        style: { strokeWidth: 5, strokeColor: 'gray' },
        targetDecorator: { shape: "None" },
        tooltip: {
            content: "Analog Pin",
            relativeMode: 'Mouse',
        }
    },
];





import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import MyCourses from './MyCourses';
import { isReactNative } from '../../../utils/ReactNativeHandler';

export type MyCoursesPageProps = {
}

export default function MyCoursesPage(props: MyCoursesPageProps) {
    if(isReactNative()){
        return (
            <MyCourses/>
        )
    }
    return (
        <div>
            <DashboardLayout title='My Courses'>
                <MyCourses/>
            </DashboardLayout>
        </div>
    );
}

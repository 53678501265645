import * as ReactDOM from "react-dom";
import * as React from "react";
import {
    DiagramComponent,
    SymbolInfo,
    IDragEnterEventArgs,
    SymbolPaletteComponent,
    NodeModel,
    ConnectorModel,
    Node,
    Connector,
    GridlinesModel,
    IExportOptions,
    DiagramTools,
    NodeConstraints,
    ConnectorConstraints,
    IHistoryChangeArgs,
    ISelectionChangeEventArgs,
    IScrollChangeEventArgs,
    PrintAndExport,
    UndoRedo,
    Inject,
    HistoryEntry,
    ShapeAnnotationModel,
    Segments,
} from "@syncfusion/ej2-react-diagrams";
import '../circuit-designer/index.css';
import { ToolbarComponent } from "@syncfusion/ej2-react-navigations";
import { DropDownButton } from "@syncfusion/ej2-react-splitbuttons";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { BreadBoardComponents, connectorSymbols, firmwares, inputSensors, outputSensors } from "../circuit-designer/configs/Symbols";
import { toolbarItems } from "../circuit-designer/configs/ToolbarItems";
import { toast } from "react-toastify";
import { eduCOBOTColor, eduCOBOTColorScheme } from "../../context/SettingsContext";
import { LessonPlayerContext } from "../../context/LessonPlayer";
import { getAllLinks } from "../../pages/Dashboard/Lessons/Lesson/Workspace/Circuitary/CircuitPlayer";
import { ContentDialogBox } from "../dialog-box";
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';




const nodes: NodeModel[] = [

];

const connectors: ConnectorModel[] = [

];

export function reduceOrScaleRatio(value: number, ratio: number) {
    return value * ratio
}








let interval: number[];
interval = [
    1,
    9,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75
];
const gridlines: GridlinesModel = {
    lineColor: "#FFFFFF",
    lineIntervals: interval
};
let diagramInstance: DiagramComponent;
let toolbarEditor: ToolbarComponent;

function CircuitDesigner() {
    let isMobile: boolean;
    const [circuitJSON, setCircuitJSON] = React.useState('{"nodes":[],"connectors":[]}');

    const [lineType, setLineType] = React.useState<Segments>('Bezier');
    const [lineColor, setLineColor] = React.useState('#000000');
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isConnectorColorChangeOn, setIsConnectorColorChangeOn] = React.useState(false)
    const { updateCircuit } = React.useContext(LessonPlayerContext)
    const [errorStuff, setErrorStuff] = React.useState({
        error: false,
        message: [] as string[]
    })
    let interval: any;
    React.useEffect(() => {
        console.log("toolbarItems", toolbarItems);

        setTimeout(() => {
            setIsLoaded(true);
            // console.log(value);

        }, 3000);
    }, [])

    React.useEffect(() => {
        clearTimeout(interval);
        interval = setTimeout(() => {
            clearTimeout(interval);
            setErrorStuff((pre) => ({
                error: false,
                message: issues
            }))
            // console.log("circuitJSON", circuitJSON);
            const allLinks = getAllLinks(circuitJSON) as string[];
            // console.log(allLinks);

            let issues = [] as string[];
            let isError = false;
            allLinks.forEach((link) => {
                const left = link.split("->")[0];
                const right = link.split("->")[1];
                if (left.toLowerCase().includes("vcc") && right.toLowerCase().includes("gnd")) {
                    issues.push("You can't connect VCC to GND");
                    issues.push("you have connected " + left + " Pin to " + right + " Pin")
                    isError = true;
                }
                if (left.toLowerCase().includes("gnd") && right.toLowerCase().includes("vcc")) {
                    issues.push("You can't connect GND to VCC");
                    issues.push("you have connected " + left + " Pin to " + right + " Pin")
                    isError = true;
                }
                if (left.toLowerCase().includes("digital") && right.toLowerCase().includes("gnd")) {
                    issues.push("You can't connect Digital Pin to GND");
                    issues.push("you have connected " + left + " Pin to " + right + " Pin")
                    isError = true;
                }
                if (left.toLowerCase().includes("gnd") && right.toLowerCase().includes("digital")) {
                    issues.push("You can't connect GND to Digital Pin");
                    issues.push("you have connected " + left + " Pin to " + right + " Pin")
                    isError = true;
                }
                if (left.toLowerCase().includes("vcc") && right.toLowerCase().includes("digital")) {
                    issues.push("You can't connect VCC to Digital Pin");
                    issues.push("you have connected " + left + " Pin to " + right + " Pin")
                    isError = true;
                }
                if (left.toLowerCase().includes("digital") && right.toLowerCase().includes("vcc")) {
                    issues.push("You can't connect Digital Pin to VCC");
                    issues.push("you have connected " + left + " Pin to " + right + " Pin")
                    isError = true;
                }
            })
            setErrorStuff((pre) => ({
                error: isError,
                message: issues
            }))
        }, 2000)
    }, [circuitJSON])





    function addEvents(): void {
        isMobile = window.matchMedia('(max-width:550px)').matches;
        if (isMobile) {
            const paletteIcon: HTMLElement = document.getElementById('palette-icon') as HTMLElement;
            if (paletteIcon) {
                paletteIcon.addEventListener('click', openPalette, false);
            }
        }
    }
    function openPalette(): void {
        const paletteSpace: HTMLElement = document.getElementById('palette-space') as HTMLElement;
        isMobile = window.matchMedia('(max-width:550px)').matches;
        if (isMobile) {
            if (!paletteSpace.classList.contains('sb-mobile-palette-open')) {
                paletteSpace.classList.add('sb-mobile-palette-open');
            } else {
                paletteSpace.classList.remove('sb-mobile-palette-open');
            }
        }
    }

    function printDiagram(args: any) {
        const options: IExportOptions = {};
        options.mode = 'Download';
        options.region = 'Content';
        options.multiplePage = diagramInstance.pageSettings.multiplePage;
        options.pageHeight = diagramInstance.pageSettings.height;
        options.pageWidth = diagramInstance.pageSettings.width;
        diagramInstance.print(options);
    }
    //To enable toolbar items.
    function enableItems() {
        toolbarEditor.items[6].disabled = false;
        toolbarEditor.items[7].disabled = false;
        toolbarEditor.items[19].disabled = false;
        toolbarEditor.items[20].disabled = false;
        toolbarEditor.items[25].disabled = false;
        toolbarEditor.items[29].disabled = false;
        toolbarEditor.items[31].disabled = false;
    }
    //To disable toolbar items while multiselection.
    function disableMultiselectedItems() {
        toolbarEditor.items[22].disabled = true;
        toolbarEditor.items[23].disabled = true;
        toolbarEditor.items[27].disabled = true;
    }
    //To handle toolbar click
    function toolbarClick(args: any) {
        // console.log("clicked", args.item.tooltipText);
        setIsConnectorColorChangeOn(false)
        const item = args.item.tooltipText;
        switch (item) {
            case 'Change Color':
                setIsConnectorColorChangeOn(true)
                break;
            case 'Undo':
                diagramInstance.undo();
                break;
            case 'Redo':
                diagramInstance.redo();
                break;
            case 'Lock':
                lockObject(args);
                break;
            case 'Unlock':
                unlockObject(args);
                break;
            case 'Cut':
                diagramInstance.cut();
                toolbarEditor.items[8].disabled = false;
                break;
            case 'Copy':
                diagramInstance.copy();
                toolbarEditor.items[8].disabled = false;
                break;
            case 'Paste':
                diagramInstance.paste();
                break;
            case 'Delete':
                diagramInstance.remove();
                break;
            case 'Select Tool':
                diagramInstance.clearSelection();
                diagramInstance.tool = DiagramTools.Default;
                break;
            case 'Text Tool':
                diagramInstance.clearSelection();
                diagramInstance.selectedItems.userHandles = [];
                diagramInstance.drawingObject = { shape: { type: 'Text' } };
                diagramInstance.tool = DiagramTools.ContinuousDraw;
                break;
            case 'Pan Tool':
                diagramInstance.clearSelection();
                diagramInstance.tool = DiagramTools.ZoomPan;
                break;
            case 'New Diagram':
                diagramInstance.clear();
                historyChange(args);
                break;
            case 'Print Diagram':
                printDiagram(args);
                break;
            case 'Save Diagram':
                download(diagramInstance.saveDiagram());
                window.navigator.clipboard.writeText(diagramInstance.saveDiagram());
                break;
            case 'Open Diagram':
                // console.log('Open Diagram');
                (document.getElementsByClassName('e-file-select-wrap')[0] as any).querySelector('button').click();
                break;
        }
        diagramInstance.dataBind();
    }
    //To change diagram zoom.
    function zoomChange(args: any) {
        const zoomCurrentValue: any = (document.getElementById("btnZoomIncrement") as any).ej2_instances[0];
        let currentZoom: any = diagramInstance.scrollSettings.currentZoom;
        const zoom: any = {};
        switch (args.item.text) {
            case 'Zoom In':
                diagramInstance.zoomTo({ type: 'ZoomIn', zoomFactor: 0.2 });
                zoomCurrentValue.content = ((diagramInstance.scrollSettings.currentZoom as number) * 100).toFixed() + '%';
                break;
            case 'Zoom Out':
                diagramInstance.zoomTo({ type: 'ZoomOut', zoomFactor: 0.2 });
                zoomCurrentValue.content = ((diagramInstance.scrollSettings.currentZoom as number) * 100).toFixed() + '%';
                break;
            case 'Zoom to Fit':
                zoom.zoomFactor = 1 / currentZoom - 1;
                diagramInstance.zoomTo(zoom);
                zoomCurrentValue.content = diagramInstance.scrollSettings.currentZoom;
                break;
            case 'Zoom to 50%':
                if (currentZoom === 0.5) {
                    currentZoom = 0;
                    zoom.zoomFactor = (0.5 / currentZoom) - 1;
                    diagramInstance.zoomTo(zoom);
                }
                else {
                    zoom.zoomFactor = (0.5 / currentZoom) - 1;
                    diagramInstance.zoomTo(zoom);
                }
                break;
            case 'Zoom to 100%':
                if (currentZoom === 1) {
                    currentZoom = 0;
                    zoom.zoomFactor = (1 / currentZoom) - 1;
                    diagramInstance.zoomTo(zoom);
                }
                else {
                    zoom.zoomFactor = (1 / currentZoom) - 1;
                    diagramInstance.zoomTo(zoom);
                }
                break;
            case 'Zoom to 200%':
                if (currentZoom === 2) {
                    currentZoom = 0;
                    zoom.zoomFactor = (2 / currentZoom) - 1;
                    diagramInstance.zoomTo(zoom);
                }
                else {
                    zoom.zoomFactor = (2 / currentZoom) - 1;
                    diagramInstance.zoomTo(zoom);
                }
                break;
        }
        zoomCurrentValue.content = Math.round((diagramInstance.scrollSettings.currentZoom as number) * 100) + ' %';
    }
    let asyncSettings: any;
    asyncSettings = {
        saveUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Save',
        removeUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Remove'
    };
    function onConnectorSelect(args: any) {
        // console.log(args);

        diagramInstance.clearSelection();
        diagramInstance.drawingObject = {
            type: args.item.text,
            style: { strokeWidth: 5, strokeColor: 'red' },
        };
        diagramInstance.tool = DiagramTools.DrawOnce;
        diagramInstance.selectedItems.userHandles = [];
        diagramInstance.dataBind();
    }

    function onShapesSelect(args: any) {
        diagramInstance.clearSelection();
        diagramInstance.drawingObject = { shape: { shape: args.item.text } };
        diagramInstance.tool = DiagramTools.ContinuousDraw;
        diagramInstance.selectedItems.userHandles = [];
        diagramInstance.dataBind();
    }
    //Export the diagraming object based on the format.
    function onselectExport(args: any) {
        const exportOptions: IExportOptions = {};
        exportOptions.format = args.item.text;
        exportOptions.mode = 'Download';
        exportOptions.region = 'PageSettings';
        exportOptions.fileName = 'Export';
        exportOptions.margin = { left: 0, top: 0, bottom: 0, right: 0 };
        diagramInstance.exportDiagram(exportOptions);
    }

    function onSelectGroup(args: any) {
        if (args.item.text === 'Group') {
            diagramInstance.group();
        }
        else if (args.item.text === 'Ungroup') {
            diagramInstance.unGroup();
        }
    }

    function onSelectAlignObjects(args: any) {
        const item: any = args.item.text;
        const alignType = item.replace('Align', '');
        const alignType1 = alignType.charAt(0).toUpperCase() + alignType.slice(1);
        diagramInstance.align(alignType1.trim());
    }
    function onSelectDistributeObjects(args: any) {
        if (args.item.text === 'Distribute Objects Vertically') {
            diagramInstance.distribute('BottomToTop');
        }
        else {
            diagramInstance.distribute('RightToLeft');
        }
    }
    //To execute order commands
    function onSelectOrder(args: any) {
        switch (args.item.text) {
            case 'Bring Forward':
                diagramInstance.moveForward();
                break;
            case 'Bring To Front':
                diagramInstance.bringToFront();
                break;
            case 'Send Backward':
                diagramInstance.sendBackward();
                break;
            case 'Send To Back':
                diagramInstance.sendToBack();
                break;
        }
    }

    function onSelectRotate(args: any) {
        if (args.item.text === 'Rotate Clockwise') {
            diagramInstance.rotate(diagramInstance.selectedItems, 90);
        }
        else {
            diagramInstance.rotate(diagramInstance.selectedItems, -90);
        }
    }
    function onSelectFlip(args: any) {
        flipObjects(args.item.text);
    }

    // To flip diagram objects
    function flipObjects(flipType: any) {
        const selectedObjects = (diagramInstance.selectedItems.nodes as NodeModel[]).concat((diagramInstance.selectedItems as any).connectors);
        for (let i: number = 0; i < selectedObjects.length; i++) {
            selectedObjects[i].flip = flipType === 'Flip Horizontal' ? 'Horizontal' : 'Vertical';
        }
        diagramInstance.dataBind();
    }
    React.useEffect(() => {
        setTimeout(() => {
            const data = localStorage.getItem('data');
            if (data) {
                diagramInstance.loadDiagram(data);
                diagramInstance.rulerSettings = {
                    showRulers: true,
                    dynamicGrid: true,
                }
                diagramInstance.scrollSettings = {
                    zoomFactor: 0.05,
                    currentZoom: 0.7,
                }

                toast.dark('Circuit loaded successfully', {
                    position: "bottom-right",
                })
            }
        }, 1000)
        const interval = setInterval(() => {
            const data = diagramInstance.saveDiagram();
            // toast.info('Circuit saved successfully', {
            //     position: "bottom-right",
            // });
            localStorage.setItem('data', data);
        }, 5000)
        return () => clearInterval(interval);
    }, [])

    function download(data: any) {
        if ((window.navigator as any).msSaveBlob) {
            const blob: Blob = new Blob([data], { type: 'data:text/json;charset=utf-8,' });
            (window.navigator as any).msSaveOrOpenBlob(blob, 'Diagram.json');
        }
        else {
            // console.log(data);
            setCircuitJSON(data);
            // console.log(encodeURIComponent(data));

            const dataString = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
            const ele = document.createElement('a');
            ele.href = dataString;
            ele.download = 'Diagram.json';
            document.body.appendChild(ele);
            ele.click();
            ele.remove();
        }
    }

    function lockObject(args: any) {
        for (let i: number = 0; i < (diagramInstance.selectedItems.nodes as NodeModel[]).length; i++) {
            const node = (diagramInstance.selectedItems.nodes as NodeModel[])[i];
            if (node.constraints && NodeConstraints.Drag) {
                node.constraints = NodeConstraints.PointerEvents | NodeConstraints.Select;
            } else {
                node.constraints = NodeConstraints.Default;
            }
        }
        for (let j: number = 0; j < (diagramInstance.selectedItems.connectors as ConnectorModel[]).length; j++) {
            const connector = (diagramInstance.selectedItems.connectors as ConnectorModel[])[j];
            if (connector.constraints && ConnectorConstraints.Drag) {
                connector.constraints = ConnectorConstraints.PointerEvents | ConnectorConstraints.Select;
            } else {
                connector.constraints = ConnectorConstraints.Default;
            }
        }
        diagramInstance.dataBind();
    }
    function unlockObject(args: any) {
        for (let i: number = 0; i < (diagramInstance.selectedItems.nodes as NodeModel[]).length; i++) {
            const node = (diagramInstance.selectedItems.nodes as NodeModel[])[i];
            node.constraints = NodeConstraints.Default;
        }
        for (let j: number = 0; j < (diagramInstance.selectedItems.connectors as ConnectorModel[]).length; j++) {
            const connector = (diagramInstance.selectedItems.connectors as ConnectorModel[])[j];
            connector.constraints = ConnectorConstraints.Default;

        }
        diagramInstance.dataBind();
    }



    function refreshOverflow() {
        // setTimeout(() => {
        //     // toolbarEditor.refreshOverflow();

        // }, 100);
    }
    function onUploadSuccess(args: any) {
        const file = args.file;
        const rawFile = file.rawFile;
        const reader = new FileReader();
        reader.readAsText(rawFile);

        reader.onloadend = loadDiagram;
    }
    function loadDiagram(event: any) {
        // console.log(event.target.result);

        diagramInstance.loadDiagram(event.target.result);
    }
    function historyChange(args: any) {
        if ((diagramInstance.historyManager.undoStack as HistoryEntry[]).length > 0) {
            toolbarEditor.items[10].disabled = false;
        } else {
            toolbarEditor.items[10].disabled = true;
        }
        if ((diagramInstance.historyManager.redoStack as HistoryEntry[]).length > 0) {
            toolbarEditor.items[11].disabled = false;
        } else {
            toolbarEditor.items[11].disabled = true;
        }
    }
    return (
        <div id="Circuit-Designer" className="control-pane">
            <div className="control-section">
                <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ display: 'none' }}
                        className="e-file-select-wrap"
                    >
                        <UploaderComponent
                            id="fileUpload"
                            type="file"
                            showFileList={true}
                            asyncSettings={asyncSettings}
                            success={onUploadSuccess}
                            onChange={onUploadSuccess}
                            onClick={(e) => {
                                // console.log('clicked');
                                // console.log(e);
                            }}
                        ></UploaderComponent>
                    </div>

                    {/* <div className="sb-mobile-palette-bar">
                        <div id="palette-icon" style={{ float: "right" }} className="e-ddb-icons1 e-toggle-palette"></div>
                    </div> */}

                    <div
                        id="diagram-space" className="sb-mobile-diagram"
                    >
                        <DiagramComponent
                            enableConnectorSplit
                            enableRtl

                            id="diagram"
                            immediateRender={false}
                            addInfo={true}
                            ref={diagram => (diagramInstance = diagram as DiagramComponent)}
                            width={"100%"}
                            style={{
                                maxHeight: "99dvh",
                                height: "100%",
                                minHeight: "99dvh",
                                background: "#F7F7F7",
                            }}
                            snapSettings={{
                                horizontalGridlines: gridlines,
                                verticalGridlines: gridlines,
                            }}

                            rulerSettings={{
                                showRulers: true,
                                dynamicGrid: true,
                                // horizontalRuler: { segmentWidth: 50, orientation: 'Horizontal', interval: 10, },
                                // verticalRuler: {
                                //     segmentWidth: 100, interval: 20, thickness: 20,
                                //     tickAlignment: 'LeftOrTop', markerColor: 'red',
                                //     orientation: 'Vertical',
                                // }
                            }}

                            nodes={nodes}

                            connectors={connectors} //Sets the default values of a node
                            getNodeDefaults={(node: NodeModel) => {




                                if (node.width === undefined) {
                                    node.width = 145;
                                }
                                node.style = { fill: "#00000000", strokeColor: 'white' };
                                for (let i: number = 0; i < (node.annotations as ShapeAnnotationModel[]).length; i++) {
                                    (node.annotations as ShapeAnnotationModel[])[i].style = {
                                        color: 'white',
                                        fill: 'transparent',
                                    };
                                }
                                //Set ports
                                // console.log(node.ports,getPorts());

                                // node.ports = getPorts();

                                return node;
                            }} //Sets the default values of a connector
                            getConnectorDefaults={(obj: Connector) => {
                                // console.log(obj.id);
                                // console.log(isLoaded, lineType);
                                obj.excludeFromLayout = false;
                                if (obj.id.indexOf("connector") !== -1) {
                                    obj.targetDecorator = {
                                        shape: 'Circle',
                                        width: 5,
                                        height: 5,
                                        style: {
                                            strokeWidth: 3,
                                            strokeColor: isLoaded ? lineColor : obj.style.strokeColor,
                                        }
                                    };

                                    obj.sourceDecorator = {
                                        shape: 'Circle',
                                        width: 5,
                                        height: 5,
                                        style: {
                                            strokeWidth: 3,
                                            strokeColor: isLoaded ? lineColor : obj.style.strokeColor,
                                        }
                                    }
                                    obj.style = {
                                        strokeWidth: 5,
                                        strokeColor: isLoaded ? lineColor : obj.style.strokeColor,
                                    }
                                    // obj.type = isLoaded ? lineType : "Orthogonal";
                                    obj.constraints = ConnectorConstraints.Default & ~ConnectorConstraints.LineRouting;
                                    obj.allowNodeOverlap = true;
                                }
                                console.log(obj);
                            }}
                            scrollChange={(args: IScrollChangeEventArgs) => {
                                if (args.panState !== 'Start') {
                                    const zoomCurrentValue: any =
                                        (document.getElementById('btnZoomIncrement') as any)
                                            .ej2_instances[0];
                                    zoomCurrentValue.content =
                                        Math.round(
                                            (diagramInstance.scrollSettings.currentZoom as number) * 100
                                        ) + ' %';
                                }
                            }}
                            historyChange={(args: IHistoryChangeArgs) => {
                                historyChange(args);
                            }}
                            scrollSettings={{
                                zoomFactor: 0.05,
                                currentZoom: 0.7,
                            }}

                            selectionChange={(args: ISelectionChangeEventArgs) => {
                                // console.log(args);

                                if (args.state === 'Changed') {
                                    let selectedItems = (diagramInstance.selectedItems.nodes as NodeModel[]);
                                    selectedItems = selectedItems.concat(
                                        (diagramInstance.selectedItems as any).connectors
                                    );
                                    if (selectedItems.length === 0) {
                                        toolbarEditor.items[6].disabled = true;
                                        toolbarEditor.items[7].disabled = true;
                                        toolbarEditor.items[19].disabled = true;
                                        toolbarEditor.items[20].disabled = true;
                                        toolbarEditor.items[25].disabled = true;
                                        toolbarEditor.items[29].disabled = true;
                                        toolbarEditor.items[31].disabled = true;
                                        disableMultiselectedItems();
                                    }
                                    if (selectedItems.length === 1) {
                                        enableItems();
                                        disableMultiselectedItems();
                                        // console.log(selectedItems[0]);
                                        if (isConnectorColorChangeOn) {
                                            if (selectedItems[0] instanceof Connector) {
                                                // console.log("Connector");
                                                (selectedItems[0] as Connector).style = {
                                                    strokeWidth: 5,
                                                    strokeColor: lineColor,
                                                }
                                            } else {
                                                setIsConnectorColorChangeOn(false)
                                            }
                                        }

                                        if (
                                            selectedItems[0].children !== undefined &&
                                            selectedItems[0].children.length > 0
                                        ) {
                                            toolbarEditor.items[27].disabled = false;
                                        } else {
                                            toolbarEditor.items[27].disabled = true;
                                        }
                                    }

                                    if (selectedItems.length > 1) {
                                        enableItems();
                                        toolbarEditor.items[22].disabled = false;
                                        toolbarEditor.items[23].disabled = false;
                                        toolbarEditor.items[27].disabled = false;
                                        if (selectedItems.length > 2) {
                                            toolbarEditor.items[23].disabled = false;
                                        } else {
                                            toolbarEditor.items[23].disabled = true;
                                        }
                                    }
                                }
                            }}
                            dragEnter={(args: IDragEnterEventArgs): void => {
                                const obj: NodeModel = args.element as NodeModel;
                                // console.log(obj);
                                if (obj instanceof Node) {

                                    obj.width = reduceOrScaleRatio(obj.width, 2.5);
                                    obj.height = reduceOrScaleRatio(obj.height, 2.5);
                                    // obj.offsetX += (obj.width - reduceOrScaleRatio(obj.width, 10)) / 2;
                                    // obj.offsetY += (obj.height - reduceOrScaleRatio(obj.height, 10)) / 2;
                                    obj.style = { fill: "#357BD2", strokeColor: "white" };
                                    obj.constraints = NodeConstraints.Default & ~NodeConstraints.InConnect;
                                    return
                                }
                                const obj2 = args.element as ConnectorModel
                                if (obj2 instanceof Connector) {

                                    obj2.style = { strokeWidth: 5, strokeColor: lineColor };
                                    return
                                }
                            }}

                        >
                            <Inject services={[PrintAndExport, UndoRedo]} />
                        </DiagramComponent>
                    </div>
                    <div id="palette-space" className="sb-mobile-palette" >
                        <SymbolPaletteComponent id="symbolpalette"
                            expandMode="Multiple"
                            enableSearch

                            palettes={[
                                {
                                    id: "flow",
                                    title: "Firmwares",
                                    symbols: firmwares,
                                    expanded: true,
                                },
                                {
                                    id: "breadboard",
                                    title: "Breadboard Components",
                                    symbols: BreadBoardComponents,
                                    expanded: true,
                                },
                                {
                                    id: "connectors",
                                    title: "Input Sensors",
                                    symbols: inputSensors,
                                    expanded: true,

                                },
                                {
                                    id: "logic",
                                    title: "Output Sensors",
                                    symbols: outputSensors,
                                    expanded: true,
                                },
                            ]}
                            width={"100%"}
                            style={{
                                maxHeight: "99vh",
                                height: "100%",
                                // background: "#357BD2",
                            }}
                            // symbolHeight={60}
                            // symbolWidth={60}
                            getNodeDefaults={(symbol: NodeModel): void => {
                                if (symbol) {
                                    symbol.width = reduceOrScaleRatio(symbol.width as number, 0.4);
                                    symbol.height = reduceOrScaleRatio(symbol.height as number, 0.4);
                                }
                                // if (
                                //     symbol.id === "Terminator" ||
                                //     symbol.id === "Process" ||
                                //     symbol.id === "Delay"
                                // ) {
                                //     symbol.width = 80;
                                //     symbol.height = 40;
                                // } else if (
                                //     symbol.id === "Decision" ||
                                //     symbol.id === "Document" ||
                                //     symbol.id === "PreDefinedProcess" ||
                                //     symbol.id === "PaperTap" ||
                                //     symbol.id === "DirectData" ||
                                //     symbol.id === "MultiDocument" ||
                                //     symbol.id === "Data"
                                // ) {
                                //     symbol.width = 50;
                                //     symbol.height = 40;
                                // } else {
                                //     // symbol.width = 50;
                                //     // symbol.height = 40;
                                // }
                                if (symbol.id === "Push-Button") {
                                    symbol.width = reduceOrScaleRatio(symbol.width as number, 3);
                                    symbol.height = reduceOrScaleRatio(symbol.height as number, 3);
                                }
                                (symbol.style as any).strokeColor = '#75757500'
                                if (symbol.ports) {
                                    for (let i: number = 0; i < symbol.ports.length; i++) {
                                        //sets styles for the ports
                                        // symbol.ports[i].width = 10;
                                        // symbol.ports[i].height = 10;
                                        symbol.ports[i].style = {
                                            fill: "#40B942",
                                            strokeColor: "#40B942"
                                        };
                                    }
                                }
                            }}
                            symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }}
                            getSymbolInfo={(symbol: NodeModel): SymbolInfo => {
                                return { fit: true };
                            }} />
                    </div>
                </div>
            </div>
            <div id="CircuitToolBar" className="db-toolbar-container" style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                height: "50px",
                width: "100vw",
                zIndex: 99999,
                display: "flex",
                justifyContent: "center",
            }}>
                <ToolbarComponent
                    ref={(toolbar) => (toolbarEditor = toolbar as ToolbarComponent)}
                    id="toolbar_diagram"
                    created={() => {
                        if (diagramInstance !== undefined) {
                            const conTypeBtn: any = new DropDownButton({
                                items: [
                                    { text: 'Straight', iconCss: 'e-icons e-line' },
                                    { text: 'Orthogonal', iconCss: 'sf-diagram-icon-orthogonal' },
                                    { text: 'Bezier', iconCss: 'sf-diagram-icon-bezier' },
                                    // { text: 'Polyline', iconCss: 'sf-diagram-icon-bezier' },
                                    // { text: 'Freehand', iconCss: 'e-icons e-line' }
                                ], iconCss: 'e-diagram-icons1 e-diagram-connector e-icons',

                                select: function (args) {
                                    // console.log(args);

                                    setLineType(args.item.text)
                                }
                            });
                            conTypeBtn.appendTo('#conTypeBtn');
                            const btnZoomIncrement: any = new DropDownButton({
                                items: [
                                    { text: 'Zoom In' }, { text: 'Zoom Out' }, { text: 'Zoom to Fit' }, { text: 'Zoom to 50%' },
                                    { text: 'Zoom to 100%' }, { text: 'Zoom to 200%' }], content: diagramInstance.scrollSettings && Math.round((diagramInstance.scrollSettings.currentZoom as number) * 100) + ' %', select: zoomChange,
                            });
                            btnZoomIncrement.appendTo('#btnZoomIncrement');
                            const shapesBtn: any = new DropDownButton({
                                items: [
                                    { text: 'Rectangle', iconCss: 'e-rectangle e-icons' },
                                    { text: 'Ellipse', iconCss: ' e-circle e-icons' },
                                    { text: 'Polygon', iconCss: 'e-line e-icons' },
                                ], iconCss: 'e-shapes e-icons',
                                select: function (args) { onShapesSelect(args) }
                            });
                            shapesBtn.appendTo('#shapesBtn');
                            const colorBtn: any = new DropDownButton({
                                items: [
                                    ...eduCOBOTColor.map((color_) => {
                                        return { text: "", iconCss: 'line-color-' + color_.replace("#", ""), id: color_, template: `<h1>hello</h1>` };
                                    })
                                ], iconCss: 'e-highlight-color e-icons',
                                cssClass: "tool-color-container",
                                select: function (args) {
                                    // console.log(args.item.text);
                                    setLineColor(args.item.id);
                                }
                            });
                            colorBtn.appendTo('#colorBtn');

                            const exportBtn: any = new DropDownButton({
                                items: [
                                    { text: 'JPG' }, { text: 'PNG' }, { text: 'SVG' }
                                ], iconCss: 'e-icons e-export', select: function (args) { onselectExport(args) },
                            });
                            exportBtn.appendTo('#diagramexportBtn');
                            const groupBtn: any = new DropDownButton({
                                items: [
                                    { text: 'Group', iconCss: 'e-icons e-group-1' }, { text: 'Ungroup', iconCss: 'e-icons e-ungroup-1' }
                                ], iconCss: 'e-icons e-group-1', select: function (args) { onSelectGroup(args) }
                            })
                            groupBtn.appendTo('#groupBtn');
                            const alignBtn: any = new DropDownButton({
                                items: [
                                    { iconCss: 'sf-diagram-icon-align-left-1', text: 'Align Left', },
                                    { iconCss: 'sf-diagram-icon-align-center-1', text: 'Align Center', },
                                    { iconCss: 'sf-diagram-icon-align-right-1', text: 'Align Ri ght', },
                                    { iconCss: 'sf-diagram-icon-align-top-1', text: 'Align Top', },
                                    { iconCss: 'sf-diagram-icon-align-middle-1', text: 'Align Middle', },
                                    { iconCss: 'sf-diagram-icon-align-bottom-1', text: 'Align Bottom', },
                                ], iconCss: 'e-icons e-restart-at-1', select: function (args) { onSelectAlignObjects(args) }
                            })
                            alignBtn.appendTo('#alignBtn');
                            const distributeBtn: any = new DropDownButton({
                                items: [
                                    { iconCss: 'sf-diagram-icon-distribute-horizontal', text: 'Distribute Objects Vertically', },
                                    { iconCss: 'sf-diagram-icon-distribute-vertical', text: 'Distribute Objects Horizontally', },
                                ], iconCss: 'e-icons e-stroke-width', select: function (args) { onSelectDistributeObjects(args) }
                            });
                            distributeBtn.appendTo('#distributeBtn');
                            const orderBtn: any = new DropDownButton({
                                items: [
                                    { iconCss: 'e-icons e-bring-forward', text: 'Bring Forward' },
                                    { iconCss: 'e-icons e-bring-to-front', text: 'Bring To Front' },
                                    { iconCss: 'e-icons e-send-backward', text: 'Send Backward' },
                                    { iconCss: 'e-icons e-send-to-back', text: 'Send To Back' }
                                ], iconCss: 'e-icons e-order', select: function (args) { onSelectOrder(args) }
                            });
                            orderBtn.appendTo('#orderBtn');
                            const rotateBtn: any = new DropDownButton({
                                items: [
                                    { iconCss: 'e-icons e-transform-right', text: 'Rotate Clockwise' },
                                    { iconCss: 'e-icons e-transform-left', text: 'Rotate Counter-Clockwise' }
                                ], iconCss: 'e-icons e-repeat', select: function (args) { onSelectRotate(args) }
                            });
                            rotateBtn.appendTo('#rotateBtn');
                            const flipBtn: any = new DropDownButton({
                                items: [
                                    { iconCss: 'e-icons e-flip-horizontal', text: 'Flip Horizontal' },
                                    { iconCss: 'e-icons e-flip-vertical', text: 'Flip Vertical' }
                                ], iconCss: 'e-icons e-flip-horizontal', select: function (args) { onSelectFlip(args) }
                            });
                            flipBtn.appendTo('#flipBtn');
                            refreshOverflow();
                        }
                    }}
                    clicked={toolbarClick}
                    items={toolbarItems}
                    overflowMode={'Scrollable'}
                    width={'min(100%,1050px)'}
                >
                </ToolbarComponent>
            </div>
            <ContentDialogBox
                maxWidth="sm"
                title={
                    <h3 style={{
                        color: eduCOBOTColorScheme.orange,
                        textAlign: "center",
                        width: "100%"
                    }}>
                        <img src="https://cdn.pixabay.com/photo/2016/07/01/22/33/industrial-safety-1492046_1280.png" style={{
                            maxWidth: "20px",
                            margin: "0 10px"
                        }} alt="" />
                        Incorrect Connection Detected!</h3>
                }
                isOpen={errorStuff.error}
                onClose={() => setErrorStuff({
                    error: false,
                    message: []
                })}
                content={<>

                    <Button variant='contained' color='warning' sx={{
                        position: "fixed",
                        top: "10px",
                        right: "10px"
                    }} onClick={() => {
                        setErrorStuff({
                            error: false,
                            message: []
                        })
                    }}>
                        <CloseIcon />
                    </Button>
                    {
                        errorStuff.message.map((message, index) => {
                            if (index % 2 === 0) {
                                return <p key={index} style={{
                                    color: "red",
                                    fontWeight: "bold"
                                }}>{message}</p>
                            }
                            return <p key={index}>{message}</p>
                        })
                    }
                </>}

            />
        </div>
    );
}
export default CircuitDesigner;
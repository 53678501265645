import { routes } from "../../../routes/routes"

const FunWithRoboticsLessons = [
    {
        name: "0-9 print on Seven Segment",
        description: " This project is about how to print 0-9 on seven segment using arduino",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/12597/12597970.png",
        link: routes.FUN_WITH_ROBOTICS.LESSONS.ONE_TO_ZERO_PRINT_ON_SEVEN_SEGMENT,
        previewImage: ""
    },
    {
        name: "Wireless LED Control",
        description: " This project is about how to control LED wirelessly using esp32",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/2644/2644589.png",
        link: routes.FUN_WITH_ROBOTICS.LESSONS.WIRELESS_LED_CONTROL,
        previewImage: ""
    },
]

export default FunWithRoboticsLessons
import React from 'react'

type Props = {}

function BreadboardDesignerPage({ }: Props) {
    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '50px'
        }}>
            <iframe allow="camera; microphone" src="/BreadboardSimulator" style={{
                width: '100%',
                minHeight: '100%',
                border: '1px solid gray',
                padding: '10px',
                borderRadius: '10px',
            }}></iframe>
        </div>
    )
}

export default BreadboardDesignerPage
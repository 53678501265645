import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Button, Card } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { LessonDataType } from '../../../../../../types/Lesson';
import audioPlayer from '../../../../../../components/audio-player';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Blockly from '../../../../../../components/micropython-block-editor/configs/micropython-blocks';
import { microPythonResolver } from '../../../../../../components/micropython-block-editor/configs/code-generator';
import { BlocklyWorkspace } from 'react-blockly';
import MicroPythonToolBox from '../../../../../../components/micropython-block-editor/configs/micropython-toolbox';
import { ContentDialogBox } from '../../../../../../components/dialog-box';
import convertXML from 'simple-xml-to-json'
import CloseIcon from '@mui/icons-material/Close';
import MicroPythonSuccessCard from '../../ProgressHandler/MicroPythonSuccessCard';
import { UserContext } from '../../../../../../context/UserContext';
import { updateLessonProgressDetails } from '../../../../../../services/backend/Lessons';



const CodePlayerComponent = ({
    lessonData,
    xml,
    code,
    setXml,
    setCode,
    setRefreshData,
    isVisible,
    closePlayer,
    setLessonData
}: {
    lessonData: LessonDataType,
    xml: string,
    code: string,
    setXml: React.Dispatch<React.SetStateAction<string>>,
    setCode: React.Dispatch<React.SetStateAction<string>>,
    setRefreshData: () => void,
    isVisible: boolean,
    closePlayer: () => void,
    setLessonData: React.Dispatch<React.SetStateAction<LessonDataType | null>>
}) => {
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [currentStatementIndex, setCurrentStatementIndex] = useState(!lessonData.progress.progressReport.MicroPythonProgress.isCompleted ? lessonData.progress.progressReport.MicroPythonProgress.currentStep + 1 || 0 : 0);
    const [isDeducted, setIsDeducted] = useState(lessonData.progress.progressReport.MicroPythonProgress.isDeduction || false);
    const [validCode, setValidCode] = useState('');
    const { user } = useContext(UserContext)
    const [isAnyError, setIsAnyError] = useState({
        isError: false,
        errorMessage: ''
    });
    let [timer, setTimer] = useState<number>(0)
    const [isCompleted, setIsCompleted] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((pre) => {
                console.log("Timer", pre);
                return pre + 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setTimer(0)
    }, [currentStatementIndex])

    const bindLessonCircuitPlayer = useDrag((parameters) => {
        setPosition(() => {
            return {
                x: parameters.offset[0],
                y: parameters.offset[1]
            }
        })
    }, { filterTaps: true, delay: true });

    async function updateLesson(isDeduction: boolean = false) {
        if (!!lessonData) {
            if (!lessonData.progress.progressReport.MicroPythonProgress.isCompleted) {
                let isAlreadyUpdated = lessonData.progress.progressReport.MicroPythonProgress.stepsDetails.some((step) => step.stepNumber === currentStatementIndex + 1)
                if (!isAlreadyUpdated) {
                    console.log("Updating Lesson Progress", currentStatementIndex, lessonData.codeInstructions.content.length);

                    let newProgressReportData = {
                        ...lessonData.progress.progressReport,
                        MicroPythonProgress: {
                            ...lessonData.progress.progressReport.MicroPythonProgress,
                            currentTime: lessonData.progress.progressReport.MicroPythonProgress.currentTime + timer,
                            isCompleted: currentStatementIndex === lessonData.codeInstructions.content.length - 1,
                            coins: currentStatementIndex === lessonData.codeInstructions.content.length - 1 ? isDeduction || isDeducted ? 2 : 3 : 0,
                            microPythonCode: xml,
                            microPythonXML: code,
                            currentStep: currentStatementIndex,
                            isDeduction: isDeduction || isDeducted,
                            stepsDetails: [
                                ...lessonData.progress.progressReport.MicroPythonProgress.stepsDetails,
                                {
                                    stepNumber: currentStatementIndex,
                                    stepTime: timer
                                }
                            ]
                        }
                    }

                    lessonData.progress.progressReport.MicroPythonProgress.stepsDetails.push({
                        stepNumber: currentStatementIndex,
                        stepTime: timer
                    })

                    let newLessonData = {
                        ...lessonData,
                        progress: {
                            ...lessonData.progress,
                            progressReport: newProgressReportData
                        }
                    }
                    setLessonData(newLessonData)
                    const response = await updateLessonProgressDetails(user.userData?.id as string, lessonData.lessonId, newProgressReportData)
                    console.log(response);

                }
            }
        }

    }






    useEffect(() => {
        audioPlayer.stop()
        audioPlayer.play(lessonData.codeInstructions.content[currentStatementIndex].audio, isAudioMuted)
        setXml(lessonData.codeInstructions.content[currentStatementIndex].valid)
        setRefreshData()
    }, [])
    function previousStatement() {
        if (currentStatementIndex > 0) {
            setCurrentStatementIndex(currentStatementIndex - 1)
            audioPlayer.stop()
            audioPlayer.play(lessonData.codeInstructions.content[currentStatementIndex - 1].audio, isAudioMuted)
            setXml(lessonData.codeInstructions.content[currentStatementIndex - 1].valid)
            setRefreshData()
        } else {

        }
    }

    function isCurrentStatementResponseValid() {
        console.log("validCode", validCode);
        console.log("user_Code", code);

        if (validCode != code) {
            console.log("invalid code");
            setIsAnyError({
                isError: true,
                errorMessage: "Invalid Code"
            })
            return false
        }
        return true
    }
    function nextStatement() {

        if (currentStatementIndex < lessonData.codeInstructions.content.length - 1 && isCurrentStatementResponseValid()) {
            setCurrentStatementIndex(currentStatementIndex + 1)
            updateLesson()
            audioPlayer.stop()
            audioPlayer.play(lessonData.codeInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            setXml(lessonData.codeInstructions.content[currentStatementIndex + 1].valid)
            setRefreshData()
        } else {

        }
    }
    function correctTheCode() {
        console.log("currentStatementIndex", currentStatementIndex);

        if (currentStatementIndex < lessonData.codeInstructions.content.length - 1) {
            setCurrentStatementIndex(currentStatementIndex + 1)
            updateLesson(true)
            audioPlayer.stop()
            audioPlayer.play(lessonData.codeInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            setXml(lessonData.codeInstructions.content[currentStatementIndex + 1].valid)
            setRefreshData()
        } else {

        }
    }

    useEffect(() => {
        console.log("validCode", validCode);
        console.log("user_Code", code);

        if (validCode != code) {
            if (currentStatementIndex < lessonData.codeInstructions.content.length - 1) {
                console.log("user", xml);
                console.log("correct", lessonData.codeInstructions.content[currentStatementIndex + 1].valid);
                console.log("correct one ", convertXML.convertXML(lessonData.codeInstructions.content[currentStatementIndex + 1].valid));
                console.log("user", convertXML.convertXML(xml));
            }
        } else {
            if (currentStatementIndex < lessonData.codeInstructions.content.length - 1) {
                setCurrentStatementIndex(currentStatementIndex + 1)
                updateLesson()
                audioPlayer.stop()
                audioPlayer.play(lessonData.codeInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            }
        }
    }, [xml])
    useEffect(() => {
        lessonData.codeInstructions.content.forEach((content, index) => {
            if (content.valid === xml) {
                setCurrentStatementIndex(index)
            }
        })
    }, [xml])
    if (!isVisible)
        return <></>
    return (
        <>
            <div>
                {
                    !lessonData.progress.progressReport.MicroPythonProgress.isCompleted ?
                        <Button color='error' variant='contained' sx={{
                            position: "fixed",
                            top: 50,
                            right: 0,
                            margin: 1,
                            zIndex: 99999
                        }}
                        >
                            {(timer / 60).toFixed(0)} min {timer % 60} sec
                        </Button> : <>

                            <Button color='warning' variant='contained' sx={{
                                position: "fixed",
                                top: 50,
                                right: 0,
                                margin: 1,
                                zIndex: 99999
                            }}
                            >
                                Completed With {(lessonData.progress.progressReport.MicroPythonProgress.currentTime / 60).toFixed(0)} min {lessonData.progress.progressReport.MicroPythonProgress.currentTime % 60} sec
                            </Button>
                        </>

                }
            </div>
            <div style={{
                display: "none"
            }}>
                <BlocklyWorkspace
                    key={currentStatementIndex}
                    toolboxConfiguration={
                        MicroPythonToolBox
                    }
                    initialXml={currentStatementIndex < lessonData.codeInstructions.content.length - 1 ? lessonData.codeInstructions.content[currentStatementIndex + 1].valid : lessonData.codeInstructions.content[lessonData.codeInstructions.content.length - 1].valid}
                    // className="micro-python-editor"
                    workspaceConfiguration={{
                        horizontalLayout: false,
                        trashcan: true,
                        zoom: {
                            controls: true,
                            wheel: true,
                            startScale: 1.0,
                            maxScale: 3,
                            minScale: 0.3,
                            scaleSpeed: 1.2,
                            pinch: true,
                        },
                        move: {
                            scrollbars: {
                                horizontal: true,
                                vertical: true
                            },
                            drag: true, wheel: false
                        },
                        renderer: "zelos",
                    }}
                    onWorkspaceChange={(workspace) => {
                        const valid_code = microPythonResolver.getPythonCode(workspace)
                        setValidCode(valid_code)
                        // console.log(valid_code);
                    }}
                />
            </div>
            <animated.div {...bindLessonCircuitPlayer()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "80vw",
                width: "100%",
                top: "30%",
                left: "50%",
                touchAction: 'none',
                position: "fixed"
            }} >
                <Card sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    cursor: "pointer",
                    maxWidth: "600px",
                    borderRadius: "10px",
                    position: "relative",
                    padding: "10px",
                    zIndex: 99999999999
                }}>

                    {
                        isCompleted ? <>
                            Congratulations, You have successfully completed the Coding Section of the Lesson. You can now proceed to the next section of the lesson.
                        </> : <>

                            <div style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 9999999999
                            }}>
                                <Button size='small' variant='text' onClick={() => {
                                    setIsAudioMuted(!isAudioMuted)
                                }} >

                                    {
                                        isAudioMuted ?
                                            <VolumeOffIcon onClick={() => {
                                                audioPlayer.resume()
                                            }} /> :
                                            <VolumeUpIcon onClick={() => {
                                                audioPlayer.stop()
                                            }} />
                                    }
                                </Button>
                                <Button size='small' variant='text' onClick={() => {
                                    closePlayer()
                                }} >

                                    <CloseIcon />
                                </Button>
                            </div>

                            <h3>
                                {
                                    currentStatementIndex === 0 ? "Task" : currentStatementIndex === lessonData.codeInstructions.content.length - 1 ? "Conclusion" : lessonData.codeInstructions.content[currentStatementIndex].title
                                }
                            </h3>
                            <p>
                                {
                                    lessonData.codeInstructions.content[currentStatementIndex].description
                                }
                            </p>

                        </>
                    }
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        <Button onClick={previousStatement} disabled={currentStatementIndex === 0 || isCompleted} color='warning' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Previous
                        </Button>
                        {
                            currentStatementIndex === lessonData.codeInstructions.content.length - 1 ? <>
                                <Button disabled={isCompleted} onClick={() => {
                                    setIsCompleted(true)
                                    updateLesson()
                                }} color='secondary' variant='contained' sx={{
                                    textTransform: "none",
                                    margin: 1
                                }}>
                                    End
                                </Button>

                            </> : <>
                                <Button onClick={nextStatement} disabled={currentStatementIndex === lessonData.codeInstructions.content.length - 1} color='success' variant='contained' sx={{
                                    textTransform: "none",
                                    margin: 1
                                }}>
                                    Next
                                </Button>

                            </>
                        }
                    </div>
                </Card>
            </animated.div>
            <ContentDialogBox
                isOpen={isAnyError.isError}
                onClose={() => setIsAnyError({
                    ...isAnyError,
                    isError: false
                })}
                title="Code Error"
                maxWidth="xs"
                content={<>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>

                        <img src="https://cdn0.iconfinder.com/data/icons/shift-free/32/Error-512.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <h4>
                            {isAnyError.errorMessage}
                        </h4>

                    </div>
                </>}
                actions={
                    <>
                        <Button variant='contained' color='success' onClick={() => setIsAnyError({
                            isError: false,
                            errorMessage: ''
                        })}
                        >Try Again</Button>
                        <Button variant='contained' color='warning' onClick={() => {
                            correctTheCode()
                            setIsAnyError({
                                isError: false,
                                errorMessage: ''
                            })
                        }}>
                            Correct It
                        </Button>

                    </>
                }
            />
            <MicroPythonSuccessCard lesson={lessonData} isCompleted={isCompleted} />

        </>
    )
}
export default CodePlayerComponent

const statements: string[] = [
    "We nailed it—next task, here we come!",
    "One task down, let’s keep the fun rolling!",
    "Boom! Another task conquered—let’s move forward!",
    "That was a blast—time to tackle the next!",
    "We’re unstoppable—bring on the next task!",
    "Another one bites the dust—what’s next?",
    "Teamwork made this task a masterpiece—onward!",
    "We crushed this one—next task, full speed ahead!",
    "High fives all around—off to the next challenge!",
    "This task was no match for us—let’s keep going!",
    "We’ve got the momentum—on to the next task!",
    "Task complete—time to level up with the next!",
    "Let’s keep the streak alive—next task awaits!",
    "Great work! Let’s channel this energy into the next!",
    "Mission accomplished—ready for the next adventure!",
    "We made it look easy—time to tackle what’s next!",
    "This task? Done! Let’s make magic with the next!",
    "Another win for the team—let’s keep it going!",
    "What a ride—off to the next challenge!",
    "We’ve mastered this—time to take on the next task!",
    "Great teamwork—next task, here we come!",
    "We’re on a roll—let’s keep the momentum going!",
    "This task didn’t stand a chance—onward to the next!",
    "What a fun task! Let’s make the next one even better!",
    "Another task, another success—let’s keep the ball rolling!",
    "That was smooth sailing—let’s set course for the next!",
    "We’re in the zone—next task, let’s go!",
    "This one’s in the bag—time for what’s next!",
    "A job well done—onward to the next task!",
    "We’ve got this! Let’s tackle the next one with the same energy!"
];
const randomIndex = Math.floor(Math.random() * statements.length);
export function getRandomEndTheoryGreetings(): string {
    return statements[randomIndex];
}

import React from 'react'

type Props = {}

function PlantDiseaseDetectorPage({ }: Props) {
    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <iframe allow="camera; microphone;" src="https://plant-detection-app-by-shiv.web.app/" style={{
                width: 'max(100%, 1320px)',
                minHeight: 'min(100%, 100vh)',
                border: '1px solid gray',
                // padding: '10px',
                borderRadius: '10px',
            }}></iframe>
        </div>
    )
}

export default PlantDiseaseDetectorPage
import { Card } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import RockPaperScissor from './RockPaperScissor'
import { SettingContext } from '../../../../../context/SettingsContext'

type Props = {}


const AIImages = {
    stone: "https://storage.googleapis.com/educobot-robotics/images/innovator-assets/robo-stone.png",
    paper: "https://storage.googleapis.com/educobot-robotics/images/innovator-assets/robo-paper.png",
    scissor: "https://storage.googleapis.com/educobot-robotics/images/innovator-assets/robo-scissor.png",
}
const UserImages = {
    stone: "https://storage.googleapis.com/educobot-robotics/images/innovator-assets/Stone.png",
    paper: "https://storage.googleapis.com/educobot-robotics/images/innovator-assets/Paper.png",
    scissor: "https://storage.googleapis.com/educobot-robotics/images/innovator-assets/Scissor.png",
}

function getRandomChoice() {
    const choices = ["stone", "paper", "scissor"]
    const randomIndex = Math.floor(Math.random() * choices.length)
    return choices[randomIndex]

}

function RockPaperScissorPage({ }: Props) {
    const [round, setRound] = React.useState(0)
    const [aiScore, setAiScore] = React.useState(0)
    const [userScore, setUserScore] = React.useState(0)
    const [tie, setTie] = React.useState(0)
    const [currentAIChoice, setCurrentAIChoice] = useState("nothing");
    const [currentUserChoice, setCurrentUserChoice] = useState("nothing")
    const [previousChoice, setPreviousChoice] = useState("nothing")
    const { settings } = useContext(SettingContext)
    function setUserChoice(choice: string) {
        // console.log("Choice", choice);
        setCurrentUserChoice(choice)
        // if (choice == "victory") {
        //     setCurrentUserChoice("victory")
        // }
        // if (choice == "thumbs_up") {
        //     setCurrentUserChoice("stone")
        // }
        // else{
        //     setCurrentUserChoice("paper")
        // }
    }
    useEffect(() => {
        if (currentUserChoice !== previousChoice) {
            console.log("User Choice", currentUserChoice);

            setRound(round + 1)
            setPreviousChoice(currentUserChoice)
            const aiChoice = getRandomChoice()
            setCurrentAIChoice(aiChoice)
            console.log("User Choice", currentUserChoice)
            console.log("AI Choice", aiChoice)
            if (currentUserChoice == "stone" && aiChoice == "stone") {
                setTie(tie + 1)
            }
            if (currentUserChoice == "stone" && aiChoice == "paper") {
                setAiScore(aiScore + 1)
            }
            if (currentUserChoice == "stone" && aiChoice == "scissor") {
                setUserScore(userScore + 1)
            }
            if (currentUserChoice == "paper" && aiChoice == "stone") {
                setUserScore(userScore + 1)
            }
            if (currentUserChoice == "paper" && aiChoice == "paper") {
                setTie(tie + 1)
            }
            if (currentUserChoice == "paper" && aiChoice == "scissor") {
                setAiScore(aiScore + 1)
            }
            if (currentUserChoice == "scissor" && aiChoice == "stone") {
                setAiScore(aiScore + 1)
            }
            if (currentUserChoice == "scissor" && aiChoice == "paper") {
                setUserScore(userScore + 1)
            }
            if (currentUserChoice == "scissor" && aiChoice == "scissor") {
                setTie(tie + 1)
            }
        }
    }, [currentUserChoice])
    if (settings.screen === "mobile") {
        return (
            <div style={{
                padding: '20px',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '5dvh',
                    // gap: '20px',
                }}>
                    <div style={{
                        flexGrow: 1,
                        display: 'flex',
                        border: '1px solid black',
                    }}>

                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            height: '35px',
                            width: '36px',
                            borderTop: '2px solid black',
                            borderLeft: '2px solid black',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: 'rotate(-45deg)',
                            left: '18px',
                        }}>

                        </div>
                        <div style={{
                            height: '50px',
                            width: '150px',
                            borderTop: '2px solid black',
                            borderBottom: '2px solid black',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            Round {round}
                        </div>
                        <div style={{
                            height: '35px',
                            width: '36px',
                            borderTop: '2px solid black',
                            borderRight: '2px solid black',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: 'rotate(45deg)',
                            left: '-18px',
                        }}>
                        </div>
                    </div>
                    <div style={{
                        flexGrow: 1,
                        display: 'flex',
                        border: '1px solid black',
                    }}>

                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',

                }}>
                    <h3>The AI</h3>
                    <h3>You</h3>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    margin: "50px 0"
                }}>

                    <div style={{
                        fontSize: "15px"
                    }}>
                        <span style={{
                            fontSize: "40px"

                        }}>

                            {
                                aiScore
                            }
                        </span> Wins
                    </div>
                    <div style={{
                        height: '75px',
                        width: '75px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '15px',
                        textAlign: 'center',
                        left: '-12px',
                    }}>
                        <div style={{
                            position: 'absolute',
                            height: '75px',
                            width: '75px',
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: 'rotate(45deg)',
                            left: '0px',
                        }}>
                        </div>
                        Tie : {
                            tie
                        }
                    </div>
                    <div style={{
                        fontSize: "15px"
                    }}>

                        <span style={{
                            fontSize: "40px"

                        }}>

                            {
                                userScore
                            }
                        </span> Wins
                    </div>


                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    gap: '10px',
                }}>
                    <div style={{
                        // width: '100%',
                        // maxWidth: "50vw",
                        display: 'flex',
                        justifyContent: 'end',
                        // alignItems: 'center',
                    }}>

                        <Card elevation={5} style={{
                            minWidth: '150px',
                            background: "white",
                            minHeight: '200px',
                            padding: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img src={AIImages[
                                currentAIChoice as "stone" | "paper" | "scissor"
                            ]} style={{
                                maxWidth: "100px"
                            }} alt="" />



                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                left: "-1px",
                                top: "-1px",
                                borderTop: '2px solid black',
                                borderLeft: '2px solid black',
                                borderRadius: "5px 0 0 0 "
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                right: "-1px",
                                top: "-1px",
                                borderTop: '2px solid black',
                                borderRight: '2px solid black',
                                borderRadius: "0 5px 0 0 "
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                left: "-1px",
                                bottom: "-1px",
                                borderBottom: '2px solid black',
                                borderLeft: '2px solid black',
                                borderRadius: "0 0 0 5px "
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                right: "-1px",
                                bottom: "-1px",
                                borderBottom: '2px solid black',
                                borderRight: '2px solid black',
                                borderRadius: "0 0 5px 0 "
                            }}></div>

                        </Card>
                    </div>
                    <div style={{
                        // width: '100%',
                        // maxWidth: "50vw",
                        display: 'flex',
                        justifyContent: 'start',
                        // flexDirection: 'column',
                        // alignItems: 'center',
                    }}>
                        <Card elevation={5} style={{
                            minWidth: '150px',
                            background: "white",
                            minHeight: '200px',
                            padding: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'visible'
                        }}>
                            <img src={UserImages[
                                currentUserChoice as "stone" | "paper" | "scissor"
                            ]} style={{
                                maxWidth: "100px"
                            }} alt="" />
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                left: "-1px",
                                top: "-1px",
                                borderTop: '2px solid black',
                                borderLeft: '2px solid black',
                                borderRadius: "5px 0 0 0 "
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                right: "-1px",
                                top: "-1px",
                                borderTop: '2px solid black',
                                borderRight: '2px solid black',
                                borderRadius: "0 5px 0 0 "
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                left: "-1px",
                                bottom: "-1px",
                                borderBottom: '2px solid black',
                                borderLeft: '2px solid black',
                                borderRadius: "0 0 0 5px "
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                height: '40px',
                                width: '40px',
                                right: "-1px",
                                bottom: "-1px",
                                borderBottom: '2px solid black',
                                borderRight: '2px solid black',
                                borderRadius: "0 0 5px 0 "
                            }}></div>
                        </Card>


                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '80px',
                    margin: "50px 0"
                }}>
                    <h4 style={{
                        textAlign: 'center',
                    }}>{currentAIChoice}</h4>
                    <h4 style={{
                        textAlign: 'center',
                    }}>{currentUserChoice}</h4>
                </div>
                <RockPaperScissor setUserChoice={setUserChoice} />


            </div>
        )
    }
    return (
        <div style={{
            padding: '20px',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '5dvh',
                // gap: '20px',
            }}>
                <div style={{
                    flexGrow: 1,
                    display: 'flex',
                    border: '1px solid black',
                }}>

                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div style={{
                        height: '35px',
                        width: '36px',
                        borderTop: '2px solid black',
                        borderLeft: '2px solid black',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(-45deg)',
                        left: '18px',
                    }}>

                    </div>
                    <div style={{
                        height: '50px',
                        width: '150px',
                        borderTop: '2px solid black',
                        borderBottom: '2px solid black',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        Round {round}
                    </div>
                    <div style={{
                        height: '35px',
                        width: '36px',
                        borderTop: '2px solid black',
                        borderRight: '2px solid black',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(45deg)',
                        left: '-18px',
                    }}>
                    </div>
                </div>
                <div style={{
                    flexGrow: 1,
                    display: 'flex',
                    border: '1px solid black',
                }}>

                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',

            }}>
                <h1>The AI</h1>
                <h1>You</h1>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                margin: "50px 0"
            }}>

                <div style={{
                    fontSize: "30px"
                }}>
                    <span style={{
                        fontSize: "60px"

                    }}>

                        {
                            aiScore
                        }
                    </span> Wins
                </div>
                <div style={{
                    height: '100px',
                    width: '100px',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                    left: '-12px',
                }}>
                    <div style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        border: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(45deg)',
                        left: '0px',
                    }}>
                    </div>
                    Tie : {
                        tie
                    }
                </div>
                <div style={{
                    fontSize: "20px"
                }}>

                    <span style={{
                        fontSize: "60px"

                    }}>

                        {
                            userScore
                        }
                    </span> Wins
                </div>


            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
                gap: '80px',
            }}>
                <div style={{
                    // width: '100%',
                    // maxWidth: "50vw",
                    display: 'flex',
                    justifyContent: 'end',
                    // alignItems: 'center',
                }}>

                    <Card elevation={5} style={{
                        minWidth: '250px',
                        background: "white",
                        minHeight: '200px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={AIImages[
                            currentAIChoice as "stone" | "paper" | "scissor"
                        ]} style={{
                            maxWidth: "200px"
                        }} alt="" />



                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            left: "-1px",
                            top: "-1px",
                            borderTop: '2px solid black',
                            borderLeft: '2px solid black',
                            borderRadius: "5px 0 0 0 "
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            right: "-1px",
                            top: "-1px",
                            borderTop: '2px solid black',
                            borderRight: '2px solid black',
                            borderRadius: "0 5px 0 0 "
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            left: "-1px",
                            bottom: "-1px",
                            borderBottom: '2px solid black',
                            borderLeft: '2px solid black',
                            borderRadius: "0 0 0 5px "
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            right: "-1px",
                            bottom: "-1px",
                            borderBottom: '2px solid black',
                            borderRight: '2px solid black',
                            borderRadius: "0 0 5px 0 "
                        }}></div>

                    </Card>
                </div>
                <div style={{
                    // width: '100%',
                    // maxWidth: "50vw",
                    display: 'flex',
                    justifyContent: 'start',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                }}>

                    <Card elevation={5} style={{
                        minWidth: '250px',
                        background: "white",
                        minHeight: '200px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'visible'
                    }}>
                        <img src={UserImages[
                            currentUserChoice as "stone" | "paper" | "scissor"
                        ]} style={{
                            maxWidth: "150px"
                        }} alt="" />
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            left: "-1px",
                            top: "-1px",
                            borderTop: '2px solid black',
                            borderLeft: '2px solid black',
                            borderRadius: "5px 0 0 0 "
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            right: "-1px",
                            top: "-1px",
                            borderTop: '2px solid black',
                            borderRight: '2px solid black',
                            borderRadius: "0 5px 0 0 "
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            left: "-1px",
                            bottom: "-1px",
                            borderBottom: '2px solid black',
                            borderLeft: '2px solid black',
                            borderRadius: "0 0 0 5px "
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            height: '40px',
                            width: '40px',
                            right: "-1px",
                            bottom: "-1px",
                            borderBottom: '2px solid black',
                            borderRight: '2px solid black',
                            borderRadius: "0 0 5px 0 "
                        }}></div>
                    </Card>


                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '80px',
                margin: "50px 0"
            }}>
                <h4 style={{
                    textAlign: 'center',
                }}>{currentAIChoice}</h4>
                <h4 style={{
                    textAlign: 'center',
                }}>{currentUserChoice}</h4>
            </div>
            <RockPaperScissor setUserChoice={setUserChoice} />


            <img src="https://storage.googleapis.com/educobot-robotics/images/logo/innov8r_Single_Line.png" style={{
                maxWidth: "200px",
                position: 'fixed',
                bottom: '20px',
                left: '20px',
            }} alt="" />

        </div>
    )
}

export default RockPaperScissorPage
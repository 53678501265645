import { Button, Card, Container, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ContentDialogBox } from '../../../../components/dialog-box'
import DeleteIcon from '@mui/icons-material/Delete';
import MonitorDesignerForLessonBuilder from './MonitorDesignerEditorForIOT';
import { WidgetDataType } from '../../../../types/Dashboard';
import AddInstructionComponent from './AddInstructions';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    instructions: string[],
    setInstructions: (instructions: string[]) => void
    monitorJSON: string,
    setMonitorJSON: (xml: string) => void
}

function IOTBuildComponent({
    instructions,
    setInstructions,
    monitorJSON,
    setMonitorJSON
}: Props) {
    const [open, setIsOpen] = React.useState(false)
    const [widgets, setWidgets] = useState<WidgetDataType[]>([])
    const [refreshData, setRefreshData] = useState<boolean>(false)

    function deleteWidget(widgetId: string) {
        setWidgets((prev) => {
            return prev.filter((widget) => widget.widgetId !== widgetId)
        })
    }
    useEffect(() => {
        setMonitorJSON(JSON.stringify(widgets))
    }, [widgets])
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
        }}>

            <Card sx={{
                padding: "5px 20px",
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}>
                <img src="https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20a%20text%20written%20on%20screen%20as%20IOT.png" style={{
                    height: "50px",
                    margin: "0.1rem"
                }} alt="" />
                <h3 style={{
                    // width: "100%",
                    flexGrow: 1,
                    padding: "0 1rem",
                }}>
                    IOT
                </h3>
                <Button style={{
                    borderRadius: "0.4rem",
                }} variant='contained' color='info' onClick={() => {
                    setIsOpen(true)
                }}>
                    Add IOT
                </Button>
            </Card>
            {
                open && <>
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100dvw",
                        height: "100dvh",
                        backgroundColor: "white",
                        zIndex: 9999
                    }}>
                        <div style={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            zIndex: 99999,
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                            padding: "0.5rem",
                            backgroundColor: "white",
                            outline: "1px solid gray",
                            borderRadius: "5px",
                        }}>

                            <Tooltip title="Saved Changes" style={{}}>

                                <Button color='primary' onClick={() => {
                                    // setOpen(false)
                                }}>
                                    <img src="https://cdn-icons-png.flaticon.com/512/6005/6005544.png" style={{
                                        height: "25px"
                                    }} alt="" />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Open Instructions" >
                                <AddInstructionComponent lessonInstructions={instructions} setLessonInstructions={setInstructions} />
                            </Tooltip>
                            <Button variant='contained' color='warning' onClick={() => {
                                setIsOpen(false)
                            }}>
                                <CloseIcon />
                            </Button>
                        </div>
                        <div key={refreshData ? "1" : "2"}>

                            <MonitorDesignerForLessonBuilder
                                deleteWidget={deleteWidget} widgets={widgets} setWidgets={setWidgets} previewMode={false} reRender={() => {
                                    setRefreshData(!refreshData)
                                }} />
                        </div>
                    </div>

                </>
            }
        </div>
    )
}

export default IOTBuildComponent
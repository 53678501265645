import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { BackendConfigs } from '../../../../../../config.environment'
import { APIResponse } from '../../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../../Common/BottomBar'
import { LessonDataType } from '../../../../../../types/Lesson'
import CircuitPlayerComponent from './CircuitPlayer'
import CircuitDesignerComponent from './CircuitDesigner'
import CircuitLessonBuilder from '../../../../../../components/circuit-lesson-builder/CircuitLessonBuilder'








type CircuitryLessonWorkspacePageProps = {}

export default function CircuitryLessonWorkspacePage({ }: CircuitryLessonWorkspacePageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, setLessonData] = useState<LessonDataType | null>(null)
    const [circuitJSON, setCircuitJSON] = useState<string>("{}")
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [isGuideVisible, setIsGuideVisible] = useState(true);

    async function getProject() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            setLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    setLessonData(result.data)
                    if ((result.data as LessonDataType).progress.progressReport.CircuitProgress.createdCircuits !== "") {
                        if ((result.data as LessonDataType).progress.progressReport.CircuitProgress.currentStep === 0) {
                            if ((result.data as LessonDataType).circuitInstructions.content.length > 0){
                                console.log("Circuit JSON", (result.data as LessonDataType).circuitInstructions.content[0].valid);
                                
                                setCircuitJSON((result.data as LessonDataType).circuitInstructions.content[0].valid)
                            }
                        } else {
                            setCircuitJSON((result.data as LessonDataType).progress.progressReport.CircuitProgress.createdCircuits)
                        }
                        setRefreshData(true)
                    }
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()
    }, [lessonId, user])
    useEffect(() => {

    }, [])
    if (!lessonData) {
        return <></>
    }
    return (
        <>
            {
                lessonData && user.userData?.id === "001329.d54007cf2bbc44f5ac86df4e9c28f027.0951" &&
                <CircuitLessonBuilder lessonData={lessonData} circuitJSON={circuitJSON} />
            }

            <div style={{
                overflow: "scroll",
                height: window.innerHeight,
                maxWidth: "100vw",
                overflowX: "hidden"
            }}>
                {
                    lessonData ? <>
                        <div key={refreshData ? "1" : "2"}>
                            <CircuitDesignerComponent lesson={lessonData} circuitJSON={circuitJSON} setCircuitJSON={setCircuitJSON} />
                        </div>
                    </> : <></>
                }
                <CircuitPlayerComponent closePlayer={() => {
                    setIsGuideVisible(false)
                }} isVisible={isGuideVisible} setRefreshData={() => { setRefreshData(!refreshData) }} lessonData={lessonData} setCircuitJSON={setCircuitJSON} circuitJSON={circuitJSON} setLessonData={setLessonData} />
            </div>
            {
                lessonData &&
                <LessonBottomBarComponent toggleHelp={() => { setIsGuideVisible(!isGuideVisible) }} lesson={lessonData} autoFill={() => {
                    setCircuitJSON(lessonData.circuitInstructions.finalCircuit)
                    setRefreshData(!refreshData)
                }} />
            }
        </>

    )
}



import React, { useEffect, useRef } from 'react'
import { ContentDialogBox } from '../../../../../components/dialog-box'
import { LessonDataType } from '../../../../../types/Lesson'
import { Fireworks } from '@fireworks-js/react'
import type { FireworksHandlers } from '@fireworks-js/react'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../../routes/routes'
import { getRandomEndTheoryGreetings } from '../../../../../utils/Greetings'

type Props = {
    lesson: LessonDataType,
    isCompleted: boolean
}

function AssemblySuccessCard({
    lesson,
    isCompleted,
}: Props) {
    const [isOpen, setIsOpen] = React.useState(false)
    const ref = useRef<FireworksHandlers>(null)
    const navigate = useNavigate()
    const toggle = () => {
        if (!ref.current) return
        if (ref.current) {
            ref.current.start()
        }
    }
    useEffect(() => {
        if (isCompleted) {
            setTimeout(() => {
                setIsOpen(true)
            }, 5000)
        }

    }, [isCompleted])
    useEffect(() => {
        if (isCompleted) {
            toggle()
        }
    }, [isCompleted, ref])
    function getNextLessonButton() {
        if (lesson.editors.includes("circuit")) {
            return (<Button
                variant="contained"
                color="warning"
                onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(lesson.lessonId))
                }}
                sx={{
                    flexGrow: 1
                }}
            >
                Go to Circuits
            </Button>)
        }
        if (lesson.editors.includes("code")) {
            return (<Button
                variant="contained"
                color="warning"
                onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(lesson.lessonId))
                }}
                sx={{
                    flexGrow: 1
                }}
            >
                Go to Code
            </Button>)
        }
        if (lesson.editors.includes("iot")) {
            return (<Button
                variant="contained"
                color="warning"
                onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.WORKSPACE.MONITOR(lesson.lessonId))
                }}
                sx={{
                    flexGrow: 1
                }}
            >
                Go to IOT
            </Button>)

        }


        return (
            <Button
                variant="contained"
                color="warning"
                onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.SUBMIT(lesson.lessonId))
                }}
                sx={{
                    flexGrow: 1
                }}
            >
                End Lesson
            </Button>)
    }
    const randomSuccessText = getRandomEndTheoryGreetings()

    return (
        <>
            <ContentDialogBox
                isOpen={isOpen}
                onClose={() => { }}
                maxWidth="xs"
                paperStyle={{
                    minHeight: '60vh',
                }}
                isTransition={true}
                title={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <h5 style={{
                            textAlign: 'center',
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: 600,
                        }}>{lesson.name} : Assembly Completed</h5>
                        <p style={{ textAlign: 'center', fontSize: "10px" }}>You have successfully completed the assembly.</p>
                    </div>
                }
                content={
                    <>
                        <div style={{
                            display: 'flex',
                            minHeight: '50vh',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            flexDirection: 'column',
                            zIndex: 9999999999
                        }}>
                            <img src="https://storage.googleapis.com/educobot-robotics/images/set-1/a%20child%20robot%20successfully%20completing%20an%20assembly%20lesson.png" style={{
                                maxWidth: '100%',
                                width: '90%',
                                borderRadius: '10px',
                            }} alt="" />

                            <Typography>
                                <h4 style={{
                                    textAlign: 'center',
                                    margin: '20px 10px',
                                }}>{randomSuccessText}</h4>
                            </Typography>



                            <Typography style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                width: '100%',
                                gap: '10px',
                            }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
                                    }}
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    color="primary"
                                >
                                    Go to Overview
                                </Button>
                                {getNextLessonButton()}
                            </Typography>
                        </div>

                    </>
                }
            />
            {
                isCompleted &&
                <Fireworks
                    ref={ref}
                    options={{ opacity: 0.5 }}
                    style={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        background: '#00000000'
                    }}
                />
            }
        </>
    )
}

export default AssemblySuccessCard
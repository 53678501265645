import { AuthUserDataType, eduCOBOTUsersType } from '../../types/User'
import Cookies from 'universal-cookie'
import { jwtDecode } from 'jwt-decode';
import { getUserDataAfterVerification } from '../../context/UserContext';


export async function getCookieUserData(): Promise<AuthUserDataType | null> {
    const cookies = new Cookies()
    const token = cookies.get("Token")
    if (token) {
        const tokenValue = jwtDecode(token) as {
            userID: string
            schoolID: string
            name: string
            userType: string
            iat: number
            exp: number
        }
        console.log(tokenValue);
        if (tokenValue.exp && tokenValue.exp < Date.now() / 1000) return null
        else {
            const userData = await getUserDataAfterVerification(tokenValue.userID) as eduCOBOTUsersType | null

            if (userData) {
                const newResponseData: AuthUserDataType = {
                    id: userData.userID,
                    name: userData.euName,
                    contact: {
                        type: "email",
                        value: userData.euEmailID
                    },
                    role: userData.euUserType,
                    token: token,
                    avatar: "",
                    schoolId: userData.euSchoolID
                }
                return newResponseData
            }
            else return null
        }
    }
    else {
        const userId = cookies.get("userID")
        if (userId) {
            const userData = await getUserDataAfterVerification(userId) as eduCOBOTUsersType | null
            if (userData) {
                const newResponseData: AuthUserDataType = {
                    id: userData.userID,
                    name: userData.euName,
                    contact: {
                        type: "email",
                        value: userData.euEmailID
                    },
                    role: userData.euUserType,
                    token: "",
                    avatar: "",
                    schoolId: userData.euSchoolID
                }
                return newResponseData
            }
            else return null
        }
        else return null
    }
}

export function setCookieUserData(userData: AuthUserDataType): void {
    const cookies = new Cookies()
    cookies.set("token", userData.token, { path: "/", expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) })
    cookies.set("userData", JSON.stringify(userData), { path: "/", expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) })
}

export function removeCookieUserData(): void {
    const cookies = new Cookies()
    cookies.remove("token", { path: "/" })
    cookies.remove("userData", { path: "/" })
}
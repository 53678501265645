import React from "react";
import { Card, CardContent, CardHeader, CardMedia, Typography, Button, Chip } from "@mui/material";
import { motion } from "framer-motion";
import { ProjectDataType } from "../../../types/Dashboard";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { isReactNative, sendMessageToReactNative } from "../../../utils/ReactNativeHandler";



interface ProjectCardProps {
    data: ProjectDataType['project'];
    backgroundColor?: string
}

const ProjectCard: React.FC<ProjectCardProps> = ({ data, backgroundColor }) => {
    const navigate = useNavigate()
    return (
        <motion.div whileHover={{ scale: 1.05 }} style={{
            width: "100%" ,
            maxWidth: !isReactNative()?400:undefined,
            margin: isReactNative() ? "10px auto": undefined,
            display: "flex",
            justifyContent: "center",
        }}>
            <Card sx={{
                maxWidth: 400, width: "100%", boxShadow: 5, borderRadius: 3, padding: 2, transition: "all 0.3s ease-in-out",
                background:  backgroundColor,
                color:  "white"   
            }}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={"https://img.freepik.com/premium-photo/cute-robot-cartoon-icon-character-isolated-graphic-design-sign_41691-7293.jpg"}
                        alt={data.name}
                        sx={{ borderRadius: 2, margin: "10px auto", width: "90%" }}
                    />
                </motion.div>
                <CardHeader title={data.name} subheader={data.description} sx={{ textAlign: "center", borderRadius: "8px 8px 0 0" }} />
                <CardContent>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        Public: <strong>{data.isPublic ? "Yes" : "No"}</strong>
                    </Typography>
                    <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: 8, marginTop: 8, color: "white" }}>
                            {data.editors.map(editor => (
                                <Chip key={editor} label={
                                    editor.includes("ai-editor") ? "AI" :
                                        editor.includes("micropython-editor") ? "µPython" :
                                            editor.includes("circuit-designer") ? "Circuit" :
                                                editor.includes("iot-monitor") ? "IOT" : "Unknown"
                                } variant="filled" sx={{
                                    color: "white",
                                }} />
                            ))}
                        </div>
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.1 }}>
                        <Button variant="contained" color={!isReactNative() ? "primary" : "secondary"} sx={{ mt: 3, width: "100%" }} onClick={() => {
                            if(isReactNative()) {
                                sendMessageToReactNative({
                                    project: data
                                },"project")
                                return
                            }
                            navigate(routes.DASHBOARD.PROJECT.DESCRIPTION(data._id))
                        }}>
                            View Project
                        </Button>
                    </motion.div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

export default ProjectCard;

const HardwarePackages: { 
    name: string; 
    image: string; 
    description: string; 
    type: string; 
    backgroundColor: string; 
    price: number;
    amazonLink: string 
}[] = [
    { 
        name: "Grade 1 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 1 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#FFCCCC", 
        price: 3000, 
        amazonLink: "https://www.amazon.com/BB400-Solderless-Plug-BreadBoard-tie-points/dp/B0040Z1ERO/"
    },
    { 
        name: "Grade 2 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 2 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#CCFFCC", 
        price: 18750, 
        amazonLink: "https://www.amazon.com/HiLetgo-ESP32-WROOM-32-Development-Microcontroller-Integrated/dp/B0718T232Z/"
    },
    { 
        name: "Grade 3 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 3 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#CCCCFF", 
        price: 11250, 
        amazonLink: "https://www.amazon.com/ESP32-WROOM-32-Development-ESP-32S-Bluetooth-Arduino/dp/B08D5ZD528/"
    },
    { 
        name: "Grade 4 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 4 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#FFEECC", 
        price: 2000, 
        amazonLink: "https://www.amazon.com/HiLetgo-Infrared-Avoidance-Reflective-Photoelectric/dp/B07W97H2WS/"
    },
    { 
        name: "Grade 5 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 5 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#EECCFF", 
        price: 2250, 
        amazonLink: "https://www.amazon.com/HiLetgo-TTP223B-Capacitive-Digital-Touch/dp/B00HFQEFWU/"
    },
    { 
        name: "Grade 6 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 6 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#CCFFEE", 
        price: 1625, 
        amazonLink: "https://www.amazon.com/HiLetgo-Photosensitive-Resistance-Photoresistor-Photoelectric/dp/B00N1Y4GJW/"
    },
    { 
        name: "Grade 7 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 7 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#FFCCEE", 
        price: 625, 
        amazonLink: "https://www.amazon.com/BOJACK-Momentary-Tactile-Button-Switch/dp/B07ZBHXBK8/"
    },
    { 
        name: "Grade 8 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 8 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#CCEEFF", 
        price: 3375, 
        amazonLink: "https://www.amazon.com/HiLetgo-Temperature-Humidity-Arduino-Raspberry/dp/B01DKC2GQ0/"
    },
    { 
        name: "Grade 9 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 9 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#FFF0CC", 
        price: 4500, 
        amazonLink: "https://www.amazon.com/HiLetgo-Sensor-Module-Arduino-Raspberry/dp/B07L711YNW/"
    },
    { 
        name: "Grade 10 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 10 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#EEDDFF", 
        price: 3000, 
        amazonLink: "https://www.amazon.com/HC-SR04-Ultrasonic-Distance-Measuring-Sensor/dp/B07TKVPPHF/"
    },
    { 
        name: "Grade 11 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 11 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#EEDDFF", 
        price: 3000, 
        amazonLink: "https://www.amazon.com/HC-SR04-Ultrasonic-Distance-Measuring-Sensor/dp/B07TKVPPHF/"
    },
    { 
        name: "Grade 12 Kit By eduCOBOT", 
        image: "https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png", 
        description: "Included in the Grade 12 eduCOBOT curriculum.", 
        type: "Package", 
        backgroundColor: "#EEDDFF", 
        price: 3000, 
        amazonLink: "https://www.amazon.com/HC-SR04-Ultrasonic-Distance-Measuring-Sensor/dp/B07TKVPPHF/"
    },
]


export default HardwarePackages
import React, { useContext } from 'react'
import CourseCard from './CourseCard';
import { Container, Grid } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import BuildIcon from '@mui/icons-material/Build';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { routes } from '../../../routes/routes';
import { useNavigate } from 'react-router-dom';
import { link } from 'fs';
import { SettingContext } from '../../../context/SettingsContext';
import CircuitIcon from '@mui/icons-material/Memory';
import PythonIcon from '@mui/icons-material/Code';
import LogicIcon from '@mui/icons-material/DeveloperBoard';
import BreadboardIcon from '@mui/icons-material/Widgets';
type Props = {}

function MyCourses({ }: Props) {
    const navigate = useNavigate();
    const { settings } = useContext(SettingContext);
    const courses = [
        {
            image: "https://thumbs.dreamstime.com/b/robot-teacher-students-classroom-artificial-intelligence-concept-vector-illustration-cartoon-style-357057289.jpg",
            title: "Robotics, AI and IOT with MicroPython",
            description: "Learn how to build robots, AI models and IOT devices using MicroPython.",
            isFree: false,
            rating: 4.5,
            category: "Course",
            backgroundColor: "#A2B8C9",
            icon: <SportsEsportsIcon />,
            type: "card",
            link: routes.DASHBOARD.LESSONS
        },
        {
            image: "https://img.freepik.com/free-vector/cute-artificial-intelligence-robot-isometric-icon_1284-63045.jpg",
            title: "Fun With AI",
            description: "Explore the fascinating world of Artificial Intelligence and its applications.",
            isFree: true,
            rating: 4,
            category: "Activities",
            backgroundColor: "#FFFFFF",
            icon: <CodeIcon />,
            type: "card",
            link: routes.FUN_WITH_AI.ROOT
        },
        {
            image: "https://img.freepik.com/premium-vector/cute-robot-icon-illustration-techology-robot-icon-concept-isolated-flat-cartoon-style_138676-1213.jpg",
            title: "Fun With Robotics",
            description: "Dive into the exciting field of robotics and learn how robots are built and controlled.",
            isFree: true,
            rating: 4.2,
            category: "Activities",
            backgroundColor: "#0CCBFF",
            icon: <BuildIcon />,
            type: "card",
            link: routes.FUN_WITH_ROBOTICS.ROOT
        },
        {
            image: "https://static.vecteezy.com/system/resources/previews/007/404/139/non_2x/cute-panda-gaming-cartoon-icon-illustration-animal-technology-icon-concept-isolated-premium-flat-cartoon-style-vector.jpg",
            title: "Fun With Gaming",
            description: "Discover the world of game development and design your own video games.",
            isFree: true,
            rating: 4.5,
            category: "Robotics",
            backgroundColor: "#DC9AFE",
            icon: <SportsEsportsIcon />,
            type: "card",
            link: routes.FUN_WITH_GAMING.ROOT
        },
        {
            image: "https://thumbs.dreamstime.com/b/cartoon-robot-cheerful-expression-works-circuit-board-blue-large-eyes-using-small-tool-to-connect-components-359776007.jpg",
            title: "Circuit Designer",
            description: "Design and visualize circuits using a simple drag-and-drop interface.",
            isFree: true,
            rating: 4,
            category: "Tool",
            backgroundColor: "#A9CFA5",
            icon: <CircuitIcon />,
            type: "card",
            link: routes.TOOLS.CIRCUIT_DESIGNER
        },
        {
            image: "https://img.freepik.com/premium-vector/cheerful-cartoon-robot-stands-front-binary-code-display-showcasing-its-customizable-features-blending-technology-creativity_538213-120289.jpg",
            title: "Micropython Editor",
            description: "Design and visualize circuits using a simple drag-and-drop interface.",
            isFree: true,
            rating: 4.2,
            category: "Tool",
            backgroundColor: "#307E88",
            icon: <PythonIcon />,
            type: "card",
            link: routes.TOOLS.MICROPYTHON_EDITOR
        },
        {
            image: "https://pbs.twimg.com/media/GdB_Au_XAAEkcW-.jpg",
            title: "Logic Gate Simulator",
            description: "Design and visualize circuits using a simple drag-and-drop interface.",
            isFree: true,
            rating: 4.5,
            category: "Tool",
            backgroundColor: "#FDCC89",
            icon: <LogicIcon />,
            type: "card",
            link: routes.TOOLS.LOGIC_GATE_SIMULATOR
        },
    ];
    const handleOpenCourse = (link: string) => {
        // Handle the logic to open the course
        navigate(link);
    };
    return (
        <div>
            {/* <Container sx={{ mt: 5 }}> */}
            <Grid spacing={3} style={{ display: "flex", justifyContent: settings.screen === "mobile" ? 'center' : 'flex-start', flexWrap: "wrap", padding: "10px", gap: "20px" }}>
                {courses.map((course, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <CourseCard
                            image={course.image}
                            title={course.title}
                            description={course.description}
                            isFree={course.isFree}
                            onOpen={() => handleOpenCourse(course.link)}
                            rating={course.rating}
                            category={course.category}
                            backgroundColor={course.backgroundColor}
                            icon={course.icon}
                            link={course.link}
                        />
                    </Grid>
                ))}
            </Grid>
            {/* </Container> */}
        </div>
    )
}

export default MyCourses
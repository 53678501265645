//@ts-nocheck
import React, { useRef, useState, useEffect, useContext } from "react";
import * as tf from "@tensorflow/tfjs";
import * as handpose from "@tensorflow-models/handpose";
import Webcam from "react-webcam";
import { drawHand } from "./utilities";

import * as fp from "fingerpose";
import victory from "./victory.png";
import thumbs_up from "./thumbs_up.png";
import { SettingContext } from "../../../../../context/SettingsContext";
import { indexAndMiddleAndRingAndPinkyFinger, indexAndMiddleAndRingFinger, indexAndMiddleFinger, indexFinger, middleFinger, pinkyFinger, ringFinger, thumbFinger } from "./Finger/fingerGesture";
function App({
    setUserChoice
}: {
    setUserChoice: (value: string) => void,
}) {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [emoji, setEmoji] = useState(null);
    const images = { thumbs_up: thumbs_up, victory: victory };
    const ratio = window.innerWidth / window.innerHeight;
    const runHandPose = async () => {
        handleGlobalLoading(true)
        const net = await handpose.load();
        console.log("Handpose model loaded.");
        handleGlobalLoading(false)
        setInterval(() => {
            detect(net);
        }, 100);
    };

    const detect = async (net) => {
        if (
            typeof webcamRef.current !== "undefined" &&
            webcamRef.current !== null &&
            webcamRef.current.video.readyState === 4
        ) {
            const video = webcamRef.current.video;
            const videoWidth = webcamRef.current.video.videoWidth;
            const videoHeight = webcamRef.current.video.videoHeight;
            webcamRef.current.video.width = videoWidth;
            webcamRef.current.video.height = videoHeight;
            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;

            const hand = await net.estimateHands(video);

            if (hand.length > 0) {
                const GE = new fp.GestureEstimator([
                    // fp.Gestures.VictoryGesture,
                    indexFinger,
                    middleFinger,
                    ringFinger,
                    pinkyFinger,
                    thumbFinger,
                    indexAndMiddleFinger,
                    indexAndMiddleAndRingFinger,
                    indexAndMiddleAndRingAndPinkyFinger
                ]);
                const gesture = await GE.estimate(hand[0].landmarks, 4) as {
                    gesture: {
                        "name": string,
                        "score": number
                    }[];
                };
                if (gesture.gestures !== undefined && gesture.gestures.length > 0) {
                    console.log(gesture.gestures);

                    let confidence = gesture.gestures.map((prediction) => prediction.score);
                    let maxConfidence = Math.max(...confidence);
                    let maxIndex = confidence.indexOf(maxConfidence);
                    // console.log(gesture.gestures[maxIndex].name);
                    // if (gesture.gestures[maxIndex].name !== "thumb-finger") {
                        setUserChoice(gesture.gestures[maxIndex].name);
                    // }

                }
            }
            const ctx = canvasRef.current.getContext("2d");
            drawHand(hand, ctx);
        }
    };

    useEffect(() => { runHandPose() }, []);

    return (
        <div style={{
            position: "fixed",
            right: "350px",
            bottom: "250px",
        }}>
            <Webcam
                ref={webcamRef}
                style={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    zIndex: 9,
                    width: 320,
                    height: 240,
                }}
            />
            <canvas
                ref={canvasRef}
                style={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    zIndex: 9,
                    width: 320,
                    height: 240,
                }}
            />
        </div>
    );
}

export default App;

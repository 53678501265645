import React, { useEffect } from "react"
import { ContentDialogBox } from "../dialog-box"
import { Button, Typography } from "@mui/material"
import AnimatedLoadingCard from "../loaders/LeadingCard"
import CloseIcon from '@mui/icons-material/Close'

export function ControllerComponent({
    isOpen,
    onClose,
}: {
    isOpen: boolean,
    onClose: () => void
}) {

    const [isConnected, setIsConnected] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    async function connectToMachine() {
        setIsLoading(true)
        try {
            const response = await fetch("http://192.168.4.1", { method: "GET" })
            if (response.ok) {
                setIsConnected(true)
            } else {
                setIsConnected(false)
            }
        } catch (error) {
            console.log(error);
            setIsConnected(false)
        }

        setIsLoading(false)
    }
    useEffect(() => {
        connectToMachine()
    }, [])
    return (
        <ContentDialogBox
            isOpen={isOpen}
            onClose={onClose}
            title="Controller"
            isTransition={true}
            transitionDirection="right"
            content={
                <>
                    <Typography style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10,
                        rowGap: 50,
                        minHeight: "80vh",
                    }}>
                        <Button onClick={onClose} sx={{ position: "absolute", right: 0, top: 0 }}>
                            <CloseIcon />
                        </Button>
                        {
                            isLoading && !isConnected && <AnimatedLoadingCard message="Connecting" />
                        }
                        <iframe src="http://192.168.4.1" style={{ width: "100%", height: "80vh" }} onError={() => {
                            alert("Error")
                        }}></iframe>
                    </Typography>
                </>
            }
        />
    )
}
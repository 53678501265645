import React from 'react'
import StudentLessons from './StudentLessons'

type Props = {}

function LessonsOTPDashboardPage({ }: Props) {
    return (
        <div>
            <StudentLessons />
        </div>
    )
}

export default LessonsOTPDashboardPage
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardActions, Button, Typography, CardHeader, CardContent, Stack, Chip, CardMedia } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { routes } from "../../../routes/routes";
import { SettingContext } from "../../../context/SettingsContext";
import { UserContext } from "../../../context/UserContext";
import { BackendConfigs } from "../../../config.environment";
import { AlertDialogBox } from "../../../components/dialog-box";
import { ModelType } from "../../../types/Database";
import GetCardColor from "../../../utils/GetCardColor";



type ModelCardProps = {
    data: ModelType;
    reFetch: () => void;
    index: number;
};

const ModelCard: React.FC<ModelCardProps> = ({ data, reFetch, index }) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { settings, handleGlobalLoading } = useContext(SettingContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [deletingModelData, setDeletingModelData] = useState<ModelType | null>(data);

    async function deleteModel(modelId: string) {
        if (user.isLogin && modelId) {
            try {
                const response = await fetch(`${BackendConfigs.url}/DeleteModelAPI`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ userId: user.userData?.id, modelId })
                });
                if (response.status === 200) {
                    toast.success("Model deleted successfully");
                    setDeleteDialogOpen(false);
                    setDeletingModelData(null);
                    reFetch();
                } else {
                    toast.error("Access Denied");
                }
            } catch (error) {
                console.error(error);
                toast.error("Failed to delete model");
            }
        }
    }

    return (
        <>
            <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
            >
                <Card
                    sx={{
                        maxWidth: 300,
                        m: 2,
                        boxShadow: 5,
                        borderRadius: 3,
                        backgroundColor: "#f5f5f5",
                        transition: "0.3s",
                        '&:hover': {
                            boxShadow: 8,
                        },
                        background: GetCardColor(index),
                    }}
                >
                    <DeleteIcon sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        transition: "all 0.3s",
                        cursor: "pointer",
                        zIndex:9999999,
                        "&:hover": { transform: "scale(1.3)" }
                    }} color='error' onClick={() => {
                        setDeleteDialogOpen(true);
                    }} />
                    <CardMedia
                        component="img"
                        height="250"
                        image="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png"
                        alt="Model Image"
                    />
                    <CardHeader
                        title={<Typography variant="h6" fontWeight="bold">{data.name}</Typography>}
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {data.description}
                        </Typography>
                        <Typography variant="subtitle2" mt={1} fontWeight="bold">Labels:</Typography>
                        <Stack direction="row" spacing={1} flexWrap="wrap" mt={1}>
                            {data.labels.map((label, index) => (
                                <Chip key={index} label={label} variant="filled"  />
                            ))}
                        </Stack>
                        <Typography variant="caption" display="block" mt={2} color="text.secondary">
                            Created At: {new Date(data.createdAt).toLocaleDateString()}
                        </Typography>

                    </CardContent>
                    <CardActions sx={{ justifyContent: "center", p: 2 }}>
                        <Button
                        fullWidth
                            // size="large"
                            href={data.fileUrls.modelJson}
                            target="_blank"
                            rel="noopener"
                            variant="contained"
                            color="primary"
                            // sx={{ borderRadius: 2 }}
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </motion.div>

            <AlertDialogBox
                isOpen={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false);
                }}
                title={`Delete Model - ${deletingModelData?.name}?`}
                successAction={
                    <Button
                        size='small'
                        sx={{ textTransform: "none", borderRadius: "6px", fontWeight: "bold", fontSize: "12px" }}
                        variant='contained'
                        color='error'
                        onClick={() => deletingModelData && deleteModel(deletingModelData._id)}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
};

export default ModelCard;
import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import { UserContext } from '../../../context/UserContext';
import { SettingContext } from '../../../context/SettingsContext';
import ProjectListComponent from './ProjectListComponent';
import { isReactNative } from '../../../utils/ReactNativeHandler';

export type ProjectsPageProps = {
}

export default function ProjectsPage(props: ProjectsPageProps) {
    const { user, changeUser } = React.useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = React.useContext(SettingContext)
    const [isDataChanged, setIsDataChanged] = React.useState<any>(false)
    if (isReactNative()) {
        return (
            <ProjectListComponent setIsDataChanged={setIsDataChanged} key={isDataChanged} />
            
        )
    }
    return (
        <div>
            <DashboardLayout title='Projects'>

                <ProjectListComponent setIsDataChanged={setIsDataChanged} key={isDataChanged} />
            </DashboardLayout>
        </div>
    )
}


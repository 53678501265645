import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ConstructionIcon from '@mui/icons-material/Construction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BuildIcon from '@mui/icons-material/Build';
import { Blocks, Container, SettingsIcon } from 'lucide-react';
import { routes } from '../../routes/routes';
import { SmartToy } from '@mui/icons-material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StoreIcon from '@mui/icons-material/Store';
function DashboardNavbarIcon(icon: string, size: string = "20px") {
    switch (icon) {
        case routes.DASHBOARD.MAIN:
            return <DashboardIcon style={{
                fontSize: size,
            }} />

        case routes.DASHBOARD.MY_COURSES:
            return <MenuBookIcon style={{
                fontSize: size,
            }} />
        
        case routes.DASHBOARD.PROJECTS:
            return <DeveloperBoardIcon style={{
                fontSize: size,
            }} />
        
        case routes.DASHBOARD.LESSONS:
            return <LibraryBooksIcon style={{
                fontSize: size,
            }}/>
        case routes.DASHBOARD.HARDWARE_COMPONENTS:
            return <StoreIcon style={{
                fontSize: size,
            }}/>

        case routes.FUN_WITH_AI.ROOT:
            return <SmartToy style={{
                fontSize: size,
            }}/>
        case routes.FUN_WITH_ROBOTICS.ROOT:
            return <PrecisionManufacturingIcon style={{
                fontSize: size,
            }}/>
        case routes.FUN_WITH_GAMING.ROOT:
            return <VideogameAssetIcon style={{
                fontSize: size,
            }}/>
        case routes.DASHBOARD.HOW_IT_WORKS:
            return <AcUnitIcon style={{
                fontSize: size,
            }}/>
        case routes.DASHBOARD.LESSON_BUILDER_LESSON_LIST:
            return <BuildIcon style={{
                fontSize: size,
            }}/>

        case routes.DASHBOARD.TOOLS:
            return <Blocks size={size} />

        case routes.DASHBOARD.SETTINGS:
            return <SettingsIcon style={{
                fontSize: size,
            }} />
        default:
            return <ConstructionIcon style={{
                fontSize: size,
            }} />
    }
}

export default DashboardNavbarIcon

export const DashboardNavConfig = {
    navigation: [
        {
            title: "Dashboard",
            link: routes.DASHBOARD.MAIN,
        },
        {
            title: "My Courses",
            link: routes.DASHBOARD.MY_COURSES
        },
        {
            title: "My Projects",
            link: routes.DASHBOARD.PROJECTS
        },
        // {
        //     title: "Curriculum",
        //     link: routes.DASHBOARD.LESSONS
        // },
        {
            title: "Quick Hacks",
            link: routes.DASHBOARD.HOW_IT_WORKS
        },
        {
            title: "Components",
            link: routes.DASHBOARD.HARDWARE_COMPONENTS
        },
        // {
        //     title: "Fun With Robotics",
        //     link: routes.FUN_WITH_ROBOTICS.ROOT
        // },
        // {
        //     title: "Fun With AI",
        //     link: routes.FUN_WITH_AI.ROOT
        // },
        // {
        //     title: "Fun With Gaming",
        //     link: routes.FUN_WITH_GAMING.ROOT
        // },
        {
            title: "Models",
            link: routes.DASHBOARD.MODELS
        },
        // {
        //     title: "Tools",
        //     link: routes.DASHBOARD.TOOLS
        // },
        {
            title: "Settings",
            link: routes.DASHBOARD.SETTINGS
        },
    ]
}
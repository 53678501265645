import { Button, Card, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import TerminalIcon from '@mui/icons-material/Terminal';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { isReactNative } from '../utils/ReactNativeHandler';
import { SettingContext } from './SettingsContext';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify';
import { MatrixDataResolver } from '../components/CodingHelpers/MatrixResolvers';
import { ControllerComponent } from '../components/CodingHelpers/ControllerComponent';
type Props = {
    children: React.ReactNode
}

export const PythonIDEContextContext = React.createContext({
    toggleTerminal: () => { },
    RestartButton: React.memo(() => <></>),
    TerminalButton: React.memo(() => <></>),
    DeployCodeOnIDEButton: React.memo(({ code }: { code: string }) => <></>),
    PermanentStorageButton: React.memo(({ code }: { code: string }) => <></>),
    ControllerButton: React.memo(() => <></>),
    MatrixCodeResolverButton: React.memo(({ code }: { code: string }) => <></>),
})


function PythonIDEContextContextProvider({ children }: Props) {
    const [isTerminalOpen, setIsTerminalOpen] = React.useState(false)
    const [console_messages, setConsoleMessages] = React.useState<string[]>([
        "started"
    ])
    const [isConnected, setIsConnected] = React.useState(false)
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isMatrixCodeResolverOpen, setIsMatrixCodeResolverOpen] = React.useState(false)
    const { settings, handleGlobalLoading } = React.useContext(SettingContext)
    async function getConsoleMessages() {
        try {
            const response = await fetch("http://localhost:8080/ide/shell/get/messages", { method: "GET" })
            const json = await response.json()
            setConsoleMessages((json.messages.split("\n") as string[]).reverse())
            setIsConnected(true)
        } catch (error) {
            console.log(error);
            if (isConnected) {
                setIsConnected(false)
                setIsTerminalOpen(true)
            }
            setConsoleMessages(["Innovator IDE is not running"])
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            getConsoleMessages()
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    const toggleTerminal = () => {
        setIsTerminalOpen(!isTerminalOpen)
    }
    const restartTerminal = async () => {
        handleGlobalLoading(true)
        try {
            const response = await fetch("http://localhost:8080/ide/shell/restart-backend", { method: "GET" })
            const json = await response.json()
            console.log(json)
            if (json.status.toLowerCase() == "success") {
                toast.info("Terminal restarted successfully")
            } else {
                toast.error("Failed to restart terminal")
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed to restart terminal")
        }
        handleGlobalLoading(false)
    }
    const deployCodeOnIDE = async (code: string) => {
        handleGlobalLoading(true)
        try {
            const response = await fetch("http://localhost:8080/api/v1/esp32/deploy", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    config: {
                        isWifi: false,
                    },
                    data: code,
                    libraries: []
                })
            })
            const json = await response.json() as {
                status: string,
                message: string
            };
            console.log(json);
            if (json.status.toLowerCase() == "success") {
                toast.info("Code uploaded to Innovator IDE successfully");
            } else {
                if (json.message.toLowerCase().includes("enter this address in browser = 192.168")) {
                    toast.info("IP address conflict detected. Please reconfigure the ESP32 to resolve the issue and try again.")
                } else {
                    toast.error(json.message);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed to upload code to Innovator IDE")
        }
        handleGlobalLoading(false)
    }
    const deployCodeInPersistentMode = async (code: string) => {
        handleGlobalLoading(true)
        try {
            const response = await fetch("http://localhost:8080/ide/shell/save-in-persistent-storage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    config: {
                        isWifi: false,
                    },
                    data: code,
                    libraries: []
                })
            })
            const json = await response.json() as {
                status: string,
                message: string
            };
            console.log(json);
            if (json.status.toLowerCase() == "success") {
                toast.info("Code uploaded to Innovator IDE successfully, Press en button on ESP32 to see changes");
            } else {
                toast.error(json.message);
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed to upload code to Innovator IDE")
        }
        handleGlobalLoading(false)
    }
    return (
        <PythonIDEContextContext.Provider value={{
            toggleTerminal, TerminalButton: React.memo(() => <Tooltip title={(isTerminalOpen ? "Close Terminal" : "Open Terminal") + (isConnected ? "" : " (Not Connected)")}><Button endIcon={!isConnected ? <img src='https://cdn-icons-png.flaticon.com/128/1824/1824953.png' style={{
                height: "20px"
                // height: "20px"
            }} /> : <img src='https://cdn-icons-png.flaticon.com/128/1017/1017466.png' style={{
                height: "20px"
                // height: "20px"
            }} />} variant='contained' onClick={toggleTerminal} >
                <TerminalIcon style={{
                    height: "20px"
                }} />
            </Button></Tooltip>),
            RestartButton: React.memo(() => <Tooltip title="Restart Terminal"><Button variant='contained' color='secondary' onClick={restartTerminal} >
                <RestartAltIcon style={{
                    height: "20px"
                }} />
            </Button></Tooltip>
            ),
            DeployCodeOnIDEButton: React.memo(({ code }: { code: string }) => <Tooltip title="Deploy Code"><Button variant='text' onClick={() => {
                console.log(code)
                deployCodeOnIDE(code)
            }} >
                <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                    height: "20px"
                }} alt="" />
            </Button></Tooltip>),
            PermanentStorageButton: React.memo(({ code }: { code: string }) => <Tooltip title="Save Code in Persistent Storage"><Button variant='contained' onClick={() => {
                console.log(code)
                deployCodeInPersistentMode(code)
            }} >
                <img src="https://cdn-icons-png.flaticon.com/512/8297/8297338.png" style={{
                    height: "20px"
                }} alt="" />
            </Button></Tooltip>),
            ControllerButton: React.memo(() => <Tooltip title="Open Controller"><Button variant='contained' onClick={() => setIsControllerOpen(!isControllerOpen)} >
                <img src="https://storage.googleapis.com/innovator-resourses/assets/Game%20Pad.png" style={{
                    height: "20px"
                }} alt="" />
            </Button></Tooltip>),
            MatrixCodeResolverButton: React.memo(({ code }: { code: string }) => code.includes("max7219") ? <Tooltip title="Resolve Matrix Code"><Button variant='contained' onClick={() => setIsMatrixCodeResolverOpen(!isMatrixCodeResolverOpen)} >
                <Grid4x4Icon style={{
                    height: "20px"
                }} />
            </Button>
            </Tooltip> : <></>),
        }}>
            <div>
                {children}
                {
                    !isReactNative() && isTerminalOpen && <>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            position: "fixed",
                            bottom: "0",
                            right: "0",
                            background: "#224FBC",
                            zIndex: 9999999999999,
                        }}>
                            <Button variant='contained' onClick={toggleTerminal} style={{
                                position: "absolute",
                                right: "10px",
                                top: "5px",
                                cursor: "pointer",
                                zIndex: 9999999999999,
                            }}>
                                <CloseIcon onClick={toggleTerminal} />
                            </Button>
                            <h4 style={{ textAlign: "center", width: "100%", background: "#B9B9B9", padding: "10px" }}>
                                Terminal
                            </h4>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center",
                            }}>
                                <Card style={{
                                    width: "100%",
                                    display: "flex", flexDirection: "column",
                                    margin: "auto",
                                    height: "40dvh",
                                    padding: "20px",
                                    overflow: "auto",
                                    background: "#224FBC",
                                }}>
                                    {
                                        console_messages && console_messages.map((message, index) => <p key={index} style={{
                                            color: "#B9B9B9"
                                        }}>&gt; {message}</p>)
                                    }

                                </Card>
                            </div>
                        </div>
                    </>
                }
                {
                    isMatrixCodeResolverOpen && <MatrixDataResolver isOpen={isMatrixCodeResolverOpen} onClose={() => setIsMatrixCodeResolverOpen(false)} />
                }
                {
                    isControllerOpen && <ControllerComponent isOpen={isControllerOpen} onClose={() => setIsControllerOpen(false)} />
                }
            </div>
        </PythonIDEContextContext.Provider>
    )
}

export default PythonIDEContextContextProvider
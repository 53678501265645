import React, { useEffect } from 'react'
import { ChatBotContainer } from '../components/chatbot'
import { useLocation } from 'react-router-dom';
import { routes } from '../routes/routes';
import { isReactNative } from '../utils/ReactNativeHandler';
export default function AIContextProvider(props: {
    children: React.ReactNode
}) {
    const location = useLocation();

    useEffect(() => {
    }, [])
    if (location.pathname.includes("circuit") || location.pathname.includes("fun-with-ai") || location.pathname.includes("tools") || isReactNative()) {
        return <>
            {
                props.children
            }
        </>
    }

    return (
        <div>
            {
                props.children
            }
            <ChatBotContainer />
        </div>
    )
}

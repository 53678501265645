import { routes } from "../../../routes/routes"

const FunWithGamingLessons = [
    {
        name: "Snake Game with Python",
        description: "This project is about how to create a snake game using python programming language",
        thumbnail: "https://cdn4.iconfinder.com/data/icons/miscellaneous-44/24/snake-game-512.png",
        link: routes.FUN_WITH_GAMING.LESSONS.SNAKE_GAME_USING_PYGAME,
        previewImage: ""
    },
]

export default FunWithGamingLessons
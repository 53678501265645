import { routes } from "../../../routes/routes"

const FunWithAITools = [
    {
        name: "Hand Gesture Drawing",
        description: "Discover the art of drawing with AI by recognizing and interpreting hand gestures.",
        thumbnail: "https://educobot.com/imgs/ai-sketch-studio.png",
        link: routes.FUN_WITH_AI.LESSONS.AI_HAND_DRAWER,
        previewImage: ""
    },
    {
        name: "Plant Disease Detector",
        description: "Detect plant diseases using AI and learn about image classification.",
        thumbnail: "https://www.letsnurture.com/wp-content/uploads/2021/02/Plant-disease-classifier-with-ai-blog-banner.jpg",
        link: routes.FUN_WITH_AI.LESSONS.PLANT_DISEASE_DETECTOR,
        previewImage: ""
    },
    {
        name: "Rock Paper Scissors",
        description: "Play Rock-Paper-Scissors with AI and learn about computer vision and game theory.",
        thumbnail: "https://storage.googleapis.com/educobot-robotics/images/website-matches/rock-paper-scissors-game-rules.png",
        link: routes.FUN_WITH_AI.LESSONS.ROCK_PAPER_SCISSOR,
        previewImage: ""
    },
    {
        name: "LEDs on Finger",
        description: "Control LEDs using finger gestures with AI.",
        thumbnail: "https://m.media-amazon.com/images/I/618jfCqUFnL.jpg", 
        link: routes.FUN_WITH_AI.LESSONS.LED_ON_HAND,
        previewImage: ""
    }
]

export default FunWithAITools
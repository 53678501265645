import React, { useState } from 'react';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Chip,
    IconButton,
    Tooltip,
    Box,
    Rating
} from '@mui/material';
import {
    ShoppingCart as ShoppingCartIcon,
    Favorite as FavoriteIcon,
    FavoriteBorder as FavoriteBorderIcon,
    Share as ShareIcon,
    Info as InfoIcon
} from '@mui/icons-material';

// Define the interface for hardware components
interface HardwareComponentProps {
    name: string;
    image: string;
    description: string;
    type: string;
    backgroundColor: string;
    price: number;
    amazonLink: string;
}

const HardwareComponentCard: React.FC<HardwareComponentProps> = ({
    name,
    image,
    description,
    type,
    backgroundColor,
    price,
    amazonLink
}) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const [rating, setRating] = useState<number | null>(4.5);

    return (
        <Card
            sx={{
                maxWidth: 300,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                transition: 'transform 0.3s, box-shadow 0.3s',
                background: backgroundColor || '#f5f5f5',
                '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.4)',
                },
                color: '#000000',
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <CardMedia
                    component="img"
                    height="200"
                    image={image}
                    alt={name}
                    sx={{
                        objectFit: 'contain',
                        padding: 2,
                    }}
                />
                <Chip
                    label={type}
                    size="small"
                    color="primary"
                    variant='filled'
                    sx={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        fontWeight: 'bold',
                        borderRadius: '4px'
                    }}
                />
            </Box>

            <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    {name.replace(/-/g, ' ')}
                </Typography>
{/* 
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Rating
                        value={rating}
                        precision={0.5}
                        size="small"
                        onChange={(_, newValue) => {
                            setRating(newValue);
                        }}
                    />
                    <Typography variant="body2" sx={{ ml: 1, }}>
                        ({Math.floor(Math.random() * 100) + 10} reviews)
                    </Typography>
                </Box> */}

                <Typography variant="body2" sx={{ mb: 2 }}>
                    {description}
                </Typography>

                {/* <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                    Rs {price.toFixed(2)}
                </Typography> */}
            </CardContent>

            <CardActions sx={{ pt: 0, pb: 2, px: 2, justifyContent: 'space-between' }}>
                <Box>
                    {/* <Tooltip title={isFavorite ? "Remove from favorites" : "Add to favorites"}>
                        <IconButton
                            color={isFavorite ? "secondary" : "default"}
                            onClick={() => setIsFavorite(!isFavorite)}
                            size="small"
                        >
                            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                        </IconButton>
                    </Tooltip> */}
                    {/* <Tooltip title="Share">
                        <IconButton size="small">
                            <ShareIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="More info">
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
{/* 
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ShoppingCartIcon />}
                        size="small"
                        onClick={() => window.open(amazonLink, '_blank')}
                        sx={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontWeight: 'bold'
                        }}
                    >
                        Buy Now
                    </Button>
                </Box> */}
            </CardActions>
        </Card>
    );
};

export default HardwareComponentCard;

// Usage example:
// <HardwareComponentCard
//   name="ESP32"
//   image="https://example.com/esp32.jpg"
//   description="ESP32 microcontroller with WiFi and Bluetooth capabilities"
//   type="Microcontroller"
//   backgroundColor="#000033"
//   price={450}
//   amazonLink="https://www.amazon.com/example"
// />
export const HowItWorksResources = [
    {
        title: "eduCOBOT Robotics Component Cheat sheet",
        description: "Learn about the various components used in Robotics and their applications",
        type: "Cheat sheet",
        image: "https://storage.googleapis.com/educobot-robotics/resources/thumbnails/eduCOBOT%20Robotics%20Component%20Cheet%20sheet.png",
        url: "https://storage.googleapis.com/educobot-robotics/resources/eduCOBOT%20Component%20Cheet%20sheet.pdf",
    },
    {
        title: "Robotics Component Cheat sheet",
        description: "Learn about the various components used in Robotics and their applications",
        type: "Cheat sheet",
        image: "https://storage.googleapis.com/educobot-robotics/resources/thumbnails/Robotics%20Component%20Cheet%20sheet.png",
        url: "https://storage.googleapis.com/educobot-robotics/resources/Robotics%20Component%20Cheet%20sheet.pdf",
    },
    {
        title:"Breadboard Component Cheat sheet",
        description:"Learn about the various components used in Breadboard and their applications",
        type:"Cheat sheet",
        image:"https://storage.googleapis.com/educobot-robotics/resources/thumbnails/Breadboard%20Component%20Cheet%20sheet.png",
        url:"https://storage.googleapis.com/educobot-robotics/resources/Breadboard%20Component%20Cheet%20sheet.pdf",
    },
    {
        title: "Google Teachable Machine",
        description: "Learn about Google Teachable Machine and how to train your own machine learning model using it",
        type: "Information",
        image: "https://storage.googleapis.com/educobot-robotics/resources/thumbnails/Google%20Teachable%20Machine.png",
        url: "/InformativeLessons/GoogleTeachableMachine.html",
    },
    // {
    //     title: "ESP32 Information",
    //     description: "Learn about the ESP32 microcontroller and its features, applications, and programming using MicroPython",
    //     type: "Information",
    //     image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg",
    //     url: "/InformativeLessons/Esp32.html",
    // },
    // {
    //     title: "LCD Information",
    //     description: "Learn about the Liquid Crystal Display (LCD) and its applications in Robotics",
    //     type: "Information",
    //     image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LCD.svg",
    //     url: "/InformativeLessons/LCD.html",
    // },
]
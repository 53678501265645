export const LessonCodes:{
    [key: string]: {
        code: string,
        title: string,
        description: string,
        instructions: string[],
        image?: string
    }
}  = {
    "one-to-zero-print-on-seven-segment": {
        code: `from machine import Pin

import time
import machine
from machine import Pin
Pin13 = Pin(13,Pin.OUT) # A
Pin12 = Pin(12,Pin.OUT) # B
Pin14 = Pin(14,Pin.OUT) # C
Pin26 = Pin(26,Pin.OUT) # D
Pin25 = Pin(25,Pin.OUT) # E
Pin33 = Pin(33,Pin.OUT) # F
Pin32 = Pin(32,Pin.OUT) # G
Pin13.value(0) # top
Pin12.value(0) # top right
Pin14.value(0) # bottom right
Pin26.value(0) # bottom
Pin25.value(0) # bottom left
Pin33.value(0) # top left
Pin32.value(0) # center

def display_digit(digit):
    segments = {
        '0': [1, 1, 1, 1, 1, 1, 0],  # 0
        '1': [0, 1, 1, 0, 0, 0, 0],  # 1
        '2': [1, 1, 0, 1, 1, 0, 1],  # 2
        '3': [1, 1, 1, 1, 0, 0, 1],  # 3
        '4': [0, 1, 1, 0, 0, 1, 1],  # 4
        '5': [1, 0, 1, 1, 0, 1, 1],  # 5
        '6': [1, 0, 1, 1, 1, 1, 1],  # 6
        '7': [1, 1, 1, 0, 0, 0, 0],  # 7
        '8': [1, 1, 1, 1, 1, 1, 1],  # 8
        '9': [1, 1, 1, 1, 0, 1, 1],  # 9
    }

    # Get the pin configuration for the given digit
    if str(digit) in segments:
        pin_states = segments[str(digit)]
        # Assign values to each pin
        Pin13.value(pin_states[0])  # top
        Pin12.value(pin_states[1])  # top right
        Pin14.value(pin_states[2])  # bottom right
        Pin26.value(pin_states[3])  # bottom
        Pin25.value(pin_states[4])  # bottom left
        Pin33.value(pin_states[5])  # top left
        Pin32.value(pin_states[6])  # center
    else:
        print("Invalid digit:", digit)


while True:
    for num in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]:
        display_digit(num)
        time.sleep(0.5)`,
        title: "Print 0-9 on Seven Segment",
        description: "This project is about how to print 0-9 on seven segment using esp32",
        instructions: [
            "Open Innovator IDE",
            "Select Micropython board esp32 from bottom right options",
            "Hit Green Flag button Here to upload the code",
            "Once code uploaded to innovator IDE, go to innovator ide and run it from there",
            "Identify the pins from code and Connect correct circuit as per the code",
            "Enjoy the project",
        ]
    },
    "snake-game-using-pygame": {
        code: `"""
Snake Eater
Made with PyGame
"""

import pygame, sys, time, random


# Difficulty settings
# Easy      ->  10
# Medium    ->  25
# Hard      ->  40
# Harder    ->  60
# Impossible->  120
difficulty = 25

# Window size
frame_size_x = 720
frame_size_y = 480

# Checks for errors encountered
check_errors = pygame.init()
# pygame.init() example output -> (6, 0)
# second number in tuple gives number of errors
if check_errors[1] > 0:
    print(f'[!] Had {check_errors[1]} errors when initialising game, exiting...')
    sys.exit(-1)
else:
    print('[+] Game successfully initialised')


# Initialise game window
pygame.display.set_caption('Snake Eater')
game_window = pygame.display.set_mode((frame_size_x, frame_size_y))


# Colors (R, G, B)
black = pygame.Color(0, 0, 0)
white = pygame.Color(255, 255, 255)
red = pygame.Color(255, 0, 0)
green = pygame.Color(0, 255, 0)
blue = pygame.Color(0, 0, 255)


# FPS (frames per second) controller
fps_controller = pygame.time.Clock()


# Game variables
snake_pos = [100, 50]
snake_body = [[100, 50], [100-10, 50], [100-(2*10), 50]]

food_pos = [random.randrange(1, (frame_size_x//10)) * 10, random.randrange(1, (frame_size_y//10)) * 10]
food_spawn = True

direction = 'RIGHT'
change_to = direction

score = 0


# Game Over
def game_over():
    my_font = pygame.font.SysFont('times new roman', 90)
    game_over_surface = my_font.render('YOU DIED', True, red)
    game_over_rect = game_over_surface.get_rect()
    game_over_rect.midtop = (frame_size_x/2, frame_size_y/4)
    game_window.fill(black)
    game_window.blit(game_over_surface, game_over_rect)
    show_score(0, red, 'times', 20)
    pygame.display.flip()
    time.sleep(3)
    pygame.quit()
    sys.exit()


# Score
def show_score(choice, color, font, size):
    score_font = pygame.font.SysFont(font, size)
    score_surface = score_font.render('Score : ' + str(score), True, color)
    score_rect = score_surface.get_rect()
    if choice == 1:
        score_rect.midtop = (frame_size_x/10, 15)
    else:
        score_rect.midtop = (frame_size_x/2, frame_size_y/1.25)
    game_window.blit(score_surface, score_rect)
    # pygame.display.flip()


# Main logic
while True:
    for event in pygame.event.get():
        if event.type == pygame.QUIT:
            pygame.quit()
            sys.exit()
        # Whenever a key is pressed down
        elif event.type == pygame.KEYDOWN:
            # W -> Up; S -> Down; A -> Left; D -> Right
            if event.key == pygame.K_UP or event.key == ord('w'):
                change_to = 'UP'
            if event.key == pygame.K_DOWN or event.key == ord('s'):
                change_to = 'DOWN'
            if event.key == pygame.K_LEFT or event.key == ord('a'):
                change_to = 'LEFT'
            if event.key == pygame.K_RIGHT or event.key == ord('d'):
                change_to = 'RIGHT'
            # Esc -> Create event to quit the game
            if event.key == pygame.K_ESCAPE:
                pygame.event.post(pygame.event.Event(pygame.QUIT))

    # Making sure the snake cannot move in the opposite direction instantaneously
    if change_to == 'UP' and direction != 'DOWN':
        direction = 'UP'
    if change_to == 'DOWN' and direction != 'UP':
        direction = 'DOWN'
    if change_to == 'LEFT' and direction != 'RIGHT':
        direction = 'LEFT'
    if change_to == 'RIGHT' and direction != 'LEFT':
        direction = 'RIGHT'

    # Moving the snake
    if direction == 'UP':
        snake_pos[1] -= 10
    if direction == 'DOWN':
        snake_pos[1] += 10
    if direction == 'LEFT':
        snake_pos[0] -= 10
    if direction == 'RIGHT':
        snake_pos[0] += 10

    # Snake body growing mechanism
    snake_body.insert(0, list(snake_pos))
    if snake_pos[0] == food_pos[0] and snake_pos[1] == food_pos[1]:
        score += 1
        food_spawn = False
    else:
        snake_body.pop()

    # Spawning food on the screen
    if not food_spawn:
        food_pos = [random.randrange(1, (frame_size_x//10)) * 10, random.randrange(1, (frame_size_y//10)) * 10]
    food_spawn = True

    # GFX
    game_window.fill(black)
    for pos in snake_body:
        # Snake body
        # .draw.rect(play_surface, color, xy-coordinate)
        # xy-coordinate -> .Rect(x, y, size_x, size_y)
        pygame.draw.rect(game_window, green, pygame.Rect(pos[0], pos[1], 10, 10))

    # Snake food
    pygame.draw.rect(game_window, white, pygame.Rect(food_pos[0], food_pos[1], 10, 10))

    # Game Over conditions
    # Getting out of bounds
    if snake_pos[0] < 0 or snake_pos[0] > frame_size_x-10:
        game_over()
    if snake_pos[1] < 0 or snake_pos[1] > frame_size_y-10:
        game_over()
    # Touching the snake body
    for block in snake_body[1:]:
        if snake_pos[0] == block[0] and snake_pos[1] == block[1]:
            game_over()

    show_score(1, white, 'consolas', 20)
    # Refresh game screen
    pygame.display.update()
    # Refresh rate
    fps_controller.tick(difficulty)`,
        title: "Snake Game Using PyGame",
        description: "This project is about how to make a snake game using pygame",
        instructions: [
            "Open Innovator IDE",
            "Select Local python 3 from bottom right options",
            "Hit Green Flag button Here to upload the code",
            "Once code uploaded to innovator IDE, go to innovator ide and run it properly from there",
            "Enjoy the game",
        ]
    },
    "wireless-led-control": {
        code: `try:
  import usocket as socket
except:
  import socket
import network
import machine
import esp
import gc

esp.osdebug(None)
gc.collect()
ssid = 'ESP32-Access-Point-Neha'
password = '12345678'

ap_if = network.WLAN(network.AP_IF)
ap_if.active(True)
ap_if.config(essid=ssid, password=password)

while ap_if.active() == False:
  pass

print('Connection successful')
print(ap_if.ifconfig())


def web_page():
    html = """<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>LED Status</title>
    <style>
        body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
            margin: 0;
            background: linear-gradient(to bottom, #e0f7fa, #80deea);
            font-family: 'Arial', sans-serif;
            color: #004d40;
        }

        h1 {
            font-size: 2.5rem;
            margin-bottom: 20px;
        }

        .led {
            width: 150px;
            height: 150px;
            margin: 20px;
        }

        button {
            padding: 12px 24px;
            font-size: 18px;
            color: #fff;
            background-color: #00796b;
            border: none;
            border-radius: 8px;
            margin: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease;
        }

        button:hover {
            background-color: #004d40;
            transform: scale(1.1);
        }

        button:active {
            transform: scale(0.95);
        }
    </style>
</head>

<body>
    <h1>Control LED Status</h1>
    <div style="display: flex; justify-content: center; align-items: center; gap: 20px;">
        <button onclick="turnLedOn()">LED ON</button>
        <button onclick="turnLedOff()">LED OFF</button>
    </div>
    <svg class="led" id="ledSvg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="40" fill="#ccc" stroke="#888" stroke-width="5" />
    </svg>

    <script>
        async function turnLedOn() {
            const led = document.getElementById('ledSvg').querySelector('circle');
            led.setAttribute('fill', '#0000FF'); // Green color for "ON"
            try {
                const response = fetch("/led_on")
                alert(JSON.stringify(response));
            } catch (error) {
                console.log(error);
                alert(JSON.stringify(error));
            }
        }
        async function turnLedOff() {
            const led = document.getElementById('ledSvg').querySelector('circle');
            led.setAttribute('fill', '#888888'); // Green color for "ON"
            try {
                fetch("/led_off")
                alert(JSON.stringify(response));
            } catch (error) {
                console.log(error);
                alert(JSON.stringify(error));
            }
        }
    </script>
</body>

</html>"""
    return html

s = socket.socket(socket.AF_INET, socket.SOCK_STREAM)
s.bind(('', 80))
s.listen(5)

led_Pin = machine.Pin(2, machine.Pin.OUT)
led_Pin.value(0)

print("OPEN->   http://"+ap_if.ifconfig()[0],"  to get the link to control LED")

while True:
    conn, addr = s.accept()
    print('Got a connection from %s' % str(addr))
    request = conn.recv(1024)
    print(request)
    if(str(request).find('/led_on') == 6):
        led_Pin.value(1)
        print('LED ON')
    if(str(request).find('/led_off') == 6):
        led_Pin.value(0)
        print('LED OFF')
    print('Content = %s' % str(request))
    response = web_page()
    conn.send(response)
    conn.close()
`,
        title: "Wireless LED Control",
        description: "This project is about how to control LED wirelessly using esp32",
        instructions: [
            "Open Innovator IDE",
            "Select Micropython board esp32 from bottom right options",
            "Hit Green Flag button Here to upload the code",
            "Once code uploaded to innovator IDE, go to innovator ide and run it from there",
            "Identify the pins from code and Connect correct circuit as per the code",
            "Enjoy the project",
        ]
    }
}


export default function GetLessonCode(lesson_slug: string) {
    if (LessonCodes[lesson_slug] === undefined) {
        return {
            code: "",
            title: "",
            description: "",
            instructions: [],
        }
    }
    return LessonCodes[lesson_slug]
}
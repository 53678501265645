import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import { Button, Card } from '@mui/material';
import { eduCOBOTColorScheme } from '../../../context/SettingsContext';
import { ContentDialogBox } from '../../../components/dialog-box';
import { useNavigate } from 'react-router-dom';
import ToolsData from '../../../utils/ToolsData';

export type ToolsPageProps = {
}








export default function ToolsPage(props: ToolsPageProps) {
    return (
        <div>
            <DashboardLayout title='Tools'>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start",
                    padding: "10px",
                }}>
                    {
                        ToolsData.map((tool, index) => {
                            return <ToolsCard tool={tool} key={index} />
                        })
                    }
                </div>
            </DashboardLayout>
        </div>
    );
}



export function ToolsCard({
    tool
}: {
    tool: typeof ToolsData[0]
}) {
    const [isPreview, setIsPreview] = React.useState(false)
    const navigate = useNavigate()
    return (<>
        <Card sx={{
            width: "250px",
            minHeight: "250px",
            margin: 2,
            borderRadius: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            flexDirection: "column",
            // backgroundColor: eduCOBOTColorScheme.white,
            padding: "10px"
        }}
            elevation={5}
        >
            <img src={tool.thumbnail} style={{
                maxWidth: "70%",
                margin: 5
            }} alt="" />
            <h4 style={{
                textAlign: "center",
            }}>
                {
                    tool.name
                }
            </h4>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px",
                margin: "10px 0",
            }}>
                
                <Button fullWidth color='secondary' size='small' variant='contained' style={{
                    textTransform: "none",
                }} onClick={() => {
                    navigate(tool.link)
                }}>Open</Button>
            </div>
        </Card>
        <ContentDialogBox
            isOpen={isPreview}
            onClose={() => setIsPreview(false)}
            title={tool.name}
        />
    </>)
}
import { PointPortModel, PortConstraints, PortVisibility } from "@syncfusion/ej2-react-diagrams";

export interface CustomPort extends PointPortModel {
    text: string;
}


export const breadBoardPorts: CustomPort[] = [
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.9255, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port2", shape: "Circle", offset: { x: 0.8956, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port3", shape: "Circle", offset: { x: 0.8657, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port4", shape: "Circle", offset: { x: 0.8358, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default & PortConstraints.Draw,
    },
    {
        id: "breadboard-port5", shape: "Circle", offset: { x: 0.8059, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port6", shape: "Circle", offset: { x: 0.7760, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port7", shape: "Circle", offset: { x: 0.7461, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port8", shape: "Circle", offset: { x: 0.7162, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port9", shape: "Circle", offset: { x: 0.6863, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port10", shape: "Circle", offset: { x: 0.6564, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port11", shape: "Circle", offset: { x: 0.6265, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port12", shape: "Circle", offset: { x: 0.5966, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port13", shape: "Circle", offset: { x: 0.5667, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port14", shape: "Circle", offset: { x: 0.5368, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port15", shape: "Circle", offset: { x: 0.5069, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port16", shape: "Circle", offset: { x: 0.4770, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port17", shape: "Circle", offset: { x: 0.4471, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port18", shape: "Circle", offset: { x: 0.4172, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port19", shape: "Circle", offset: { x: 0.3873, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port20", shape: "Circle", offset: { x: 0.3574, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port21", shape: "Circle", offset: { x: 0.3275, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port22", shape: "Circle", offset: { x: 0.2976, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port23", shape: "Circle", offset: { x: 0.2677, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port24", shape: "Circle", offset: { x: 0.2378, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port25", shape: "Circle", offset: { x: 0.2079, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port26", shape: "Circle", offset: { x: 0.1780, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port27", shape: "Circle", offset: { x: 0.1471, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port28", shape: "Circle", offset: { x: 0.1172, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port29", shape: "Circle", offset: { x: 0.0873, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port30", shape: "Circle", offset: { x: 0.0574, y: 0.2460 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port31", shape: "Circle", offset: { x: 0.9255, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port32", shape: "Circle", offset: { x: 0.8956, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port33", shape: "Circle", offset: { x: 0.8657, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port34", shape: "Circle", offset: { x: 0.8358, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port35", shape: "Circle", offset: { x: 0.8059, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port36", shape: "Circle", offset: { x: 0.7760, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port37", shape: "Circle", offset: { x: 0.7461, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port38", shape: "Circle", offset: { x: 0.7162, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port39", shape: "Circle", offset: { x: 0.6863, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port40", shape: "Circle", offset: { x: 0.6564, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port41", shape: "Circle", offset: { x: 0.6265, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port42", shape: "Circle", offset: { x: 0.5966, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port43", shape: "Circle", offset: { x: 0.5667, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port44", shape: "Circle", offset: { x: 0.5368, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port45", shape: "Circle", offset: { x: 0.5069, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port46", shape: "Circle", offset: { x: 0.4770, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port47", shape: "Circle", offset: { x: 0.4471, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port48", shape: "Circle", offset: { x: 0.4172, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port49", shape: "Circle", offset: { x: 0.3873, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port50", shape: "Circle", offset: { x: 0.3574, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port51", shape: "Circle", offset: { x: 0.3275, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port52", shape: "Circle", offset: { x: 0.2976, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port53", shape: "Circle", offset: { x: 0.2677, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port54", shape: "Circle", offset: { x: 0.2378, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port55", shape: "Circle", offset: { x: 0.2079, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port56", shape: "Circle", offset: { x: 0.1780, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port57", shape: "Circle", offset: { x: 0.1471, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port58", shape: "Circle", offset: { x: 0.1172, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port59", shape: "Circle", offset: { x: 0.0873, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port60", shape: "Circle", offset: { x: 0.0574, y: 0.2925 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port61", shape: "Circle", offset: { x: 0.9255, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port62", shape: "Circle", offset: { x: 0.8956, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port63", shape: "Circle", offset: { x: 0.8657, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port64", shape: "Circle", offset: { x: 0.8358, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port65", shape: "Circle", offset: { x: 0.8059, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port66", shape: "Circle", offset: { x: 0.7760, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port67", shape: "Circle", offset: { x: 0.7461, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port68", shape: "Circle", offset: { x: 0.7162, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port69", shape: "Circle", offset: { x: 0.6863, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port70", shape: "Circle", offset: { x: 0.6564, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port71", shape: "Circle", offset: { x: 0.6265, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port72", shape: "Circle", offset: { x: 0.5966, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port73", shape: "Circle", offset: { x: 0.5667, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port74", shape: "Circle", offset: { x: 0.5368, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port75", shape: "Circle", offset: { x: 0.5069, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port76", shape: "Circle", offset: { x: 0.4770, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port77", shape: "Circle", offset: { x: 0.4471, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port78", shape: "Circle", offset: { x: 0.4172, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port79", shape: "Circle", offset: { x: 0.3873, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port80", shape: "Circle", offset: { x: 0.3574, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port81", shape: "Circle", offset: { x: 0.3275, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port82", shape: "Circle", offset: { x: 0.2976, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port83", shape: "Circle", offset: { x: 0.2677, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port84", shape: "Circle", offset: { x: 0.2378, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port85", shape: "Circle", offset: { x: 0.2079, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port86", shape: "Circle", offset: { x: 0.1780, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port87", shape: "Circle", offset: { x: 0.1471, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port88", shape: "Circle", offset: { x: 0.1172, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port89", shape: "Circle", offset: { x: 0.0873, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port90", shape: "Circle", offset: { x: 0.0574, y: 0.3390 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port91", shape: "Circle", offset: { x: 0.9255, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port92", shape: "Circle", offset: { x: 0.8956, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port93", shape: "Circle", offset: { x: 0.8657, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port94", shape: "Circle", offset: { x: 0.8358, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port95", shape: "Circle", offset: { x: 0.8059, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port96", shape: "Circle", offset: { x: 0.7760, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port97", shape: "Circle", offset: { x: 0.7461, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port98", shape: "Circle", offset: { x: 0.7162, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port99", shape: "Circle", offset: { x: 0.6863, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port100", shape: "Circle", offset: { x: 0.6564, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port101", shape: "Circle", offset: { x: 0.6265, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port102", shape: "Circle", offset: { x: 0.5966, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port103", shape: "Circle", offset: { x: 0.5667, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port104", shape: "Circle", offset: { x: 0.5368, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port105", shape: "Circle", offset: { x: 0.5069, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port106", shape: "Circle", offset: { x: 0.4770, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port107", shape: "Circle", offset: { x: 0.4471, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port108", shape: "Circle", offset: { x: 0.4172, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port109", shape: "Circle", offset: { x: 0.3873, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port110", shape: "Circle", offset: { x: 0.3574, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port111", shape: "Circle", offset: { x: 0.3275, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port112", shape: "Circle", offset: { x: 0.2976, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port113", shape: "Circle", offset: { x: 0.2677, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port114", shape: "Circle", offset: { x: 0.2378, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port115", shape: "Circle", offset: { x: 0.2079, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port116", shape: "Circle", offset: { x: 0.1780, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port117", shape: "Circle", offset: { x: 0.1471, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port118", shape: "Circle", offset: { x: 0.1172, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port119", shape: "Circle", offset: { x: 0.0873, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port120", shape: "Circle", offset: { x: 0.0574, y: 0.3855 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port121", shape: "Circle", offset: { x: 0.9255, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port122", shape: "Circle", offset: { x: 0.8956, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port123", shape: "Circle", offset: { x: 0.8657, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port124", shape: "Circle", offset: { x: 0.8358, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port125", shape: "Circle", offset: { x: 0.8059, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port126", shape: "Circle", offset: { x: 0.7760, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port127", shape: "Circle", offset: { x: 0.7461, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port128", shape: "Circle", offset: { x: 0.7162, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port129", shape: "Circle", offset: { x: 0.6863, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port130", shape: "Circle", offset: { x: 0.6564, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port131", shape: "Circle", offset: { x: 0.6265, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port132", shape: "Circle", offset: { x: 0.5966, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port133", shape: "Circle", offset: { x: 0.5667, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port134", shape: "Circle", offset: { x: 0.5368, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port135", shape: "Circle", offset: { x: 0.5069, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port136", shape: "Circle", offset: { x: 0.4770, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port137", shape: "Circle", offset: { x: 0.4471, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port138", shape: "Circle", offset: { x: 0.4172, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port139", shape: "Circle", offset: { x: 0.3873, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port140", shape: "Circle", offset: { x: 0.3574, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port141", shape: "Circle", offset: { x: 0.3275, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port142", shape: "Circle", offset: { x: 0.2976, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port143", shape: "Circle", offset: { x: 0.2677, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port144", shape: "Circle", offset: { x: 0.2378, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port145", shape: "Circle", offset: { x: 0.2079, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port146", shape: "Circle", offset: { x: 0.1780, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port147", shape: "Circle", offset: { x: 0.1471, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port148", shape: "Circle", offset: { x: 0.1172, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port149", shape: "Circle", offset: { x: 0.0873, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port150", shape: "Circle", offset: { x: 0.0574, y: 0.4320 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "breadboard-port151", shape: "Circle", offset: { x: 0.9255, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port152", shape: "Circle", offset: { x: 0.8956, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port153", shape: "Circle", offset: { x: 0.8657, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port154", shape: "Circle", offset: { x: 0.8358, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port155", shape: "Circle", offset: { x: 0.8059, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port156", shape: "Circle", offset: { x: 0.7760, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port157", shape: "Circle", offset: { x: 0.7461, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port158", shape: "Circle", offset: { x: 0.7162, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port159", shape: "Circle", offset: { x: 0.6863, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port160", shape: "Circle", offset: { x: 0.6564, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port161", shape: "Circle", offset: { x: 0.6265, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port162", shape: "Circle", offset: { x: 0.5966, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port163", shape: "Circle", offset: { x: 0.5667, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port164", shape: "Circle", offset: { x: 0.5368, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port165", shape: "Circle", offset: { x: 0.5069, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port166", shape: "Circle", offset: { x: 0.4770, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port167", shape: "Circle", offset: { x: 0.4471, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port168", shape: "Circle", offset: { x: 0.4172, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port169", shape: "Circle", offset: { x: 0.3873, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port170", shape: "Circle", offset: { x: 0.3574, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port171", shape: "Circle", offset: { x: 0.3275, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port172", shape: "Circle", offset: { x: 0.2976, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port173", shape: "Circle", offset: { x: 0.2677, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port174", shape: "Circle", offset: { x: 0.2378, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port175", shape: "Circle", offset: { x: 0.2079, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port176", shape: "Circle", offset: { x: 0.1780, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port177", shape: "Circle", offset: { x: 0.1471, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port178", shape: "Circle", offset: { x: 0.1172, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port179", shape: "Circle", offset: { x: 0.0873, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port180", shape: "Circle", offset: { x: 0.0574, y: 0.5785 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port181", shape: "Circle", offset: { x: 0.9255, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port182", shape: "Circle", offset: { x: 0.8956, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port183", shape: "Circle", offset: { x: 0.8657, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port184", shape: "Circle", offset: { x: 0.8358, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port185", shape: "Circle", offset: { x: 0.8059, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port186", shape: "Circle", offset: { x: 0.7760, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port187", shape: "Circle", offset: { x: 0.7461, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port188", shape: "Circle", offset: { x: 0.7162, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port189", shape: "Circle", offset: { x: 0.6863, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port190", shape: "Circle", offset: { x: 0.6564, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port191", shape: "Circle", offset: { x: 0.6265, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port192", shape: "Circle", offset: { x: 0.5966, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port193", shape: "Circle", offset: { x: 0.5667, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port194", shape: "Circle", offset: { x: 0.5368, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port195", shape: "Circle", offset: { x: 0.5069, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port196", shape: "Circle", offset: { x: 0.4770, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port197", shape: "Circle", offset: { x: 0.4471, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port198", shape: "Circle", offset: { x: 0.4172, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port199", shape: "Circle", offset: { x: 0.3873, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port200", shape: "Circle", offset: { x: 0.3574, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port201", shape: "Circle", offset: { x: 0.3275, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port202", shape: "Circle", offset: { x: 0.2976, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port203", shape: "Circle", offset: { x: 0.2677, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port204", shape: "Circle", offset: { x: 0.2378, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port205", shape: "Circle", offset: { x: 0.2079, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port206", shape: "Circle", offset: { x: 0.1780, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port207", shape: "Circle", offset: { x: 0.1471, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port208", shape: "Circle", offset: { x: 0.1172, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port209", shape: "Circle", offset: { x: 0.0873, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port210", shape: "Circle", offset: { x: 0.0574, y: 0.6250 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port211", shape: "Circle", offset: { x: 0.9255, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }, {
        id: "breadboard-port212", shape: "Circle", offset: { x: 0.8956, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port213", shape: "Circle", offset: { x: 0.8657, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port214", shape: "Circle", offset: { x: 0.8358, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port215", shape: "Circle", offset: { x: 0.8059, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port216", shape: "Circle", offset: { x: 0.7760, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port217", shape: "Circle", offset: { x: 0.7461, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port218", shape: "Circle", offset: { x: 0.7162, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port219", shape: "Circle", offset: { x: 0.6863, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port220", shape: "Circle", offset: { x: 0.6564, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port221", shape: "Circle", offset: { x: 0.6265, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port222", shape: "Circle", offset: { x: 0.5966, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port223", shape: "Circle", offset: { x: 0.5667, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port224", shape: "Circle", offset: { x: 0.5368, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port225", shape: "Circle", offset: { x: 0.5069, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port226", shape: "Circle", offset: { x: 0.4770, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port227", shape: "Circle", offset: { x: 0.4471, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port228", shape: "Circle", offset: { x: 0.4172, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port229", shape: "Circle", offset: { x: 0.3873, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port230", shape: "Circle", offset: { x: 0.3574, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port231", shape: "Circle", offset: { x: 0.3275, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port232", shape: "Circle", offset: { x: 0.2976, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port233", shape: "Circle", offset: { x: 0.2677, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port234", shape: "Circle", offset: { x: 0.2378, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port235", shape: "Circle", offset: { x: 0.2079, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port236", shape: "Circle", offset: { x: 0.1780, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port237", shape: "Circle", offset: { x: 0.1471, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port238", shape: "Circle", offset: { x: 0.1172, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port239", shape: "Circle", offset: { x: 0.0873, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port240", shape: "Circle", offset: { x: 0.0574, y: 0.6715 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port241", shape: "Circle", offset: { x: 0.9255, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port242", shape: "Circle", offset: { x: 0.8956, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port243", shape: "Circle", offset: { x: 0.8657, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port244", shape: "Circle", offset: { x: 0.8358, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port245", shape: "Circle", offset: { x: 0.8059, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port246", shape: "Circle", offset: { x: 0.7760, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port247", shape: "Circle", offset: { x: 0.7461, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port248", shape: "Circle", offset: { x: 0.7162, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port249", shape: "Circle", offset: { x: 0.6863, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port250", shape: "Circle", offset: { x: 0.6564, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port251", shape: "Circle", offset: { x: 0.6265, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port252", shape: "Circle", offset: { x: 0.5966, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port253", shape: "Circle", offset: { x: 0.5667, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port254", shape: "Circle", offset: { x: 0.5368, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port255", shape: "Circle", offset: { x: 0.5069, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port256", shape: "Circle", offset: { x: 0.4770, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port257", shape: "Circle", offset: { x: 0.4471, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port258", shape: "Circle", offset: { x: 0.4172, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port259", shape: "Circle", offset: { x: 0.3873, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port260", shape: "Circle", offset: { x: 0.3574, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port261", shape: "Circle", offset: { x: 0.3275, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port262", shape: "Circle", offset: { x: 0.2976, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port263", shape: "Circle", offset: { x: 0.2677, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port264", shape: "Circle", offset: { x: 0.2378, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port265", shape: "Circle", offset: { x: 0.2079, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port266", shape: "Circle", offset: { x: 0.1780, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port267", shape: "Circle", offset: { x: 0.1471, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port268", shape: "Circle", offset: { x: 0.1172, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port269", shape: "Circle", offset: { x: 0.0873, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port270", shape: "Circle", offset: { x: 0.0574, y: 0.7180 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port271", shape: "Circle", offset: { x: 0.9255, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port272", shape: "Circle", offset: { x: 0.8956, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port273", shape: "Circle", offset: { x: 0.8657, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port274", shape: "Circle", offset: { x: 0.8358, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port275", shape: "Circle", offset: { x: 0.8059, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port276", shape: "Circle", offset: { x: 0.7760, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port277", shape: "Circle", offset: { x: 0.7461, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port278", shape: "Circle", offset: { x: 0.7162, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port279", shape: "Circle", offset: { x: 0.6863, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port280", shape: "Circle", offset: { x: 0.6564, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port281", shape: "Circle", offset: { x: 0.6265, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port282", shape: "Circle", offset: { x: 0.5966, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port283", shape: "Circle", offset: { x: 0.5667, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port284", shape: "Circle", offset: { x: 0.5368, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port285", shape: "Circle", offset: { x: 0.5069, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port286", shape: "Circle", offset: { x: 0.4770, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port287", shape: "Circle", offset: { x: 0.4471, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port288", shape: "Circle", offset: { x: 0.4172, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port289", shape: "Circle", offset: { x: 0.3873, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port290", shape: "Circle", offset: { x: 0.3574, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port291", shape: "Circle", offset: { x: 0.3275, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port292", shape: "Circle", offset: { x: 0.2976, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port293", shape: "Circle", offset: { x: 0.2677, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port294", shape: "Circle", offset: { x: 0.2378, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port295", shape: "Circle", offset: { x: 0.2079, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port296", shape: "Circle", offset: { x: 0.1780, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port297", shape: "Circle", offset: { x: 0.1471, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port298", shape: "Circle", offset: { x: 0.1172, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port299", shape: "Circle", offset: { x: 0.0873, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw
    },
    {
        id: "breadboard-port300", shape: "Circle", offset: { x: 0.0574, y: 0.7645 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },





    {
        id: "breadboard-port301", shape: "Circle", offset: { x: 0.058, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port302", shape: "Circle", offset: { x: 0.088, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port303", shape: "Circle", offset: { x: 0.118, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port304", shape: "Circle", offset: { x: 0.148, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port305", shape: "Circle", offset: { x: 0.178, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port306", shape: "Circle", offset: { x: 0.248, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port307", shape: "Circle", offset: { x: 0.278, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port308", shape: "Circle", offset: { x: 0.308, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port309", shape: "Circle", offset: { x: 0.338, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port310", shape: "Circle", offset: { x: 0.368, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port311", shape: "Circle", offset: { x: 0.432, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port312", shape: "Circle", offset: { x: 0.462, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port313", shape: "Circle", offset: { x: 0.492, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port314", shape: "Circle", offset: { x: 0.522, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port315", shape: "Circle", offset: { x: 0.552, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port316", shape: "Circle", offset: { x: 0.622, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port317", shape: "Circle", offset: { x: 0.652, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port318", shape: "Circle", offset: { x: 0.682, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port319", shape: "Circle", offset: { x: 0.712, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port320", shape: "Circle", offset: { x: 0.742, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port321", shape: "Circle", offset: { x: 0.802, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port322", shape: "Circle", offset: { x: 0.832, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port323", shape: "Circle", offset: { x: 0.862, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port324", shape: "Circle", offset: { x: 0.892, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port325", shape: "Circle", offset: { x: 0.922, y: 0.8895 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "breadboard-port326", shape: "Circle", offset: { x: 0.058, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port327", shape: "Circle", offset: { x: 0.088, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port328", shape: "Circle", offset: { x: 0.118, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port329", shape: "Circle", offset: { x: 0.148, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port330", shape: "Circle", offset: { x: 0.178, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port331", shape: "Circle", offset: { x: 0.248, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port332", shape: "Circle", offset: { x: 0.278, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port333", shape: "Circle", offset: { x: 0.308, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port334", shape: "Circle", offset: { x: 0.338, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port335", shape: "Circle", offset: { x: 0.368, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port336", shape: "Circle", offset: { x: 0.432, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port337", shape: "Circle", offset: { x: 0.462, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port338", shape: "Circle", offset: { x: 0.492, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port339", shape: "Circle", offset: { x: 0.522, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port340", shape: "Circle", offset: { x: 0.552, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port341", shape: "Circle", offset: { x: 0.622, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port342", shape: "Circle", offset: { x: 0.652, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port343", shape: "Circle", offset: { x: 0.682, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port344", shape: "Circle", offset: { x: 0.712, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port345", shape: "Circle", offset: { x: 0.742, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port346", shape: "Circle", offset: { x: 0.802, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port347", shape: "Circle", offset: { x: 0.832, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port348", shape: "Circle", offset: { x: 0.862, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port349", shape: "Circle", offset: { x: 0.892, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port350", shape: "Circle", offset: { x: 0.922, y: 0.9349 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port351", shape: "Circle", offset: { x: 0.0588, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port352", shape: "Circle", offset: { x: 0.0888, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port353", shape: "Circle", offset: { x: 0.1188, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port354", shape: "Circle", offset: { x: 0.1488, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port355", shape: "Circle", offset: { x: 0.1788, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port356", shape: "Circle", offset: { x: 0.2488, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port357", shape: "Circle", offset: { x: 0.2788, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port358", shape: "Circle", offset: { x: 0.3088, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port359", shape: "Circle", offset: { x: 0.3388, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port360", shape: "Circle", offset: { x: 0.3688, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port361", shape: "Circle", offset: { x: 0.4328, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port362", shape: "Circle", offset: { x: 0.4628, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port363", shape: "Circle", offset: { x: 0.4928, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port364", shape: "Circle", offset: { x: 0.5228, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port365", shape: "Circle", offset: { x: 0.5528, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port366", shape: "Circle", offset: { x: 0.6228, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port367", shape: "Circle", offset: { x: 0.6528, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port368", shape: "Circle", offset: { x: 0.6828, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port369", shape: "Circle", offset: { x: 0.7128, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port370", shape: "Circle", offset: { x: 0.7428, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port371", shape: "Circle", offset: { x: 0.8028, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port372", shape: "Circle", offset: { x: 0.8328, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port373", shape: "Circle", offset: { x: 0.8628, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port374", shape: "Circle", offset: { x: 0.8928, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port375", shape: "Circle", offset: { x: 0.9228, y: 0.065 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "breadboard-port376", shape: "Circle", offset: { x: 0.0588, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port377", shape: "Circle", offset: { x: 0.0888, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port378", shape: "Circle", offset: { x: 0.1188, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port379", shape: "Circle", offset: { x: 0.1488, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port380", shape: "Circle", offset: { x: 0.1788, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port381", shape: "Circle", offset: { x: 0.2488, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port382", shape: "Circle", offset: { x: 0.2788, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port383", shape: "Circle", offset: { x: 0.3088, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port384", shape: "Circle", offset: { x: 0.3388, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port385", shape: "Circle", offset: { x: 0.3688, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port386", shape: "Circle", offset: { x: 0.4328, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port387", shape: "Circle", offset: { x: 0.4628, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port388", shape: "Circle", offset: { x: 0.4928, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port389", shape: "Circle", offset: { x: 0.5228, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port390", shape: "Circle", offset: { x: 0.5528, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port391", shape: "Circle", offset: { x: 0.6228, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port392", shape: "Circle", offset: { x: 0.6528, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port393", shape: "Circle", offset: { x: 0.6828, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port394", shape: "Circle", offset: { x: 0.7128, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port395", shape: "Circle", offset: { x: 0.7428, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port396", shape: "Circle", offset: { x: 0.8028, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port397", shape: "Circle", offset: { x: 0.8328, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "breadboard-port398", shape: "Circle", offset: { x: 0.8628, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port399", shape: "Circle", offset: { x: 0.8928, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port400", shape: "Circle", offset: { x: 0.9228, y: 0.110 }, height: 12, width: 12, visibility: PortVisibility.Hover, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    }
]




export const shieldPorts: CustomPort[] = [
    {
        id: "shield-port3", shape: "Square", offset: { x: 0, y: 0.305 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port4", shape: "Square", offset: { x: 0, y: 0.35 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port5", shape: "Square", offset: { x: 0, y: 0.39 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1",
        constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-port6", shape: "Square", offset: { x: 0, y: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port7", shape: "Square", offset: { x: 0, y: 0.51 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port8", shape: "Square", offset: { x: 0, y: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-port9", shape: "Square", offset: { x: 0, y: 0.63 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port10", shape: "Square", offset: { x: 0, y: 0.670 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port11", shape: "Square", offset: { x: 0, y: 0.710 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-port13", shape: "Square", offset: { x: 0, y: 0.8 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port14", shape: "Square", offset: { x: 0, y: 0.835 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port15", shape: "Square", offset: { x: 0, y: 0.87 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-port18", shape: "Square", offset: { x: 1, y: 0.315 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port35", shape: "Square", offset: { x: 1, y: 0.345 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port19", shape: "Square", offset: { x: 1, y: 0.375 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port20", shape: "Square", offset: { x: 1, y: 0.405 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "shield-port22", shape: "Square", offset: { x: 1, y: 0.49 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port23", shape: "Square", offset: { x: 1, y: 0.54 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-port25", shape: "Square", offset: { x: 1, y: 0.640 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port26", shape: "Square", offset: { x: 1, y: 0.680 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-port28", shape: "Square", offset: { x: 1, y: 0.78 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port29", shape: "Square", offset: { x: 1, y: 0.82 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port30", shape: "Square", offset: { x: 1, y: 0.87 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "shield-port31", shape: "Square", offset: { x: 0.615, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port32", shape: "Square", offset: { x: 0.69, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-port33", shape: "Square", offset: { x: 0.355, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port34", shape: "Square", offset: { x: 0.27, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const newShieldPorts: CustomPort[] = [
    {
        id: "shield-led-digital2", shape: "Square", offset: { x: 0, y: 0.185 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-led-gnd", shape: "Square", offset: { x: 0, y: 0.22 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-touch-gnd", shape: "Square", offset: { x: 0, y: 0.31 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-touch-vcc", shape: "Square", offset: { x: 0, y: 0.345 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-touch-digital15", shape: "Square", offset: { x: 0, y: 0.38 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1",
        constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-ldr-vcc", shape: "Square", offset: { x: 0, y: 0.46 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ldr-gnd", shape: "Square", offset: { x: 0, y: 0.495 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ldr-digital18", shape: "Square", offset: { x: 0, y: 0.53 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-ir2-vcc", shape: "Square", offset: { x: 0, y: 0.61 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ir2-gnd", shape: "Square", offset: { x: 0, y: 0.645 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ir2-digital19", shape: "Square", offset: { x: 0, y: 0.68 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-servo-gnd", shape: "Square", offset: { x: 0, y: 0.76 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-servo-vcc", shape: "Square", offset: { x: 0, y: 0.795 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-servo-digital23", shape: "Square", offset: { x: 0, y: 0.83 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },











    {
        id: "shield-rgb-vcc", shape: "Square", offset: { x: 1, y: 0.37 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-rgb-digital14", shape: "Square", offset: { x: 1, y: 0.395 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-rgb-digital12", shape: "Square", offset: { x: 1, y: 0.42 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-rgb-digital13", shape: "Square", offset: { x: 1, y: 0.445 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-ir1-vcc", shape: "Square", offset: { x: 1, y: 0.54 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    // {
    //     id: "shield-port22", shape: "Square", offset: { x: 1, y: 0.565 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    // },
    {
        id: "shield-ir1-gnd", shape: "Square", offset: { x: 1, y: 0.577 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ir1-digital35", shape: "Square", offset: { x: 1, y: 0.615 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-ultrasonic-vcc", shape: "Square", offset: { x: 1, y: 0.71 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ultrasonic-digital4", shape: "Square", offset: { x: 1, y: 0.735 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ultrasonic-digital5", shape: "Square", offset: { x: 1, y: 0.76 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ultrasonic-gnd", shape: "Square", offset: { x: 1, y: 0.785 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },





    {
        id: "shield-m2-digital32", shape: "Square", offset: { x: 0.54, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-m2-digital33", shape: "Square", offset: { x: 0.58, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-m1-digital25", shape: "Square", offset: { x: 0.42, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-m1-digital26", shape: "Square", offset: { x: 0.46, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "shield-lcd-gnd", shape: "Square", offset: { x: 0.14, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-lcd-vcc", shape: "Square", offset: { x: 0.17, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-lcd-digital21", shape: "Square", offset: { x: 0.20, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-lcd-digital22", shape: "Square", offset: { x: 0.23, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const esp32Ports: CustomPort[] = [
    {
        id: "esp-port1", shape: "Square", offset: { x: 0, y: 0.207 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port2", shape: "Square", offset: { x: 0, y: 0.258 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port3", shape: "Square", offset: { x: 0, y: 0.305 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port4", shape: "Square", offset: { x: 0, y: 0.355 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port5", shape: "Square", offset: { x: 0, y: 0.405 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port6", shape: "Square", offset: { x: 0, y: 0.455 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port7", shape: "Square", offset: { x: 0, y: 0.505 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port8", shape: "Square", offset: { x: 0, y: 0.555 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port9", shape: "Square", offset: { x: 0, y: 0.605 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port10", shape: "Square", offset: { x: 0, y: 0.650 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port11", shape: "Square", offset: { x: 0, y: 0.700 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port12", shape: "Square", offset: { x: 0, y: 0.750 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port13", shape: "Square", offset: { x: 0, y: 0.795 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port14", shape: "Square", offset: { x: 0, y: 0.840 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port15", shape: "Square", offset: { x: 0, y: 0.890 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port16", shape: "Square", offset: { x: 1, y: 0.207 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port17", shape: "Square", offset: { x: 1, y: 0.258 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port18", shape: "Square", offset: { x: 1, y: 0.305 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port19", shape: "Square", offset: { x: 1, y: 0.355 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port20", shape: "Square", offset: { x: 1, y: 0.405 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port21", shape: "Square", offset: { x: 1, y: 0.455 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port22", shape: "Square", offset: { x: 1, y: 0.505 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port23", shape: "Square", offset: { x: 1, y: 0.555 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port24", shape: "Square", offset: { x: 1, y: 0.605 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port25", shape: "Square", offset: { x: 1, y: 0.650 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port26", shape: "Square", offset: { x: 1, y: 0.700 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port27", shape: "Square", offset: { x: 1, y: 0.750 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port28", shape: "Square", offset: { x: 1, y: 0.795 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port29", shape: "Square", offset: { x: 1, y: 0.840 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port30", shape: "Square", offset: { x: 1, y: 0.890 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const ultrasonicPorts: CustomPort[] = [
    {
        id: "ultrasonic-vcc", shape: "Square", offset: { x: 0.41, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ultrasonic-trig-digital", shape: "Square", offset: { x: 0.467, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ultrasonic-echo-digital", shape: "Square", offset: { x: 0.522, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ultrasonic-gnd", shape: "Square", offset: { x: 0.58, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SevenSegmentPorts: CustomPort[] = [
    {
        id: "SevenSegment-digital-G", shape: "Square", offset: { x: 0.15, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-digital-F", shape: "Square", offset: { x: 0.25, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-digital-E", shape: "Square", offset: { x: 0.35, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-digital-D", shape: "Square", offset: { x: 0.45, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-digital-C", shape: "Square", offset: { x: 0.55, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-digital-B", shape: "Square", offset: { x: 0.65, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-digital-A", shape: "Square", offset: { x: 0.75, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-gnd", shape: "Square", offset: { x: 0.85, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SensorModulePorts: CustomPort[] = [
    // {
    //     id: "SensorModule-port1", shape: "Square", offset: { x: 0, y: 0.51 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    // },
    // {
    //     id: "SensorModule-port2", shape: "Square", offset: { x: 0, y: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    // },



    {
        id: "SensorModule-port3", shape: "Square", offset: { x: 1, y: 0.42 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port4", shape: "Square", offset: { x: 1, y: 0.48 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port2", shape: "Square", offset: { x: 1, y: 0.54 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "SensorModule-port5", shape: "Square", offset: { y: 0, x: 0.535 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port6", shape: "Square", offset: { y: 0, x: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "SensorModule-port7", shape: "Square", offset: { y: 1, x: 0.57 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port8", shape: "Square", offset: { y: 1, x: 0.49 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port9", shape: "Square", offset: { y: 1, x: 0.42 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const dht11Ports: CustomPort[] = [
    {
        id: "dht11-vcc", shape: "Square", offset: { x: 0.20, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "dht11-digital2", shape: "Square", offset: { x: 0.50, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "dht11-gnd", shape: "Square", offset: { x: 0.80, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const IRPorts: CustomPort[] = [
    {
        id: "IR-digital1", shape: "Square", offset: { x: 0.39, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "IR-gnd", shape: "Square", offset: { x: 0.5, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "IR-vcc", shape: "Square", offset: { x: 0.62, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const TouchPorts: CustomPort[] = [
    {
        id: "Touch-gnd", shape: "Square", offset: { x: 0.39, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Touch-vcc", shape: "Square", offset: { x: 0.5, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Touch-digital", shape: "Square", offset: { x: 0.62, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SoilMoisturePorts: CustomPort[] = [
    {
        id: "SoilMoisture-gnd", shape: "Square", offset: { x: 0.24, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SoilMoisture-vcc", shape: "Square", offset: { x: 0.43, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SoilMoisture-digital", shape: "Square", offset: { x: 0.60, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SoilMoisture-Analog", shape: "Square", offset: { x: 0.78, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]


export const LDRPorts: CustomPort[] = [
    {
        id: "LDR-gnd", shape: "Square", offset: { x: 0.39, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LDR-digital", shape: "Square", offset: { x: 0.5, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LDR-vcc", shape: "Square", offset: { x: 0.62, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const LCDPorts: CustomPort[] = [
    {
        id: "LCD-gnd", shape: "Square", offset: { y: 0.20, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LCD-vcc", shape: "Square", offset: { y: 0.27, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LCD-SDA-digital", shape: "Square", offset: { y: 0.34, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LCD-SCL-digital", shape: "Square", offset: { y: 0.41, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SmokeSensorPorts: CustomPort[] = [
    {
        id: "SmokeSensor-port1", shape: "Square", offset: { x: 0.31, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SmokeSensor-port2", shape: "Square", offset: { x: 0.44, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SmokeSensor-port3", shape: "Square", offset: { x: 0.57, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SmokeSensor-port4", shape: "Square", offset: { x: 0.70, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const KeypadPorts: CustomPort[] = [
    {
        id: "Keypad-port1", shape: "Square", offset: { x: 0.385, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port2", shape: "Square", offset: { x: 0.42, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port3", shape: "Square", offset: { x: 0.455, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port4", shape: "Square", offset: { x: 0.49, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port5", shape: "Square", offset: { x: 0.525, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port6", shape: "Square", offset: { x: 0.56, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port7", shape: "Square", offset: { x: 0.595, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port8", shape: "Square", offset: { x: 0.63, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]




export const LEDPorts: CustomPort[] = [
    {
        id: "LED-port1", shape: "Square", offset: { x: 0, y: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LED-port2", shape: "Square", offset: { x: 0, y: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]


export const RGB_LED_Ports: CustomPort[] = [
    {
        id: "rgb-vcc1", shape: "Square", offset: { x: 0, y: 0.44 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-r1-digital", shape: "Square", offset: { x: 0, y: 0.52 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-vcc2", shape: "Square", offset: { x: 1, y: 0.44 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-r2-digital", shape: "Square", offset: { x: 1, y: 0.52 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-r-digital", shape: "Square", offset: { x: 0.38, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-g-digital", shape: "Square", offset: { x: 0.46, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-b-digital", shape: "Square", offset: { x: 0.54, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-vcc", shape: "Square", offset: { x: 0.62, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const DCMotorPorts: CustomPort[] = [
    {
        id: "DCMotor-digital25", shape: "Square", offset: { y: 0, x: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DCMotor-digital26", shape: "Square", offset: { y: 0, x: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DCMotor-digital32", shape: "Square", offset: { y: 1, x: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DCMotor-digital33", shape: "Square", offset: { y: 1, x: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const BOMotorPorts: CustomPort[] = [
    {
        id: "BOMotor-digital25", shape: "Square", offset: { y: 0, x: 0.65 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "BOMotor-digital26", shape: "Square", offset: { y: 0, x: 0.80 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const DotMatrixPorts: CustomPort[] = [
    {
        id: "DotMatrix-vcc", shape: "Square", offset: { y: 0.36, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-gnd", shape: "Square", offset: { y: 0.438, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-digital", shape: "Square", offset: { y: 0.515, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-CS-digital", shape: "Square", offset: { y: 0.588, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-CLK-digital", shape: "Square", offset: { y: 0.67, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const BuzzerPorts: CustomPort[] = [
    {
        id: "Buzzer-port1", shape: "Square", offset: { x: 1, y: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Buzzer-port2", shape: "Square", offset: { x: 1, y: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const PushButtonPorts: CustomPort[] = [
    {
        id: "PushButton-port1", shape: "Square", offset: { x: 0, y: 0.51 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port2", shape: "Square", offset: { x: 0, y: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port3", shape: "Square", offset: { x: 1, y: 0.53 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port4", shape: "Square", offset: { x: 1, y: 0.49 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port5", shape: "Square", offset: { y: 0, x: 0.535 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port6", shape: "Square", offset: { y: 0, x: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port7", shape: "Square", offset: { y: 1, x: 0.53 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port8", shape: "Square", offset: { y: 1, x: 0.46 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const ServoPorts: CustomPort[] = [
    {
        id: "Servo-gnd", shape: "Square", offset: { x: 0.84, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Servo-vcc", shape: "Square", offset: { x: 0.90, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Servo-digital", shape: "Square", offset: { x: 0.96, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]




export const OLEDPorts: CustomPort[] = [
    {
        id: "OLED-gnd", shape: "Square", offset: { x: 0.22, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OLED-vcc", shape: "Square", offset: { x: 0.375, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OLED-SCL-digital", shape: "Square", offset: { x: 0.535, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OLED-SDA-digital", shape: "Square", offset: { x: 0.685, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const RFIDPorts: CustomPort[] = [
    {
        id: "RFID-port1", shape: "Square", offset: { x: 0.25, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port2", shape: "Square", offset: { x: 0.32, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port3", shape: "Square", offset: { x: 0.385, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port4", shape: "Square", offset: { x: 0.45, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port5", shape: "Square", offset: { x: 0.52, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port6", shape: "Square", offset: { x: 0.585, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port7", shape: "Square", offset: { x: 0.65, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port8", shape: "Square", offset: { x: 0.715, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const ADXL345Ports: CustomPort[] = [
    {
        id: "RFID-port1", shape: "Square", offset: { x: 0.1, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port2", shape: "Square", offset: { x: 0.215, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port3", shape: "Square", offset: { x: 0.322, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port4", shape: "Square", offset: { x: 0.434, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port5", shape: "Square", offset: { x: 0.549, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port6", shape: "Square", offset: { x: 0.663, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port7", shape: "Square", offset: { x: 0.773, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port8", shape: "Square", offset: { x: 0.895, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const RedLEDPorts: CustomPort[] = [
    {
        id: "RedLED-gnd", shape: "Square", offset: { y: 1, x: 0.15 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RedLED-vcc", shape: "Square", offset: { y: 1, x: 0.85 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const CeramicCapacitorPorts: CustomPort[] = [
    {
        id: "CeramicCapacitor-gnd", shape: "Square", offset: { y: 1, x: 0.1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "CeramicCapacitor-vcc", shape: "Square", offset: { y: 1, x: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const ElectrolyticCapacitorPorts: CustomPort[] = [
    {
        id: "ElectrolyticCapacitor-gnd", shape: "Square", offset: { y: 1, x: 0.25 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ElectrolyticCapacitor-vcc", shape: "Square", offset: { y: 1, x: 0.7 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const PNPTransistorPorts: CustomPort[] = [
    {
        id: "PNPTransistor-P1", shape: "Square", offset: { y: 1, x: 0.12 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PNPTransistor-N", shape: "Square", offset: { y: 1, x: 0.5 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PNPTransistor-P2", shape: "Square", offset: { y: 1, x: 0.88 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SlideSwitchPorts: CustomPort[] = [
    {
        id: "SlideSwitch-P1", shape: "Square", offset: { y: 1, x: 0.25 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SlideSwitch-P3", shape: "Square", offset: { y: 1, x: 0.5 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SlideSwitch-P2", shape: "Square", offset: { y: 1, x: 0.76 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const TransistorPorts: CustomPort[] = [
    {
        id: "Transistor-P1", shape: "Square", offset: { y: 1, x: 0.0 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Transistor-N", shape: "Square", offset: { y: 1, x: 0.5 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Transistor-P2", shape: "Square", offset: { y: 1, x: 0.1 }, height: 5, width: 5, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const PushButtonComponentPorts: CustomPort[] = [
    {
        id: "PushButton-left", shape: "Square", offset: { y: 0.99, x: 0.07 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-right", shape: "Square", offset: { y: 0.95, x: 0.99 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const GreenLEDPorts: CustomPort[] = [
    {
        id: "GreenLED-gnd", shape: "Square", offset: { y: 1, x: 0.15 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "GreenLED-vcc", shape: "Square", offset: { y: 1, x: 0.85 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const WhiteLEDPorts: CustomPort[] = [
    {
        id: "WhiteLED-gnd", shape: "Square", offset: { y: 1, x: 0.15 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "WhiteLED-vcc", shape: "Square", offset: { y: 1, x: 0.85 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const YellowLEDPorts: CustomPort[] = [
    {
        id: "YellowLED-gnd", shape: "Square", offset: { y: 1, x: 0.15 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "YellowLED-vcc", shape: "Square", offset: { y: 1, x: 0.85 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const TenKRegisterPorts: CustomPort[] = [
    {
        id: "TenKRegister-left", shape: "Square", offset: { y: 1, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "TenKRegister-right", shape: "Square", offset: { y: 1, x: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const OneKRegisterPorts: CustomPort[] = [
    {
        id: "OneKRegister-left", shape: "Square", offset: { y: 1, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OneKRegister-right", shape: "Square", offset: { y: 1, x: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const OneMRegisterPorts: CustomPort[] = [
    {
        id: "OneMRegister-left", shape: "Square", offset: { y: 1, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OneMRegister-right", shape: "Square", offset: { y: 1, x: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const LightDependentResistorPorts: CustomPort[] = [
    {
        id: "LightDependentResistor-left", shape: "Square", offset: { y: 1, x: 0.15 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LightDependentResistor-right", shape: "Square", offset: { y: 1, x: 0.65 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const BuzzerComponentPorts: CustomPort[] = [
    {
        id: "BuzzerComponent-gnd", shape: "Square", offset: { y: 0.95, x: 0.1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "BuzzerComponent-vcc", shape: "Square", offset: { y: 0.95, x: 0.87 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]   
import React, { useContext } from 'react';
import { Dispatch, createContext, useEffect, useReducer } from 'react';
import { ContentDialogBox } from '../components/dialog-box';
import { Radio, RotatingLines } from 'react-loader-spinner';
import { eduCOBOTColorScheme } from './SettingsContext';
import { Card, Typography, Button } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
export type ConnectionDataType = {
    wifi: {
        isConnected: boolean;
        connectedModuleIP: string;
        moduleName: string;
    };
    software: {
        softwareIP: string;
        isConnected: boolean;
    };
    local: {
        isConnected: boolean;
        localIP: string;
        localNetworkName: string;
        type: string;
        isWiFiEnabled: boolean
    },
    default: {
        connectionType: "wifi",
        command: string
    },
    isConnectionTab: boolean
};

export async function getLocalConnectionData(): Promise<ConnectionDataType | null> {
    const connection = await localStorage.getItem('connection');
    if (connection) {
        return JSON.parse(connection) as ConnectionDataType;
    } else {
        return null;
    }
}

export async function setLocalConnectionData(
    connection: ConnectionDataType,
): Promise<void> {
    await localStorage.setItem('connection', JSON.stringify(connection));
}

export async function removeLocalConnectionData(): Promise<void> {
    await localStorage.removeItem('connection');
}

type connectionActionType = {
    type: 'setWiFi' | 'setBluetooth' | 'setSoftware' | 'setLocal' | "setDefault" | "setIsConnectionTab";
    value: {
        data:
        | ConnectionDataType['wifi']
        | ConnectionDataType['software']
        | ConnectionDataType['local']
        | ConnectionDataType['default']
        | boolean
    };
};

const initialState: ConnectionDataType = {
    wifi: {
        isConnected: false,
        connectedModuleIP: '',
        moduleName: '',
    },
    software: {
        softwareIP: '',
        isConnected: false,
    },
    local: {
        isConnected: false,
        localIP: '',
        localNetworkName: '',
        type: '',
        isWiFiEnabled: false
    },
    default: {
        connectionType: 'wifi',
        command: 'none'
    },
    isConnectionTab: false
};
function userReducer(
    state: ConnectionDataType,
    action: connectionActionType,
): ConnectionDataType {
    switch (action.type) {
        case 'setWiFi':
            setLocalConnectionData({
                ...state,
                wifi: action.value.data as ConnectionDataType['wifi'],
            });
            return {
                ...state,
                wifi: action.value.data as ConnectionDataType['wifi'],
            };
        case 'setSoftware':
            setLocalConnectionData({
                ...state,
                software: action.value.data as ConnectionDataType['software'],
            });
            return {
                ...state,
                software: action.value.data as ConnectionDataType['software'],
            };
        case 'setLocal':
            setLocalConnectionData({
                ...state,
                local: action.value.data as ConnectionDataType['local'],
            });
            return {
                ...state,
                local: action.value.data as ConnectionDataType['local'],
            };
        case 'setDefault':
            setLocalConnectionData({
                ...state,
                default: action.value.data as ConnectionDataType['default'],
            });
            return {
                ...state,
                default: action.value.data as ConnectionDataType['default'],
            };
        case 'setIsConnectionTab':
            setLocalConnectionData({
                ...state,
                isConnectionTab: action.value.data as ConnectionDataType['isConnectionTab'],
            });
            return {
                ...state,
                isConnectionTab: action.value.data as ConnectionDataType['isConnectionTab'],
            };
        default:
            return state;
    }
}

export const ConnectionContext = createContext({
    connection: initialState,
    modifyConnection: (() => { }) as Dispatch<connectionActionType>,
    checkLocalConnectionData: (() => { }) as () => void,
    handleConnectionTab: (value: boolean) => { }
});

export default function ConnectionContextProvider(props: { children: React.ReactNode }) {
    const [connection, modifyConnection] = useReducer(userReducer, initialState);
    async function checkLocalConnectionData() {
        const connection = await getLocalConnectionData();
        console.log('connection', connection);
    }
    useEffect(() => {
        checkLocalConnectionData();
    }, []);
    function handleConnectionTab(value: boolean) {
        modifyConnection({ type: 'setIsConnectionTab', value: { data: value } })
    }
    return (
        <ConnectionContext.Provider
            value={{
                connection,
                modifyConnection,
                checkLocalConnectionData,
                handleConnectionTab
            }}>
            {props.children}

            <ContentDialogBox isOpen={connection.isConnectionTab} paperStyle={{
                background: "#FFFFFFA5",
                borderRadius: 20,
                backdropFilter: "blur(2px)",
                display: "flex",
                alignItems: "center",
                // boxShadow: "5px 5px 5px 0px #FFFFFF70",
            }} onClose={() => { handleConnectionTab(false) }} title={<>
                <h3 style={{
                    color: eduCOBOTColorScheme.black
                }}>Connection Status</h3>
            </>} content={<>
                <ConnectionTab />
            </>}
                transitionDirection='left'
                isTransition={true}
                maxWidth='md'
                contentStyle={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                    width: "100%",
                }}
            />

        </ConnectionContext.Provider>
    );
}




function ConnectionTab() {
    const { connection, modifyConnection } = useContext(ConnectionContext);
    const [isSoftwareChecked, setIsSoftwareChecked] = React.useState(connection.software.isConnected);
    const [isModuleChecked, setIsModuleChecked] = React.useState(connection.wifi.isConnected);
    const [isSoftwareFound, setIsSoftwareFound] = React.useState(connection.software.isConnected);
    const [isModuleConnected, setIsModuleConnected] = React.useState(connection.wifi.isConnected);
    const [isSearching, setIsSearching] = React.useState(true);
    const [isConfigurationsChecked, setIsConfigurationsChecked] = React.useState(connection.wifi.isConnected);
    const [isConfigurationsFound, setIsConfigurationsFound] = React.useState(false);
    const [configurationData, setConfigurationData] = React.useState({
        IPAddress: "",
        moduleName: "",
        wifiName: "",
        wifiPassword: "",
    })
    async function checkSoftwareConnection() {
        try {
            const response = await fetch("http://localhost:8080")
            if (response.status === 200) {
                setIsSoftwareFound(true);
                modifyConnection({
                    type: 'setSoftware',
                    value: {
                        data: {
                            isConnected: true,
                            softwareIP: ""
                        }
                    }
                })
            } else {
                setIsSoftwareFound(false);
            }
        } catch (error) {
            setIsSoftwareFound(false);
        }
        setIsSoftwareChecked(true);
    }
    async function getConfigurations() {
        try {
            const response = await fetch("http://localhost:8080/getConfigurations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            console.log(response);

            if (response.status == 200) {
                const data = await response.json();
                if (data.status === "true") {
                    setConfigurationData({
                        ...configurationData,
                        wifiName: data.message.wifiName,
                        wifiPassword: data.message.wifiPassword,
                        IPAddress: data.message.IPAddress
                    });
                    console.log({
                        wifiName: data.message.wifiName,
                        wifiPassword: data.message.wifiPassword,
                        IPAddress: data.message.IPAddress
                    });

                    setIsConfigurationsFound(true);
                    checkModuleIsOn(data.message.IPAddress);
                };
            } else {
                setIsConfigurationsFound(false);
            }
        } catch (error) {
            setIsConfigurationsFound(false);
        }
        setIsConfigurationsChecked(true);
    }

    async function checkModuleIsOn(ipAddress: string) {
        console.log("checkModuleIsOn", ipAddress);

        try {
            const response = await fetch("http://localhost:8080/make_request_on_esp32",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ IPAddress: ipAddress, endpoint: "" }),
                })


            console.log(response);
            const data = await response.json();
            console.log(data);

            if (response.status == 200) {

                if (data.status === "true") {
                    console.log(configurationData);

                    setConfigurationData((preData) => {
                        return {

                            ...preData,
                            moduleName: data.message,
                        }
                    });
                    modifyConnection({
                        type: 'setWiFi',
                        value: {
                            data: {
                                isConnected: true,
                                moduleName: data.message,
                                connectedModuleIP: ipAddress
                            }
                        }
                    })
                    setIsModuleConnected(true);
                } else {
                    setIsModuleConnected(false);
                }
            } else {
                setIsModuleConnected(false);
            }

        } catch (error) {
            console.log(error);

            setIsModuleConnected(false);

        }
        setIsModuleChecked(true);
    }

    async function refreshData() {
        console.log(connection);

        console.log("isSoftwareFound", isSoftwareFound);
        console.log("isConfigurationsFound", isConfigurationsFound);
        console.log("isModuleConnected", isModuleConnected);
        console.log("isModuleChecked", isModuleChecked);
        console.log("isSoftwareChecked", isSoftwareChecked);
        console.log("isConfigurationsChecked", isConfigurationsChecked);
        console.log("configurationData", configurationData);


        if (!isSoftwareFound) {
            await checkSoftwareConnection()
        }
        if (!isConfigurationsFound) {
            await getConfigurations()
        }
        if (configurationData.IPAddress !== "") {
            await checkModuleIsOn(configurationData.IPAddress)
        }

    }
    useEffect(() => {
        if (isConfigurationsChecked && isModuleChecked && isSoftwareChecked) {
            setIsSearching(false)
        } else {
            setIsSearching(true)
        }
    }, [isConfigurationsChecked, isModuleChecked, isSoftwareChecked])
    useEffect(() => {
        refreshData()
    }, [])

    return <div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexWrap: 'wrap', position: "relative", gap: "20px" }}>
        <Button style={{
            position: "absolute",
            zIndex: 999999,
            top: 0,
            right: 0
        }} onClick={() => {
            refreshData()
        }}>
            <CachedIcon />
        </Button>
        {
            isSearching && <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Radio
                    visible={true}
                    height="80"
                    width="80"
                    colors={[eduCOBOTColorScheme.green, eduCOBOTColorScheme.blue, eduCOBOTColorScheme.red]}
                    ariaLabel="radio-loading"
                />
            </div>
        }
        {
            isSoftwareChecked && <>
                <SoftwareConnectedSuccess status={isSoftwareFound} />
            </>
        }
        {/* {
            isConfigurationsChecked && <>
                <ConfigurationDataCard configurationData={configurationData} status={isConfigurationsFound} />
            </>
        } */}
        {/* {
            isModuleChecked && <>
                <ModuleConnectedSuccess status={isModuleConnected} />
            </>
        } */}
    </div>
}

function ConfigurationDataCard({
    configurationData,
    status,
}: {
    configurationData: {
        IPAddress: string,
        moduleName: string,
        wifiName: string,
        wifiPassword: string
    },
    status: boolean
}) {
    const [isLoading, setIsLoading] = React.useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }, [])
    return <div style={{ display: 'flex', justifyContent: 'center', width: "210px" }}>
        <Card sx={{ padding: "5px", width: "200px", minHeight: "200px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "20px" }}>
            {
                status ? <>
                    <div style={{
                        minHeight: "80px",
                        marginBottom: 20,
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px"
                    }}>

                        <div style={{ fontSize: "10px" }}>
                            <span>Wifi Name</span> : <span style={{
                                fontWeight: "bold"
                            }}>{configurationData.wifiName}</span>
                        </div>
                        <div style={{ fontSize: "10px" }}>
                            <span>Module IP</span> : <span style={{
                                fontWeight: "bold"
                            }}>{configurationData.IPAddress}</span>
                        </div>
                        <div style={{ fontSize: "10px" }}>
                            <span>Module Name</span> : <span style={{
                                fontWeight: "bold"
                            }}>{configurationData.moduleName}</span>
                        </div>
                    </div>
                </> : <>
                    <h6>No Module Connected</h6>
                </>
            }
            <div style={{
            }}>
                {
                    isLoading ? <RotatingLines
                        visible={true}
                        width="30"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                    /> : status ? <img src="https://cdn-icons-png.flaticon.com/128/8622/8622132.png" style={{ width: "30px" }} /> : <img src="https://vectorified.com/images/status-icon-33.png" style={{ width: "30px" }} />
                }
            </div>
        </Card>
    </div>
}
function ModuleConnectedSuccess({ status }: { status: boolean }) {
    const [isLoading, setIsLoading] = React.useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }, [])
    return <div style={{ display: 'flex', justifyContent: 'center', width: "210px" }}>

        <Card sx={{ padding: "5px", width: "200px", minHeight: "200px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "20px" }}>
            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg" style={{ height: "80px", marginBottom: 20 }} alt="" />
            {
                isLoading ? <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                /> : status ? <img src="https://cdn-icons-png.flaticon.com/128/8622/8622132.png" style={{ width: "30px" }} /> : <img src="https://vectorified.com/images/status-icon-33.png" style={{ width: "30px" }} />
            }
        </Card>
    </div>
}
function SoftwareConnectedSuccess({ status }: { status: boolean }) {
    const [isLoading, setIsLoading] = React.useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }, [])
    return <div style={{ display: 'flex', justifyContent: 'center', width: "210px" }}>

        <Card sx={{ padding: "5px", width: "200px", minHeight: "200px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "20px" }}>
            <img src="https://storage.googleapis.com/innovator-resourses/assets/eduCOBOT%20Sheild.png" style={{ height: "80px", marginBottom: 20 }} alt="" />
            {
                isLoading ? <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                /> : status ? <img src="https://cdn-icons-png.flaticon.com/128/8622/8622132.png" style={{ width: "30px" }} /> : <img src="https://vectorified.com/images/status-icon-33.png" style={{ width: "30px" }} />
            }
        </Card>
    </div>
}





export async function handleMachineCall(connection: ConnectionDataType, endPoint: string) {
    console.log(endPoint, " is calling");

    if (connection.default.connectionType == "wifi") {
        if (connection.wifi.isConnected) {
            await call_machine_function_on_callback(connection.wifi.connectedModuleIP, endPoint)
        } else {

        }
    }
}

export async function call_machine_function_on_callback(ipAddress: string, endPoint: string) {
    try {
        await fetch(`http://localhost:8080/make_request_on_esp32`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ IPAddress: ipAddress, endpoint: endPoint }),
            })
        return true
    } catch (error) {
        console.log(error);
        return false
    }
}


import { routes } from "../routes/routes"

const ToolsData = [
    {
        name: "Circuit Designer",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/2432/2432692.png",
        link: routes.TOOLS.CIRCUIT_DESIGNER,
        previewImage: ""
    },
    {
        name: "Micropython Editor",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://upload.wikimedia.org/wikipedia/commons/a/a5/MicroPython_new_logo.jpg",
        link: routes.TOOLS.MICROPYTHON_EDITOR,
        previewImage: ""
    },
    {
        name: "Logic Gate Simulator",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/3512/3512140.png",
        link: routes.TOOLS.LOGIC_GATE_SIMULATOR,
        previewImage: ""
    },
    {
        name: "Breaddboard Designer",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/10949/10949431.png",
        link: routes.TOOLS.BREAD_BOARD_SIMULATOR,
        previewImage: ""
    },
]


export default ToolsData
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext'
import { deployCode } from '../../../../components/micropython-block-editor/RoboticHandler'
import MicroPythonEditor from '../../../../components/micropython-block-editor'
import CodeEditor from '../../../../components/code-editor'
import { ContentDialogBox } from '../../../../components/dialog-box'
import { ConnectionContext, handleMachineCall } from '../../../../context/ConnectionContext'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import GetLessonCode from '../../../../utils/GetLessonCode'
type Props = {}




export function getWiFi() {
    const isWiFi_data = localStorage.getItem("isWiFi")
    if (isWiFi_data) {
        if (isWiFi_data === "true") {
            return true
        } else {
            return false
        }
    }
    return false
}

export default function FunWithGamingLessonPage({ }: Props) {
    const { lesson_slug } = useParams()
    const [xml, setXml] = React.useState('')
    const [code, setCode] = React.useState(``)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(false)
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isMatrixCodeResolverOpen, setIsMatrixCodeResolverOpen] = React.useState(false)
    const [isExamplesOpen, setIsExamplesOpen] = React.useState(false)
    const [lessonInstructionsOpen, setLessonInstructionsOpen] = React.useState(true)
    const [lesson, setLesson] = useState<{
        code: string,
        title: string,
        description: string,
        image?: string,
        instructions: string[],
    }>()
    useEffect(() => {
        if (!!lesson_slug) {
            setCode(GetLessonCode(lesson_slug).code)
            setLesson(GetLessonCode(lesson_slug))
        }

    }, [lesson_slug])
    async function deployCodeOnMachine() {
        handleGlobalLoading(true)
        await deployCode(code, false)
        handleGlobalLoading(false)
    }



    return (
        <>
            <ContentDialogBox
                isOpen={lessonInstructionsOpen}
                onClose={() => { setLessonInstructionsOpen(false) }}
                title={lesson?.title}
                paperStyle={{
                    background: "#FFFFFFA5",
                    borderRadius: 20,
                    backdropFilter: "blur(2px)",
                    display: "flex",
                    alignItems: "center",
                    // boxShadow: "5px 5px 5px 0px #FFFFFF70",
                }}
                content={
                    <div style={{ overflowY: "scroll", maxHeight: "80vh" }}>
                        {
                            lesson?.instructions.map((instruction, index) => {
                                return (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // alignItems: "start",
                                        justifyContent: "start",
                                        margin: "10px",
                                        padding: "10px",
                                        borderRadius: "20px",
                                        background: "#FFFFFFA5",
                                        backdropFilter: "blur(2px)",
                                        boxShadow: "5px 5px 5px 0px #FFFFFF70",
                                    }}>
                                        <Typography variant="h6" style={{ textAlign: "left" }}>{instruction}</Typography>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                }
            />


            <div>
                <div style={{
                    display: "flex",
                    height: "100vh",
                }}>
                    {
                        isBlockEditing ?
                            <MicroPythonEditor
                                setCode={(code) => {
                                    // console.log(code)
                                    isBlockEditing && setCode(code)
                                }}
                                setXml={(xml) => {
                                    // console.log(xml)
                                    setXml(xml)
                                }}
                                isLocalSave={true}
                                setNewBlocks={(value) => {
                                    // console.log(value);
                                    isControllerOpen && setNewBlocks(value)
                                }}
                            /> : <CodeEditor code={code}
                                setCode={setCode} />
                    }
                    <div style={{
                        position: "fixed",
                        width: "50px",
                        height: "100vh",
                        top: "0",
                        right: "0",
                        zIndex: 9999999999999,
                        // background: settings.themeMode == "light" ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.black,
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "5vh",
                            zIndex: 999999999
                        }}>
                            <div>
                                <Tooltip title="Upload Code to Innovator IDE">
                                    <Button style={{
                                        border: "none",
                                    }} onClick={() => {
                                        deployCodeOnMachine()
                                    }}>
                                        <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                            width: "20px"
                                        }} alt="" />
                                    </Button>
                                </Tooltip>

                            </div>
                            <div>
                                <Tooltip title="Help">
                                    <Button style={{
                                        border: "none",
                                    }} onClick={() => {
                                        setLessonInstructionsOpen(true)
                                    }}>
                                        <img src="https://storage.googleapis.com/educobot-robotics/images/Help.svg" style={{
                                            width: "20px"
                                        }} alt="" />
                                    </Button>
                                </Tooltip>

                            </div>

                        </div>
                        <div>

                        </div>
                    </div>

                </div>

            </div>
        </>

    )
}


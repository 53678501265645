export const toolbarItems: any = [
    { prefixIcon: 'e-icons e-circle-add', tooltipText: 'New Diagram' },
    { prefixIcon: 'e-icons e-folder-open', tooltipText: 'Open Diagram', },
    { prefixIcon: 'e-icons e-save', tooltipText: 'Save Diagram' },
    { prefixIcon: 'e-print e-icons', tooltipText: 'Print Diagram' },
    { type: 'Input', tooltipText: 'Export Diagram', template: '<button id="diagramexportBtn" style="width:100%;"></button>' },
    { type: 'Separator' },
    { disabled: false, prefixIcon: 'e-cut e-icons', tooltipText: 'Cut', cssClass: 'tb-item-middle tb-item-lock-category' },
    { disabled: false, prefixIcon: 'e-copy e-icons', tooltipText: 'Copy', cssClass: 'tb-item-middle tb-item-lock-category' },
    { prefixIcon: 'e-icons e-paste', tooltipText: 'Paste', disabled: false },
    { type: 'Separator' },
    { disabled: false, prefixIcon: 'e-icons e-undo', tooltipText: 'Undo' },
    { disabled: false, prefixIcon: 'e-icons e-redo', tooltipText: 'Redo' },
    { type: 'Separator', },
    { prefixIcon: 'e-pan e-icons', tooltipText: 'Pan Tool', cssClass: 'tb-item-start pan-item' },
    { prefixIcon: 'e-mouse-pointer e-icons', tooltipText: 'Select Tool', cssClass: 'tb-item-middle tb-item-selected' },
    { tooltipText: 'Change Connector Type', template: '<button id="conTypeBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle' },
    { tooltipText: 'Change Color', id:"colorBtnTool", template: '<button id="colorBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle' },
    { tooltipText: 'Draw Shapes', template: '<button id="shapesBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle' },
    { prefixIcon: 'e-caption e-icons', tooltipText: 'Text Tool', cssClass: 'tb-item-end' },
    { type: 'Separator', },
    { disabled: false, prefixIcon: 'e-icons e-lock', tooltipText: 'Lock', cssClass: 'tb-item-middle tb-item-lock-category' },
    { disabled: false, prefixIcon: 'e-icons e-unlock', tooltipText: 'Unlock', cssClass: 'tb-item-middle tb-item-unlock-category' },
    { disabled: false, prefixIcon: 'e-trash e-icons', tooltipText: 'Delete', cssClass: 'tb-item-middle tb-item-lock-category' },
    { type: 'Separator', align: 'Center' },

    { disabled: false, type: 'Input', tooltipText: 'Align Objects', template: '<button id="alignBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle  tb-item-align-category' },
    { disabled: false, type: 'Input', tooltipText: 'Distribute Objects', template: '<button id="distributeBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle tb-item-space-category' },
    { type: 'Separator', },
    { disabled: false, type: 'Input', tooltipText: 'Order Commands', template: '<button id="orderBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle tb-item-lock-category' },
    { type: 'Separator' },
    { disabled: false, type: 'Input', tooltipText: 'Group/Ungroup', template: '<button id="groupBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle tb-item-align-category' },
    { type: 'Separator' },
    { disabled: false, type: 'Input', tooltipText: 'Rotate', template: '<button id="rotateBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle tb-item-lock-category' },
    { type: 'Separator' },
    { disabled: false, type: 'Input', tooltipText: 'Flip', template: '<button id="flipBtn" style="width:100%;"></button>', cssClass: 'tb-item-middle tb-item-lock-category' },
    { type: 'Separator' },
    {
        cssClass: 'tb-item-end tb-zoom-dropdown-btn', template: '<button id="btnZoomIncrement"></button>',
    },
];

import { useTheme } from '@emotion/react';
import HorizontalTabs from '../../../../components/tabs/HorizontalTabs';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext';
import { BackendConfigs } from '../../../../config.environment';
import { ProjectDataType } from '../../../../types/Dashboard';
import { APIResponse } from '../../../../types/Response';
import { ContentDialogBox } from '../../../../components/dialog-box';
import { Button, Card, CardActions, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import ProjectDescription from '../../Projects/Project/Description/Description';
import { toast } from 'react-toastify';
import { routes } from '../../../../routes/routes';
import { LessonDataType, LessonWithTeacherUnlockStatusType } from '../../../../types/Lesson';
import TotalProgressComponent from '../TotalProgress';
import LeaderboardComponent from '../Leaderboard';
import EducCOBOTLockIcon from '../../../../assets/icons/LockIcon';
import UserRed from '../../../../assets/userIcons/UserRed';
import UserGrey from '../../../../assets/userIcons/UserGrey';
import UserYellow from '../../../../assets/userIcons/UserYellow';
import UserGreen from '../../../../assets/userIcons/UserGreen';
import CloseIcon from '@mui/icons-material/Close';
import { isReactNative, sendMessageToReactNative } from '../../../../utils/ReactNativeHandler';
import GetCardColor from '../../../../utils/GetCardColor';


export interface LessonsProps {
}


export default function TeacherLessons(props: LessonsProps) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [currentFilter, setCurrentFilter] = React.useState("all")
    const [currentGrade, setCurrentGrade] = React.useState(-1)
    const [lessons, setLessons] = React.useState<LessonWithTeacherUnlockStatusType[]>([])
    const [backupLessons, setBackupLessons] = React.useState<LessonWithTeacherUnlockStatusType[]>([])
    const [searchText, setSearchText] = React.useState("")
    const [classValue, setClassValue] = useState("");
    const [divisionValue, setDivisionValue] = useState("");
    const [CLASS_DATA, setCLASS_DATA] = useState<string[]>([]);
    const [DIVISION_DATA, setDIVISION_DATA] = useState<string[]>([]);
    const [teacherData, setTeacherData] = useState<any>()
    const [CourseOTP, setCourseOTP] = useState(undefined);
    const [teacherDivisionData, setTeacherDivisionData] = useState([])
    useEffect(() => {
        if (
            !!localStorage.getItem("standard")
        ) {
            setClassValue(localStorage.getItem("standard") || "")
            if (
                !!localStorage.getItem("division")
            ) {
                setDivisionValue(
                    localStorage.getItem("division") || ""
                )
            }
        }
    }, [])



    async function getTeacherData() {
        if (!!user.userData) {
            try {
                const response = await fetch(`${BackendConfigs.educobotBackend}/blockly/get-user-details`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id
                    })
                })
                const result = await response.json()
                if (!!result) {
                    // console.log(result);
                    setTeacherDivisionData(result.teacher_division)
                    setTeacherData(result)
                    let class_data = result.teacher_division.map((value: {
                        tcClass: string
                    }) => value.tcClass)
                    // console.log(class_data);
                    class_data = new Set(class_data)

                    setCLASS_DATA([...class_data.values()])

                    // console.log([...class_data.values()]);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        if (classValue == "" || CLASS_DATA.includes(classValue)) {

        } else {
            setClassValue("")
            setDivisionValue("")
        }

    }, [CLASS_DATA])
    useEffect(() => {
        if (CLASS_DATA.length > 0) {

            let new_value: string[] = []
            teacherDivisionData.forEach((element: {
                tcClass: string,
                tcDiv: string
            }) => {
                if (element.tcClass === classValue) {
                    new_value.push(element.tcDiv)
                }
            });
            setDIVISION_DATA(new_value)
        }
    }, [classValue, CLASS_DATA])
    const handleCloseClass = (event: any) => {
        if (event.target.value !== classValue) {
            setClassValue(event.target.value)
            setDivisionValue("")
            localStorage.setItem("standard", event.target.value)
        }
    };

    const handleCloseDivision = (event: any) => {
        if (event.target.value !== divisionValue) {
            setDivisionValue(event.target.value)
            localStorage.setItem("division", event.target.value)
        }
    };




    async function checkForLastOTP() {
        if (user!! && user.userData) {
            try {
                let response = await fetch(`${BackendConfigs.educobotBackend}/sessionRoute/getOpenPIN`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json"
                    },
                    body: JSON.stringify({
                        std: classValue,
                        schoolID: user.userData?.schoolId,
                        div: divisionValue,
                        course: "Innovator",
                    })
                })
                let data = await response.json()
                if (data[0]) {
                    setCourseOTP(data[0].spPIN)
                } else {
                    setCourseOTP(undefined)
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getTeacherData()
    }, [user])
    useEffect(() => {
        if (!!classValue && !!divisionValue) {
            checkForLastOTP()
        } else {
            setCourseOTP(undefined)
        }
    }, [divisionValue, classValue])

    const generateOTP = async () => {
        if (CLASS_DATA.includes(classValue) && DIVISION_DATA.includes(divisionValue) && teacherData) {

            let body = {
                "std": `${classValue}`,
                "div": `${divisionValue}`,
                "course": "Innovator",
                "schoolID": user.userData?.schoolId,
                "userType": `${teacherData.user.euUserType}`,
                'userID': `${teacherData.user.euUserId}`
            }
            try {
                const response = await fetch(`${BackendConfigs.educobotBackend}/sessionRoute/generateOTP`, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json', 'authorization': `Bearer ${localStorage.getItem("accessToken")}` },
                    body: JSON.stringify(body),
                })
                if (response.status === 202) {
                    toast.warning("No student found in this class and division")

                } else if (response.status === 200) {
                    const result = await response.json()
                    setCourseOTP(result.otp);
                    toast.success("OTP generated successfully")
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
        }
    }


    async function fetchLessons() {
        handleGlobalLoading(true, "Fetching lessons")
        setLessons([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLessonsWithUnlockDetailsAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    schoolId: user.userData?.schoolId,
                    standard: classValue,
                    division: divisionValue,
                    OTP: CourseOTP
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    setLessons(result.data)
                    setBackupLessons(result.data)
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    React.useEffect(() => {
        if (user && user.userData) {
            fetchLessons()
        }
    }, [user, classValue, divisionValue, CourseOTP])

    useEffect(() => {
        if (currentFilter === "all") {
            setLessons(backupLessons)
        } else {
            setLessons(backupLessons.filter((lesson) => !!lesson.editors.some((editor) => editor.includes(currentFilter))))
        }
    }, [currentFilter, backupLessons])

    useEffect(() => {
        if (searchText === "") {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) =>
                lesson.name.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.description.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.lessonNumber.toString().includes(searchText.toLowerCase()) ||
                lesson.lessonId.toString().includes(searchText.toLowerCase())
            ))
        }
    }, [searchText, backupLessons])
    useEffect(() => {
        if (currentGrade === -1) {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) => lesson.lessonLevel === currentGrade))
        }
    }, [currentGrade])


    async function UnlockLesson(lesson: LessonWithTeacherUnlockStatusType) {
        try {
            const body = {
                "userId": user.userData?.id,
                "OTP": CourseOTP,
                "standard": classValue,
                "division": divisionValue,
                "lessonId": lesson.lessonId,
                "schoolId": user.userData?.schoolId,
            }
            const response = await fetch(`${BackendConfigs.url}/UnlockLessonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            })
            const result = await response.json()
            if (result.success) {
                await fetchLessons()
            } else {
                toast.error("Error in Unlocking Lesson")
            }
        }
        catch (error) {
            console.log("Error in Unlocking Lesson", error)
        }
    }

    return (
        <div>
            {
                !isReactNative() && <>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        // padding: "15px",
                        gap: "10px",
                    }}>

                        <LeaderboardComponent lessons={lessons as any} />
                        <TotalProgressComponent lessons={lessons as any} />
                    </div>

                    <Stack
                        width={{ xs: "100%", sm: "auto" }}
                        direction={{ xs: "column", md: "row" }}
                        justifyContent={"space-between"}
                        alignItems="center"
                        spacing={2}
                        sx={{
                            margin: "10px 0px",
                        }}
                    >
                        <Stack direction={{ xs: "column", sm: "row" }} alignItems={"center"} spacing={2}>
                            {
                                CLASS_DATA.length !== 0 ?
                                    <>
                                        <TextField
                                            select
                                            size="small"
                                            sx={{ width: "120px", height: "40px" }}
                                            label="Class"
                                            value={classValue}
                                            onChange={handleCloseClass}
                                        >
                                            <MenuItem key={""} value={""} style={{
                                                height: "40px"
                                            }}>
                                                {"Unselect"}
                                            </MenuItem>
                                            {CLASS_DATA.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {
                                            DIVISION_DATA.length !== 0 ? <TextField
                                                select
                                                size="small"
                                                sx={{ width: "120px", height: "40px" }}
                                                label={"Division"}
                                                value={divisionValue}
                                                onChange={handleCloseDivision}
                                            >
                                                <MenuItem key={""} value={""} style={{
                                                    height: "40px"
                                                }}>
                                                    {"Unselect"}
                                                </MenuItem>
                                                {
                                                    DIVISION_DATA.length !== 0 && DIVISION_DATA.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                            </TextField> :
                                                <Typography sx={{ maxWidth: 160 }} style={{ marginTop: "0 !important" }}>You do not have any class in this standard</Typography>
                                        }
                                    </>
                                    :
                                    <Typography sx={{ maxWidth: 160 }}>No Class Assigned</Typography>
                            }
                        </Stack>

                        <Stack direction={{ xs: "column", sm: "row" }} alignItems={"center"} spacing={2}>
                            {
                                CourseOTP &&
                                <Button color='inherit' style={{
                                    borderRadius: "10px",
                                    // background: eduCOBOTColorScheme.green,
                                    fontSize: "1.5em"
                                }} onClick={() => {
                                    navigator.clipboard.writeText(CourseOTP)
                                    toast.success("OTP copied to clipboard")
                                }}>
                                    {CourseOTP}
                                </Button>
                            }
                            {CourseOTP != undefined && CourseOTP && classValue && divisionValue ? (
                                <>

                                </>
                            ) : (
                                <>
                                    <Button variant='contained' onClick={() => {
                                        generateOTP()
                                    }} sx={{ maxWidth: 160, borderRadius: 3 }} size="large" color="primary" disabled={((CourseOTP != undefined && CourseOTP) || DIVISION_DATA.length === 0 || divisionValue.toLowerCase() == "division" || divisionValue.toLowerCase() == "")} >
                                        Generate OTP
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </Stack>
                </>

            }

            {
                lessons && lessons.length > 0 && classValue && divisionValue && lessons.filter((lesson) => lesson.isInCurrentStandard).length == 0 && <>
                    <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px",
                        backgroundColor: eduCOBOTColorScheme.red,
                        color: "white"
                    }}>
                        No Lessons Assigned to this class
                    </Card>
                </>
            }
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: settings.screen === "mobile" ? "column-reverse" : "row",
                padding: "15px"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                }}>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "all" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("all")}>
                        All
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "theory" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("theory")}>
                        Theory
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "assembly" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("assembly")}>
                        Assembly
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "circuit" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("circuit")}>
                        Circuit
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "micropython" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("micropython")}>
                        Code
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "iot" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("iot")}>
                        IOT
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter === "ai" ? isReactNative() ? "outlined" : "contained" : isReactNative() ? "contained" : "outlined"} color={isReactNative() ? "secondary" : "primary"} onClick={() => setCurrentFilter("ai")}>
                        AI
                    </Button>
                    {/* <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter !== "breadboard" ? isReactNative()?"outlined":"contained" :isReactNative()?"contained": "outlined"} color={isReactNative()?"secondary":"primary"} onClick={() => setCurrentFilter("breadboard")}>
                        Breadboard
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: isReactNative() ? "5px" : "20px",
                    }} variant={currentFilter !== "informative" ? isReactNative()?"outlined":"contained" :isReactNative()?"contained": "outlined"} color={isReactNative()?"secondary":"primary"} onClick={() => setCurrentFilter("informative")}>
                        Informative
                    </Button> */}
                </div>
                <div style={{
                    width: "100%",
                    margin: "10px 0px",
                    maxWidth: "400px",
                    display: "flex",
                    alignItems: "center",
                }}>
                    <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} size='small' color={isReactNative() ? "secondary" : "primary"} fullWidth label="Search lessons" variant="outlined" />
                    <Select
                        variant='outlined'
                        color={isReactNative() ? "secondary" : "primary"}
                        size='small'
                        value={currentGrade}
                        onChange={(e) => setCurrentGrade(parseInt(e.target.value as string))}
                        sx={{
                            //             borderRadius: isReactNative() ? "5px" : "20px",
                            width: "150px",
                            color: eduCOBOTColorScheme.light_blue,
                        }}
                        label="Select Grade"
                    >
                        <MenuItem value={"-1"}>All</MenuItem>
                        <MenuItem value={"0"}>Pre Primary</MenuItem>
                        <MenuItem value={"1"}>Grade-1</MenuItem>
                        <MenuItem value={"2"}>Grade-2</MenuItem>
                        <MenuItem value={"3"}>Grade-3</MenuItem>
                        <MenuItem value={"4"}>Grade-4</MenuItem>
                        <MenuItem value={"5"}>Grade-5</MenuItem>
                        <MenuItem value={"6"}>Grade-6</MenuItem>
                        <MenuItem value={"7"}>Grade-7</MenuItem>
                        <MenuItem value={"8"}>Grade-8</MenuItem>
                        <MenuItem value={"9"}>Grade-9</MenuItem>
                        <MenuItem value={"10"}>Grade-10</MenuItem>
                    </Select>
                </div>
            </div>



            <div style={{
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "start",
                flexWrap: "wrap",
            }}>
                {
                    lessons.map((lesson) => {
                        if (typeof lesson.isInCurrentStandard === "boolean" && !lesson.isInCurrentStandard && classValue) return null

                        return (
                            <LessonCard key={lesson._id} title={lesson.name} image={lesson.thumbnail} lesson={lesson} CourseOTP={CourseOTP} Unlock={UnlockLesson} standard={classValue} division={divisionValue} />
                        )
                        // return <h1>hello</h1>
                    })
                }
                {
                    lessons.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>
                        <p style={{ textAlign: "center" }}>No Lesson Found</p>
                    </Card>
                }
            </div>
        </div>
    );
}

function LessonCard({ image, title, lesson, CourseOTP, Unlock, standard, division }: {
    image: string, title: string, lesson: LessonWithTeacherUnlockStatusType, CourseOTP: string | undefined, Unlock: (lesson: LessonWithTeacherUnlockStatusType) => void,
    standard: string, division: string

}) {
    const navigate = useNavigate()
    const [isHovering, setIsHovering] = React.useState(false)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isReadMore, setIsReadMore] = React.useState(false)
    const [isReportOpen, setIsReportOpen] = React.useState(false)
    const { user } = useContext(UserContext)
    function openLesson() {
        navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
    }
    const [reportData, setReportData] = React.useState<{
        studentId: string
        studentName: string
        studentRollNo: number
        studentProgress: any,
        isCompleted: boolean,
        isAttempted: boolean
    }[]>()

    async function getReportData() {
        handleGlobalLoading(true, "Fetching Lesson Progress")
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLessonProgressReportDetailsAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lessonId: lesson.lessonId,
                    schoolId: user.userData?.schoolId,
                    standard: standard,
                    division: division,
                    OTP: CourseOTP
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    setReportData(result.data)
                }
            }
        } catch (error) {
            console.log(error);

        }
        handleGlobalLoading(false)
    }

    useEffect(() => {
        if (isReportOpen) {
            if (lesson._id && standard && division)
                getReportData()
        }

    }, [lesson, standard, division, isReportOpen])

    const getShadow = (lesson: LessonWithTeacherUnlockStatusType) => {
        if (lesson.isExpired) {
            return "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)";
        } else if (
            lesson.nextLessonToUnlock ||
            lesson.isOpenAndActive
        ) {
            return "0px 8px 16px 0px rgba(51, 102, 255, 0.24)";
        } else
            return "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.2)";
    }

    const getButtonsJSX = (
        className: string,
    ) => {
        if (lesson.nextLessonToUnlock && CourseOTP) {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        variant="contained"
                        fullWidth
                        color="inherit"
                        sx={className == "blueCard" ? { marginY: "10px", backgroundColor: "#fff", color: "black", borderRadius: "7px", } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => isReactNative() ? sendMessageToReactNative(
                            lesson,
                            "lesson"
                        ) : openLesson()}
                    >
                        Open
                    </Button>
                    <Button
                        className="unlockLessonBtn"
                        variant="contained"
                        fullWidth
                        color="error"
                        sx={{ marginY: "10px", borderRadius: "7px" }}
                        onClick={() => Unlock(lesson)}
                    >
                        <EducCOBOTLockIcon fill1="#fff" fill2="#fff" width="20px" height="20px" />
                    </Button>
                </>
            );
        }
        else if (lesson.isOpenAndActive) {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        fullWidth
                        variant="contained"
                        color="inherit"
                        sx={className == "blueCard" ? { marginY: "10px", backgroundColor: "#fff", color: "black", borderRadius: "7px" } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => isReactNative() ? sendMessageToReactNative(
                            lesson,
                            "lesson"
                        ) : openLesson()}
                    >
                        Open
                    </Button>
                </>
            );
        }

        // locked and expired
        else if (!lesson.isOpenAndActive && lesson.isExpired && CourseOTP) {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        variant={"contained"}
                        fullWidth
                        color="inherit"
                        sx={className == "blueCard" ? { marginY: "10px", backgroundColor: "#fff", color: "black", borderRadius: "7px" } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => isReactNative() ? sendMessageToReactNative(
                            lesson,
                            "lesson"
                        ) : openLesson()}
                    >
                        Open
                    </Button>
                    <Button
                        className="unlockLessonBtn"
                        variant={isHovering ? "contained" : "outlined"}
                        fullWidth
                        color="error"
                        sx={{ marginY: "10px", borderRadius: "7px" }}
                        onClick={() => {
                            Unlock(lesson);
                        }}
                    >
                        <EducCOBOTLockIcon
                            fill1={isHovering ? "#FFF" : "#FF4842"}
                            fill2={isHovering ? "#FFF" : "#FF4842"}
                            width="20px"
                            height="20px"
                        />
                    </Button>
                </>
            );
        }
        else if (
            CourseOTP
        ) {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        variant={"contained"}
                        fullWidth
                        color="inherit"
                        sx={className == "blueCard" ? { marginY: "10px", backgroundColor: "#fff", color: "black", borderRadius: "7px" } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => isReactNative() ? sendMessageToReactNative(
                            lesson,
                            "lesson"
                        ) : openLesson()}
                    >
                        Open
                    </Button>
                    <Button
                        className="unlockLessonBtn"
                        variant="contained"
                        fullWidth
                        color="error"
                        sx={{ marginY: "10px", borderRadius: "7px" }}
                        onClick={() => Unlock(lesson)}
                    >
                        <EducCOBOTLockIcon fill1="#FFF" fill2="#FFF" width="20px" height="20px" />
                    </Button>
                </>
            );
        }

        else {
            return (
                <>
                    <Button
                        className="openLessonBtn"
                        variant={"contained"}
                        fullWidth
                        color="inherit"
                        sx={className == "blueCard" ? { marginY: "10px", backgroundColor: "#fff", color: "black", borderRadius: "7px" } : { marginY: "10px", borderRadius: "7px" }}
                        onClick={() => isReactNative() ? sendMessageToReactNative(
                            lesson,
                            "lesson"
                        ) : openLesson()}
                    >
                        Open
                    </Button>
                </>
            );
        }
    };
    return (
        <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{
            width: isReactNative() ? "max(300px, 100%)" : "min(350px, 100%)",
            minHeight: "min(250px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: isReactNative() ? "5px" : "10px",
            margin: "10px",
            background: true ? GetCardColor(lesson.lessonNumber - 1) : lesson.nextLessonToUnlock || lesson.isOpenAndActive ? "linear-gradient(135deg, #84A9FF 0%, #1939B7 100%)" : isHovering ? eduCOBOTColorScheme.green : undefined,
            color: true ? "black" : lesson.nextLessonToUnlock || lesson.isOpenAndActive ? "white" : isHovering ? "white" : undefined,
            border: isReactNative() ? "1px solid #ccc" : undefined,
            backdropFilter: "blur(2px)",
            padding: "20px 10px",
            transition: "all 0.2s ease-in-out",
            boxShadow: getShadow(lesson),
        }}
            sx={{
                ":hover": {
                    scale: isReactNative() ? "1" : "1.1",
                }
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "2px",
                padding: "10px"
            }}>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    {
                        lesson.lessonNumber
                    }
                </div>
                <h4 style={{
                    width: "min(200px, 100%)",
                    // color: eduCOBOTColorScheme.black,
                    textAlign: "center",

                }} >
                    {title.split(" ").map((word => word.toLowerCase())).map((word, index) => {
                        return word.slice(0, 1).toUpperCase() + word.slice(1)
                    }).join(" ")}
                </h4>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    G
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                gap: "2px"
            }}>
                {
                    lesson.tags.map((tag, index) => {
                        return (
                            <p key={index} style={{
                                textAlign: "center",
                                color: isHovering ? eduCOBOTColorScheme.black : eduCOBOTColorScheme.gray,
                                background: isHovering ? eduCOBOTColorScheme.white : "#F2F3F5",
                                borderRadius: "15px",
                                margin: "5px 2px",
                                padding: "3px 10px",
                                fontSize: "0.8em",
                            }} >
                                {tag}
                            </p>
                        )
                    })
                }
            </div>
            <img src={image} alt="" style={{
                maxWidth: "80%",
                maxHeight: "120px",
                margin: "10px auto"
            }} />

            <p style={{
                fontSize: "0.8em",
                // color: isHovering ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.gray,
                padding: "10px 0px",
                textAlign: "justify"
            }}>
                {isReadMore ? lesson.description : lesson.description.slice(0, 100)} <Button onClick={() => setIsReadMore(!isReadMore)} size='small' variant='text' sx={{ textTransform: "none", color: lesson.nextLessonToUnlock || lesson.isOpenAndActive ? "#FFC0CB" : undefined }}>
                    {isReadMore ? "Read Less" : "Read More"}
                </Button>
            </p>
            {
                (lesson.isOpenAndActive || lesson.isExpired) && standard && division && <>
                    <div style={{
                        width: "100%",
                        padding: "0 10px",
                        display: "flex",
                        gap: "10px",
                    }}>
                        <Button
                            className="viewReportBtn"
                            variant={lesson.isExpired && !isHovering ? "outlined" : "contained"}
                            color="warning"
                            fullWidth
                            sx={{ fontWeight: 900 }}
                            onClick={() => setIsReportOpen(true)}
                        >
                            View Report
                        </Button>
                    </div>
                </>
            }

            <div style={{
                width: "100%",
                padding: "0 10px",
                display: "flex",
                gap: "10px",
            }}>
                {getButtonsJSX("blueCard")}
                {/* <Button size='small' fullWidth onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
                }} variant={'outlined'} sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    color: settings.themeMode === "dark" ? eduCOBOTColorScheme.black : undefined,
                    background: eduCOBOTColorScheme.white,
                    ":hover": {
                        background: eduCOBOTColorScheme.white,
                    }
                }}>
                    Open
                </Button> */}
                <ContentDialogBox
                    isOpen={isReportOpen}
                    onClose={() => setIsReportOpen(false)}
                    title={"Report for:-" + lesson.name}
                    fullScreen
                    isTransition
                    transitionDirection='left'
                    content={
                        <>
                            <Button variant='contained' color='warning' sx={{
                                position: "fixed",
                                top: "10px",
                                right: "10px"
                            }} onClick={() => {
                                setIsReportOpen(false)
                            }}>
                                <CloseIcon />
                            </Button>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                                gap: "10px",
                            }}>
                                {
                                    reportData && reportData.length > 0 ?
                                        reportData.sort(
                                            (a, b) => a.studentRollNo - b.studentRollNo
                                        ).map((data, index) => {
                                            return (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                    padding: "10px",
                                                    margin: "5px 0px",
                                                    borderRadius: "10px",
                                                    boxShadow: "0 0 5px #ccc",
                                                    // backgroundColor: !data.studentProgress ? "red" : eduCOBOTColorScheme.light_blue,
                                                    // color: "white",
                                                    minHeight: "200px"
                                                }}>
                                                    <div style={{

                                                    }}>
                                                        {
                                                            !data.studentProgress ?
                                                                <UserRed width="220px" height="220px" /> :
                                                                data.studentProgress && !data.isCompleted ?
                                                                    <UserYellow width="220px" height="220px" /> :
                                                                    <UserGreen width="220px" height="220px" />
                                                        }
                                                    </div>
                                                    <div style={{
                                                        position: "absolute",
                                                        top: "65%",
                                                        left: "50%",
                                                        // padding: "5px",
                                                        transform: "translate(-50%, -50%)",
                                                        borderRadius: "10px",
                                                        fontSize: "2em",
                                                        color: "white",
                                                    }}>
                                                        <p>{data.studentRollNo}</p>

                                                    </div>
                                                    <div>
                                                        <p>{data.studentName}</p>
                                                    </div>
                                                </div>
                                            )
                                        }) : !settings.globalLoading && <Card style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "30vh",
                                            margin: "10px",
                                            padding: "10px",
                                            boxShadow: "0 0 5px #ccc",
                                            borderRadius: "10px"
                                        }}>
                                            <p style={{ textAlign: "center" }}>No Students Found</p>
                                        </Card>
                                }
                            </div>

                        </>
                    }
                />


            </div>

        </Card>
    )
}



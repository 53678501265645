import React from "react";
import { Card, CardContent, Typography, List, ListItem, CardActions, Button } from "@mui/material";

const PythonIDEDownload: React.FC = () => {
    const version = "v5.0.0";
    const features = [
        "Connect Microcontrollers seamlessly for embedded programming.",
        "Run and configure MicroPython scripts for IoT tasks.",
        "Support for standard Python scripting and debugging.",
        "Efficient debugging tools for enhanced productivity.",
    ];

    return (
        <Card style={{ maxWidth: 600, margin: "2rem auto", padding: "1rem" }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Innovator IDE 
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Innovator IDE is a versatile development environment designed for working with microcontrollers and Python-based projects.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Key Features:
                </Typography>
                <List>
                    {features.map((feature, index) => (
                        <ListItem key={index} style={{ paddingLeft: 0 }}>
                            {feature}
                        </ListItem>
                    ))}
                </List>
                <Typography variant="body2" gutterBottom>
                    Current Version: {version}
                </Typography>
            </CardContent>
            <CardActions>

                <Button variant="contained" color="primary" href="https://github.com/innovator-robotics/innovator-ide" target="_blank" rel="noopener noreferrer">
                    Download for Windows
                    <img src="https://cdn-icons-png.flaticon.com/512/888/888882.png" style={{
                        width: "20px",
                        marginLeft: "5px"
                    }} alt="" />
                </Button>
                <Button variant="contained" color="primary" href="https://github.com/innovator-robotics/innovator-ide" target="_blank" rel="noopener noreferrer">
                    Download for macOS
                    <img src="https://cdn-icons-png.flaticon.com/512/2/2235.png" style={{
                        width: "20px",
                        marginLeft: "5px"
                    }} alt="" />
                </Button>
            </CardActions>
        </Card>
    );
};

export default PythonIDEDownload;
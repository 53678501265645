import React, { useState } from "react";
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Box } from "@mui/material";
import GetCardColor from "../../../utils/GetCardColor";

interface CardProps {
    image: string;
    title: string;
    description: string;
    onOpen: () => void;
    type?: string;
    index: number;
}

const HowItWorkResourceCard: React.FC<CardProps> = ({ image, title, description, onOpen, type, index }) => {
    const [flipped, setFlipped] = useState(false);

    return (
        <Box
            sx={{
                perspective: "1000px",
                display: "inline-block",
            }}
            onMouseEnter={() => setFlipped(true)}
            onMouseLeave={() => setFlipped(false)}
        >
            <Box
                sx={{
                    position: "relative",
                    width: 300,
                    minHeight: 450,
                    transformStyle: "preserve-3d",
                    transition: "transform 0.6s",
                    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
                    color: "black",
                }}
            >
                {/* Front Side */}
                <Card
                    sx={{
                        position: "absolute",
                        width: "100%",
                        // height: "100%",
                        background: GetCardColor(index),
                        color: "black",
                        backfaceVisibility: "hidden",
                        borderRadius: 3,
                        boxShadow: 3,
                        padding: 2,
                        
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={image} style={{
                            maxWidth: "280px",
                            maxHeight: "200px",
                        }} alt="" />
                    </div>
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 0 }}>
                            {description.slice(0, 150)}...
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ borderRadius: "5px", textTransform: "none", fontWeight: "bold", px: 4 }}

                        >
                            {type}
                        </Button>
                    </CardActions>
                </Card>

                {/* Back Side */}
                <Card
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "info.main",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "rotateY(180deg)",
                        backfaceVisibility: "hidden",
                        borderRadius: 3,
                        boxShadow: 3,
                        // background: "linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 100%)",
                        color: "black",                
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                        <img src={"https://storage.googleapis.com/educobot-robotics/images/set-1/cute-artificial-intelligence-robot-isometric-icon.png"} style={{
                            maxWidth: "300px",
                            maxHeight: "200px",
                        }} alt="" />
                    </div>
                    <Typography variant="h5" sx={{ fontWeight: "bold", position: "absolute", top: "20px", maxWidth: "80%", textAlign: "center" }}>
                        {title}
                    </Typography>
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        sx={{ borderRadius: "5px", textTransform: "none", fontWeight: "bold", position: "absolute", bottom: "30px", padding: "10px 50px", fontSize: "1.5rem", width: "60%" }}
                        onClick={onOpen}
                    >
                        Open
                    </Button>
                </Card>
            </Box>
        </Box>
    );
};

export default HowItWorkResourceCard;
import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { routes } from './routes'
import HomePage from '../pages/Home/index';
import DashboardMainPage from '../pages/Dashboard/Main';
import ProjectsPage from '../pages/Dashboard/Projects';
import BlueprintsPage from '../pages/Dashboard/Lessons';
import ToolsPage from '../pages/Dashboard/Tools';

import SettingsPage from '../pages/Dashboard/Settings';
import DashboardModelsPage from '../pages/Dashboard/Models';
import ProjectDescriptionPage from '../pages/Dashboard/Projects/Project/Description';
import CreateProjectPage from '../pages/Dashboard/Projects/Project/CreateProject';
import ProjectSettingsPage from '../pages/Dashboard/Projects/Project/ProjectSettings';
import CircuitryWorkspacePage from '../pages/Dashboard/Projects/Project/Workspace/CircuitryWorkspace';
import MicroPythonWorkspacePage from '../pages/Dashboard/Projects/Project/Workspace/MicropythonWorkspace';
import MonitoringWorkspacePage from '../pages/Dashboard/Projects/Project/Workspace/MonitoringWorkspace';
import AIEditorPage from '../pages/Dashboard/Projects/Project/Workspace/AIWorkspace';
import CreateModelPage from '../pages/Dashboard/Models/CreateModel';
import ModelWorkSpacePage from '../pages/Dashboard/Models/Workspace';
import LessonsPage from '../pages/Dashboard/Lessons';
import LessonTheoryPage from '../pages/Dashboard/Lessons/Lesson/Workspace/Theory';
import LessonAssemblyPage from '../pages/Dashboard/Lessons/Lesson/Workspace/Assemby';
import CircuitryLessonWorkspacePage from '../pages/Dashboard/Lessons/Lesson/Workspace/Circuitary';
import MicropythonLessonWorkspacePage from '../pages/Dashboard/Lessons/Lesson/Workspace/Micropython';
import CircuitDesignerPage from '../pages/Tools/CircuitDesigner/CircuitDesigner';
import LessonIOTMonitorWorkspacePage from '../pages/Dashboard/Lessons/Lesson/Workspace/Monitor';
import LogicGateSimulatorPage from '../pages/Tools/LogicGateSimulator';
import LessonDashboardPage from '../pages/Dashboard/Lessons/Lesson/Overview';
import SubmitLessonPage from '../pages/Dashboard/Lessons/Lesson/SubmitLesson/SubmitLesson';
import LessonBuilderLessons from '../pages/Dashboard/LessonBuilder/Lessons';
import CreateLessonBuilderLessonPage from '../pages/Dashboard/LessonBuilder/Create';
import MicroPythonEditorPage from '../pages/Tools/MicropythonEditor';
import LessonBuilderLessonPage from '../pages/Dashboard/LessonBuilder/Workspace';
import AIHandDrawingPage from '../pages/Dashboard/FunWithAI/Lessons/AIHandDrawing';
import BreadboardDesignerPage from '../pages/Tools/BreadBoardDesigner';
import RockPaperScissorPage from '../pages/Dashboard/FunWithAI/Lessons/RockPaperScissor';
import FunWithAIPage from '../pages/Dashboard/FunWithAI';
import LEDsONFingerPage from '../pages/Dashboard/FunWithAI/Lessons/LEDsOnHand';
import BreadBoardDesigningLessonWorkspacePage from '../pages/Dashboard/Lessons/Lesson/Workspace/Breadboard';
import ComponentInformativeLessonPage from '../pages/Dashboard/Lessons/Lesson/Workspace/ComponentInformative';
import MicroPythonMobileEditorPage from '../pages/Tools/MobileCodeEditor';
import LessonsOTPDashboardPage from '../pages/Dashboard/OTPDashboard/Lessons';
import HowItWorksPage from '../pages/Dashboard/HowItWorks';
import PlantDiseaseDetectorPage from '../pages/Dashboard/FunWithAI/Lessons/PlantDiseaseDetector';
import FunWithGamingPage from '../pages/Dashboard/FunWithGaming';
import FunWithRoboticsPage from '../pages/Dashboard/FunWithRobotics';
import FunWithRoboticsLessonPage from '../pages/Dashboard/FunWithRobotics/Lesson';
import FunWithGamingLessonPage from '../pages/Dashboard/FunWithGaming/Lesson';
import MyCoursesPage from '../pages/Dashboard/MyCourses';
import HardwareComponentsPage from '../pages/Dashboard/HardwareComponents';

function Router() {
    return (
        <Routes>
            <Route path={routes.ROOT} element={<Navigate to={routes.DASHBOARD.MAIN} replace={true} />} />
            <Route path={routes.DASHBOARD.MAIN} element={<DashboardMainPage />} />
            <Route path={routes.DASHBOARD.MY_COURSES} element={<MyCoursesPage />} />
            <Route path={routes.DASHBOARD.PROJECTS} element={<ProjectsPage />} />
            <Route path={routes.DASHBOARD.HARDWARE_COMPONENTS} element={<HardwareComponentsPage />} />
            <Route path={routes.DASHBOARD.PROJECT.CREATE} element={<CreateProjectPage />} />
            <Route path={routes.DASHBOARD.PROJECT.DESCRIPTION(':projectId')} element={<ProjectDescriptionPage />} />
            <Route path={routes.DASHBOARD.PROJECT.SETTINGS(':projectId')} element={<ProjectSettingsPage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.CIRCUIT(':projectId')} element={<CircuitryWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.MICROPYTHON(':projectId')} element={<MicroPythonWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.MONITOR(':projectId')} element={<MonitoringWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.AI(':projectId')} element={<AIEditorPage />} />
            <Route path={routes.DASHBOARD.MODEL.CREATE} element={<CreateModelPage />} />
            <Route path={routes.DASHBOARD.MODEL.WORKSPACE(":modelId")} element={<ModelWorkSpacePage />} />
            <Route path={routes.DASHBOARD.MODELS} element={<DashboardModelsPage />} />

            <Route path={routes.FUN_WITH_AI.ROOT} element={<FunWithAIPage />} />
            <Route path={routes.FUN_WITH_GAMING.ROOT} element={<FunWithGamingPage />} />
            <Route path={routes.FUN_WITH_ROBOTICS.ROOT} element={<FunWithRoboticsPage />} />

            <Route path={routes.DASHBOARD.TOOLS} element={<ToolsPage />} />
            <Route path={routes.DASHBOARD.SETTINGS} element={<SettingsPage />} />

            <Route path={routes.TOOLS.MICROPYTHON_EDITOR} element={<MicroPythonEditorPage />} />
            <Route path={routes.TOOLS.CIRCUIT_DESIGNER} element={<CircuitDesignerPage />} />
            <Route path={routes.TOOLS.LOGIC_GATE_SIMULATOR} element={<LogicGateSimulatorPage />} />
            <Route path={routes.TOOLS.BREAD_BOARD_SIMULATOR} element={<BreadboardDesignerPage />} />
            <Route path={routes.TOOLS.MOBILE_APP_CODE_EDITOR} element={<MicroPythonMobileEditorPage />} />

            <Route path={routes.FUN_WITH_ROBOTICS.LESSON(":lesson_slug")} element={<FunWithRoboticsLessonPage />} />
            <Route path={routes.FUN_WITH_GAMING.LESSON(":lesson_slug")} element={<FunWithGamingLessonPage />} />


            <Route path={routes.FUN_WITH_AI.LESSONS.ROCK_PAPER_SCISSOR} element={<RockPaperScissorPage />} />
            <Route path={routes.FUN_WITH_AI.LESSONS.AI_HAND_DRAWER} element={<AIHandDrawingPage />} />
            <Route path={routes.FUN_WITH_AI.LESSONS.LED_ON_HAND} element={<LEDsONFingerPage />} />
            <Route path={routes.FUN_WITH_AI.LESSONS.PLANT_DISEASE_DETECTOR} element={<PlantDiseaseDetectorPage />} />


            <Route path={routes.DASHBOARD.LESSONS} element={<LessonsPage />} />
            <Route path={routes.DASHBOARD.HOW_IT_WORKS} element={<HowItWorksPage />} />
            <Route path={routes.DASHBOARD.LESSON.DASHBOARD(":lessonId")} element={<LessonDashboardPage />} />
            <Route path={routes.DASHBOARD.LESSON_OTP_SECTION(":userId", ":OTP", ":standard", ":division")} element={<LessonsOTPDashboardPage />} />
            <Route path={routes.DASHBOARD.LESSON.SUBMIT(":lessonId")} element={<SubmitLessonPage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.THEORY(":lessonId")} element={<LessonTheoryPage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.ASSEMBLY(":lessonId")} element={<LessonAssemblyPage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(":lessonId")} element={<CircuitryLessonWorkspacePage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(":lessonId")} element={<MicropythonLessonWorkspacePage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.MONITOR(":lessonId")} element={<LessonIOTMonitorWorkspacePage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.BREADBOARD(":lessonId")} element={<BreadBoardDesigningLessonWorkspacePage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.COMPONENT_INFORMATIVE(":lessonId")} element={<ComponentInformativeLessonPage />} />


            <Route path={routes.DASHBOARD.LESSON_BUILDER_LESSON_LIST} element={<LessonBuilderLessons />} />
            <Route path={routes.DASHBOARD.LESSON_BUILDER_LESSON_CREATE} element={<CreateLessonBuilderLessonPage />} />
            <Route path={routes.DASHBOARD.LESSON_BUILDER.WORKSPACE(":lessonId")} element={<LessonBuilderLessonPage />} />

            {/* <Route path={routes.NOTFOUND} element={<div>Not Found</div>} /> */}
            <Route path={routes.WILDCARD} element={<Navigate to={routes.DASHBOARD.MAIN} replace={true} />} />
        </Routes>
    )
}

export default Router
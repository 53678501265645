const HardwareComponents: { 
    name: string; 
    image: string; 
    description: string; 
    type: string; 
    backgroundColor: string; 
    price: number;
    amazonLink: string 
}[] = [
    { 
        name: "Breadboard-400-Pins", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/Breadboard_400_Pin.svg", 
        description: "A 400-pin breadboard used for prototyping electronic circuits.", 
        type: "Circuit Component", 
        backgroundColor: "#330000", 
        price: 120, 
        amazonLink: "https://www.amazon.com/BB400-Solderless-Plug-BreadBoard-tie-points/dp/B0040Z1ERO/"
    },
    { 
        name: "New-eduCOBOT-Shield", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/New_Sheild.svg", 
        description: "The new eduCOBOT shield featuring the ESP32 microcontroller.", 
        type: "Microcontroller", 
        backgroundColor: "#003300", 
        price: 750, 
        amazonLink: "https://www.amazon.com/HiLetgo-ESP32-WROOM-32-Development-Microcontroller-Integrated/dp/B0718T232Z/"
    },
    { 
        name: "esp32", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg", 
        description: "ESP32 microcontroller module with Wi-Fi and Bluetooth capabilities.", 
        type: "Microcontroller", 
        backgroundColor: "#000033", 
        price: 450, 
        amazonLink: "https://www.amazon.com/ESP32-WROOM-32-Development-ESP-32S-Bluetooth-Arduino/dp/B08D5ZD528/"
    },
    { 
        name: "IR-Sensor", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/IR%20Sensor.svg", 
        description: "An infrared sensor used for detecting obstacles and motion.", 
        type: "Sensor", 
        backgroundColor: "#331A00", 
        price: 80, 
        amazonLink: "https://www.amazon.com/HiLetgo-Infrared-Avoidance-Reflective-Photoelectric/dp/B07W97H2WS/"
    },
    { 
        name: "Touch-Sensor", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Touch+Sensor.svg", 
        description: "A touch sensor used to detect physical touch or proximity.", 
        type: "Sensor", 
        backgroundColor: "#1A0033", 
        price: 90, 
        amazonLink: "https://www.amazon.com/HiLetgo-TTP223B-Capacitive-Digital-Touch/dp/B00HFQEFWU/"
    },
    { 
        name: "LDR-Sensor", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/LDR_PCB.svg", 
        description: "A light-dependent resistor (LDR) sensor used to measure light intensity.", 
        type: "Sensor", 
        backgroundColor: "#1A331A", 
        price: 65, 
        amazonLink: "https://www.amazon.com/HiLetgo-Photosensitive-Resistance-Photoresistor-Photoelectric/dp/B00N1Y4GJW/"
    },
    { 
        name: "Tactile-Switch", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Switch.svg", 
        description: "A tactile switch used for user input in electronic circuits.", 
        type: "Circuit Component", 
        backgroundColor: "#331A33", 
        price: 25, 
        amazonLink: "https://www.amazon.com/BOJACK-Momentary-Tactile-Button-Switch/dp/B07ZBHXBK8/"
    },
    { 
        name: "DHT11-Sensor", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DHT11.svg", 
        description: "A DHT11 sensor used for measuring temperature and humidity.", 
        type: "Sensor", 
        backgroundColor: "#002633", 
        price: 135, 
        amazonLink: "https://www.amazon.com/HiLetgo-Temperature-Humidity-Arduino-Raspberry/dp/B01DKC2GQ0/"
    },
    { 
        name: "MQ2-Sensor", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Smoke-Sensor.png", 
        description: "An MQ2 sensor used for detecting gases like LPG, smoke, alcohol, and carbon monoxide.", 
        type: "Sensor", 
        backgroundColor: "#332600", 
        price: 180, 
        amazonLink: "https://www.amazon.com/HiLetgo-Sensor-Module-Arduino-Raspberry/dp/B07L711YNW/"
    },
    { 
        name: "UltraSonic", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Ultrasonic.svg", 
        description: "An ultrasonic sensor used for measuring distance by using ultrasonic waves.", 
        type: "Sensor", 
        backgroundColor: "#260033", 
        price: 120, 
        amazonLink: "https://www.amazon.com/HC-SR04-Ultrasonic-Distance-Measuring-Sensor/dp/B07TKVPPHF/"
    },
    { 
        name: "RFID", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RFID.svg", 
        description: "An RFID module used for reading and writing RFID tags.", 
        type: "Sensor", 
        backgroundColor: "#0D3300", 
        price: 240, 
        amazonLink: "https://www.amazon.com/HiLetgo-RFID-Kit-Arduino-Raspberry/dp/B01CSTW0IA/"
    },
    { 
        name: "Keypad", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Key_Pad.svg", 
        description: "A keypad module used for user input in electronic projects.", 
        type: "Circuit Component", 
        backgroundColor: "#0D0033", 
        price: 160, 
        amazonLink: "https://www.amazon.com/Gikfun-Matrix-Keypad-Arduino-EK1911/dp/B07B4DR5SL/"
    },
    { 
        name: "Soil-Moisture", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Soil-Moisture.png", 
        description: "A soil moisture sensor used for measuring the moisture level in soil.", 
        type: "Sensor", 
        backgroundColor: "#331A0D", 
        price: 110, 
        amazonLink: "https://www.amazon.com/Gikfun-Capacitive-Corrosion-Resistant-Detection/dp/B07H3P1NRM/"
    },
    { 
        name: "DC-Motor", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DC+Motor.svg", 
        description: "A DC motor used for rotational motion in robotic projects.", 
        type: "Actuator", 
        backgroundColor: "#1A1A33", 
        price: 85, 
        amazonLink: "https://www.amazon.com/Electrical-Motor-Magnetic-Helicopter-Accessories/dp/B07TTVBGXC/"
    },
    { 
        name: "RGB-LED", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RGB_LED.svg", 
        description: "An RGB LED module used for displaying multiple colors.", 
        type: "Actuator", 
        backgroundColor: "#4D0000", 
        price: 45, 
        amazonLink: "https://www.amazon.com/Tricolor-Multicolor-Arduino-Raspberry-Brightness/dp/B01C19ENLC/"
    },
    { 
        name: "OLED", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/OLED_1.svg", 
        description: "An OLED display module used for displaying text and graphics.", 
        type: "Display", 
        backgroundColor: "#003300", 
        price: 320, 
        amazonLink: "https://www.amazon.com/SSD1306-Display-Arduino-Raspberry-Yellow-Blue/dp/B0B1NBKXWB/"
    },
    { 
        name: "LCD", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/LCD.png", 
        description: "An LCD display module used for displaying text and simple graphics.", 
        type: "Display", 
        backgroundColor: "#0D001A", 
        price: 280, 
        amazonLink: "https://www.amazon.com/SunFounder-Serial-Module-Display-Arduino/dp/B07TLRFKPX/"
    },
    { 
        name: "Accelerometer", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ADXL345.svg", 
        description: "An accelerometer sensor used for measuring acceleration and orientation.", 
        type: "Sensor", 
        backgroundColor: "#00331A", 
        price: 190, 
        amazonLink: "https://www.amazon.com/HiLetgo-ADXL345-Gravity-Acceleration-Raspberry/dp/B01DLG4OU4/"
    },
    { 
        name: "Dot-Matrix", 
        image: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Dot-matrix.png", 
        description: "A dot matrix display module used for displaying text and graphics.", 
        type: "Display", 
        backgroundColor: "#1A2600", 
        price: 295, 
        amazonLink: "https://www.amazon.com/KEYESTUDIO-Display-MAX7219-Arduino-Raspberry/dp/B07BVWM9HY/"
    },
    { 
        name: "BO-Motor", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/BO%20Motor.svg", 
        description: "A BO motor used for rotational motion in small robotic projects.", 
        type: "Actuator", 
        backgroundColor: "#260D00", 
        price: 90, 
        amazonLink: "https://www.amazon.com/Gikfun-Tyres-Wheels-Arduino-EK1353/dp/B07KXVJ2TP/"
    },
    { 
        name: "Servo", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Servo.svg", 
        description: "A servo motor used for precise control of angular position.", 
        type: "Actuator", 
        backgroundColor: "#331A4D", 
        price: 175, 
        amazonLink: "https://www.amazon.com/MG996R-Torque-Digital-Helicopter-ASIATOY/dp/B09PQJL3XN/"
    },
    { 
        name: "7-Segment", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/set-1/Seven%20Segment.svg", 
        description: "A 7-segment display module used for displaying numbers.", 
        type: "Display", 
        backgroundColor: "#1A0033", 
        price: 95, 
        amazonLink: "https://www.amazon.com/EDGELEC-segments-Segment-Display-Digital/dp/B07CK9DXFQ/"
    },
    { 
        name: "AND-GATE", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/AND%20Gate.svg", 
        description: "An AND gate used for logical operations in digital circuits.", 
        type: "ICs", 
        backgroundColor: "#2D2D00", 
        price: 30, 
        amazonLink: "https://www.amazon.com/Bridgold-74LS08-Quad-Input-Pack/dp/B08CXRG23P/"
    },
    { 
        name: "OR-GATE", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/OR%20Gate.svg", 
        description: "An OR gate used for logical operations in digital circuits.", 
        type: "ICs", 
        backgroundColor: "#332D1A", 
        price: 30, 
        amazonLink: "https://www.amazon.com/Bridgold-74LS32-Quad-Input-Pack/dp/B08CXRG23W/"
    },
    { 
        name: "NOT-GATE", 
        image: "https://storage.googleapis.com/educobot-robotics/images/robotic-components/breadboard/NOT%20GATE.svg", 
        description: "A NOT gate used for logical operations in digital circuits.", 
        type: "ICs", 
        backgroundColor: "#262626", 
        price: 30, 
        amazonLink: "https://www.amazon.com/Bridgold-74LS04-Inverter-Pack/dp/B08CXRG234/"
    }
];

export default HardwareComponents
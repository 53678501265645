import React from 'react'
import { LessonDataType } from '../../types/Lesson'
import { ContentDialogBox } from '../dialog-box'
import { Button, TextField } from '@mui/material'

type Props = {
    lessonData: LessonDataType,
    circuitJSON: any
}

function CircuitLessonBuilder({
    lessonData,
    circuitJSON
}: Props) {
    const [steps, setSteps] = React.useState<{
        title: string
        description: string
        valid: string
        image: string
        video: string
        audio: string
    }[]>([])
    const [isPreview, setIsPreview] = React.useState(false)
    const [description, setDescription] = React.useState('Welcome to breadboard lesson, Rather than interaction, follow the lesson, press next button and next connection will appear, then follow it in physically amd connect it accordingly')
    const [circuitJSONs, setCircuitJSONs] = React.useState<string[]>([])
    function downloadFile(filename: string, text: string) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    return (
        <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 999999999,
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                backgroundColor: "white",
            }}>
                <TextField
                    multiline
                    rows={8}
                    label="Description"
                    variant="outlined"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    sx={{
                        width: '300px'
                    }}
                />
                <Button variant='contained' onClick={() => {
                    setSteps((pre) => {
                        return [...pre, {
                            title: `Step ${pre.length}`,
                            description: description,
                            valid: "",
                            image: '',
                            video: '',
                            audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/default/step-${pre.length}.mp3`
                        }]
                    })
                    setCircuitJSONs((pre) => {
                        return [...pre, circuitJSON]
                    })
                    setDescription('')
                }}>
                    Add to Step
                </Button>
                <Button variant='contained' onClick={() => {
                    setIsPreview(true)
                }}>
                    Preview
                </Button>
                {/* <Button variant='contained' onClick={() => {
                    setSteps((pre) => {
                        return pre.slice(0, pre.length - 1)
                    })
                    setCircuitJSONs((pre) => {
                        return pre.slice(0, pre.length - 1)
                    })
                }}>
                    Remove Last Step
                </Button> */}
                {/* <Button variant='contained' onClick={() => {
                    setSteps((pre) => {
                        return [...pre, {
                            title: `Step ${pre.length}`,
                            description: "Your Circuit is Completed",
                            valid: "",
                            image: '',
                            video: '',
                            audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/default/step-${pre.length}.mp3`
                        }]
                    })
                    setCircuitJSONs((pre) => {
                        return [...pre, circuitJSON]
                    })
                    setDescription('')
                }}>End</Button> */}
                <Button variant='contained' onClick={() => {
                    downloadFile(lessonData.lessonId + '.json', JSON.stringify({
                        circuitJSONs,
                        default: steps,
                        hindi: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/hindi/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        english: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/english/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        tamil: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/tamil/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        telugu: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/telugu/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        french: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/french/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        spanish: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/spanish/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        italian: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/italian/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        swahili: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/swahili/step-${steps.indexOf(step)}.mp3`
                            }
                        }),
                        chinese: steps.map(step => {
                            return {
                                ...step,
                                audio: `https://educobot-robotics.s3.ap-south-1.amazonaws.com/audios/circuit/${lessonData.lessonId}/chinese/step-${steps.indexOf(step)}.mp3`
                            }
                        })
                    }))
                }}>Download</Button>

            </div>


            <ContentDialogBox
                isOpen={isPreview}
                title={lessonData.name}
                onClose={() => {
                    setIsPreview(false)
                }}
                content={<>
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col items-center">
                            <h3 className="text-2xl font-bold">{lessonData.name}</h3>
                            <p>{lessonData.description}</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <h1 className="text-xl font-bold">Steps</h1>
                            {steps.map((step, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <h1 className="text-lg font-bold">{step.title}</h1>
                                    <p>{step.description}</p>
                                    {step.image && <img src={step.image} alt={step.title} />}
                                    {step.video && <video src={step.video} controls />}
                                    {step.audio && <audio src={step.audio} controls />}
                                </div>
                            ))}
                        </div>
                    </div>
                </>}
            />
        </div>
    )
}

export default CircuitLessonBuilder
// Robi Chatbot

import { Button, Card, FormControl, IconButton, InputAdornment, Switch, Typography } from '@mui/material';
import * as React from 'react';
import googleGeminiGenerativeAPIService from '../../services/gemini-ai';
import { toast } from 'react-toastify'
import SendIcon from '@mui/icons-material/Send';
import ChatComponent from './ChatComponent';
import { ThreeDots } from 'react-loader-spinner';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { UserContext } from '../../context/UserContext';
import CloseIcon from '@mui/icons-material/Close';
import { eduCOBOTColorScheme, SettingContext } from '../../context/SettingsContext';
export type Props = {
}

export function ChatBotContainer(props: Props) {
    const { user } = React.useContext(UserContext)
    const chatScrollRef = React.useRef<HTMLDivElement>();
    const [isActive, setIsActive] = React.useState(false);
    const [textInput, setTextInput] = React.useState("");
    const [isThinking, setIsThinking] = React.useState(false)
    const [isMicroPythonRestricted, setIsMicroPythonRestricted] = React.useState(true)
    const [position, setPosition] = React.useState({ x: 50, y: 50 });
    const { settings } = React.useContext(SettingContext)
    const bindLessonCircuitPlayer = useDrag((parameters) => {
        setPosition(() => {
            return {
                x: parameters.offset[0],
                y: parameters.offset[1]
            }
        })
    }, { filterTaps: true, delay: true });

    const [interactions, setInteractions] = React.useState<{
        text: string,
        fromUser: boolean,
    }[]>([

    ])
    React.useEffect(() => {
        setInteractions(() => {
            return [{
                "text": `Hello ${user.userData?.name}, I'm SpongeBOT, your very own eduCOBOT guide. I am happy to be of assistance in helping you with your queries in your coding journey.`,
                "fromUser": false
            }]
        })
    }, [user])
    function scrollToEnd() {
        if (chatScrollRef && chatScrollRef.current) {

            const scroll_height = chatScrollRef.current.scrollHeight
            chatScrollRef.current.scrollTo(0, scroll_height)
        }
    }
    async function getResponse() {
        setIsThinking(true);
        if (textInput.length < 5) {
            toast.warning("Question is too short")
        } else {
            setTextInput("")
            setInteractions(prev => [...prev, { text: textInput, fromUser: true }])
            // scrollToEnd()
            const response = await googleGeminiGenerativeAPIService.getPromptResponse(isMicroPythonRestricted ? textInput + " in micropython in simple way" : textInput + " in simple way")
            console.log(response);
            console.log([...interactions, { text: response, fromUser: false }]);

            setInteractions(prev => [...prev, { text: response, fromUser: false }])
            // scrollToEnd()
        }
        setIsThinking(false);
    }
    React.useEffect(() => {
        scrollToEnd()
    }, [interactions])
    if (!isActive) {
        return <>
            <div style={{
                position: "fixed",
                bottom: 5,
                right: "0",
                width: "70px",
                zIndex: 99999999999
            }}>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "6fr 3fr",
                    height: isActive ? "100vh" : "auto",
                    background: isActive ? "#47C5DD" : "",
                }}>

                    <Typography sx={{
                        transition: "all 0.1s ease-out",
                        transformOrigin: "right bottom",
                        display: "flex",
                        flexDirection: "column",
                        background: "#47C5DD",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: isActive ? 2 : "1px",
                        maxWidth: isActive ? "100%" : "50px",
                        maxHeight: isActive ? "100%" : "50px",
                        borderRadius: !isActive ? "10px" : "0",
                    }}>
                        <img onClick={() => {
                            setIsActive(!isActive);
                        }} src="https://storage.googleapis.com/educobot-statics/SpongeBOT_Blue.svg" style={{
                            maxWidth: isActive ? "90%" : "80px",
                            maxHeight: isActive ? "90%" : "80px",
                            cursor: "pointer",
                            transition: "all 2s ease-out",
                            transformOrigin: "right bottom",
                            borderRadius: "10px",
                        }} alt="" />
                    </Typography>
                </div>
            </div>
        </>
    }
    else if (isActive && settings.screen === "mobile") {
        return <animated.div {...bindLessonCircuitPlayer()} style={{
            width: "100vw",
            top: "0",
            left: "0",
            touchAction: 'none',
            position: "fixed",
            zIndex: 9999999999
        }} >
            <Card sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer",
                width: "100vw",
                borderRadius: "10px",
                position: "relative",
                height: "100dvh",
                zIndex: 9999999999,
                border: "1px solid #47C5DD"

            }}>
                <div style={{
                    display: "flex",
                    background: "#47C5DD",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 10px",
                    height: "10dvh"
                }}>
                    <div onClick={() => {
                        setIsActive(!isActive);
                    }} style={{
                        display: "flex",
                        alignItems: "center",
                    }}>

                        <img src="https://storage.googleapis.com/educobot-statics/SpongeBOT_Blue.svg" style={{
                            maxWidth: "40px",
                            maxHeight: "40px",
                            cursor: "pointer",
                            transition: "all 2s ease-out",
                            transformOrigin: "right bottom",
                        }} alt="" />
                        <img src="https://storage.googleapis.com/educobot-statics/SpongeBOT.svg" style={{
                            width: "100px",
                            transition: "all 2s ease-out",
                            transformOrigin: "right bottom",
                        }} alt="" />
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px"
                    }}>
                        <Button onClick={() => {
                            setIsMicroPythonRestricted(true);
                        }} style={{
                            width: "20px",
                            minWidth: "20px",
                        }} >
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/MicroPython_new_logo.svg/768px-MicroPython_new_logo.svg.png" style={{
                                maxWidth: "15px",

                            }} alt="" />
                        </Button>
                        <div onClick={() => {
                            setIsMicroPythonRestricted(!isMicroPythonRestricted);
                        }} style={{
                            width: "50px",
                            height: "20px",
                            border: "1px solid #00000055",
                            backgroundColor: "#A9D1E2",
                            borderRadius: "40px",
                            position: "relative",
                            cursor: "pointer",
                        }}>
                            <div style={{
                                position: "absolute",
                                left: !isMicroPythonRestricted ? "31px" : "2.2px",
                                top: "1px",
                                width: "16px",
                                height: "16px",
                                border: "1px solid #00000055",
                                backgroundColor: "#202C62",
                                borderRadius: "40px",
                                transition: "all 0.2s linear",
                            }}></div>
                        </div>
                        <Button onClick={() => {
                            setIsMicroPythonRestricted(false);
                        }} style={{
                            width: "30px",
                            minWidth: "30px",
                        }}>
                            <img src="/images/arduino.png" style={{
                                maxWidth: "30px"
                            }} alt="" />
                        </Button>
                        <Button variant='contained' color='warning' onClick={() => {
                            setIsActive(false)
                        }}>
                            <CloseIcon />
                        </Button>
                    </div>
                </div>

                <div style={{
                    // background: "red",
                    display: "flex",
                    // justifyContent: "center",
                    flexDirection: "column",
                    // alignItems: "center",
                    cursor: "pointer",
                    flexBasis: "100%",
                    maxHeight: "80dvh",

                }}>



                    <div ref={(ref) => {
                        chatScrollRef.current = ref as HTMLDivElement;
                    }} style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        cursor: "pointer",
                        flexDirection: "column",
                        overflowY: "scroll",
                        scrollBehavior: "smooth",
                    }}>
                        {
                            interactions.map((interaction, index) => {
                                return <ChatComponent text={interaction.text} key={index} fromUser={interaction.fromUser} />;
                            })
                        }


                        {
                            isThinking && <>
                                <div style={{
                                    width: "100%",
                                    padding: "20px 50px",
                                }}>

                                    <ThreeDots
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#2065D1"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                            </>
                        }





                    </div>
                </div>


                <div style={{
                    display: "flex",
                    height: "10dvh",
                    // borderTop: "1px solid #47C5DD"
                }}>
                    <FormControl onSubmit={(event) => {
                        event.preventDefault();
                        getResponse()
                    }} style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        // padding: "3px",
                    }}>

                        <OutlinedInput
                            disabled={isThinking}
                            type='text'
                            value={textInput}
                            onChange={(event) => {
                                setTextInput(event.target.value)
                            }} style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                // color: "white",
                                fontSize: 18,
                                // padding: 5,
                                cursor: "pointer",
                            }}
                            placeholder='Ask anything whatever you want'
                            // label="Ask anything what you want"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        color='primary'
                                        onClick={getResponse}
                                        edge="end"
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            }

                        />

                    </FormControl>

                </div>





            </Card>

        </animated.div>
    } else {
        return <animated.div {...bindLessonCircuitPlayer()} style={{
            x: position.x,
            y: position.y,
            maxWidth: "800px",
            width: "100%",
            top: "5%",
            left: "50%",
            touchAction: 'none',
            position: "fixed",
            zIndex: 9999999999
        }} >
            <Card sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // alignItems: "center",
                cursor: "pointer",
                width: "100%",
                maxWidth: "700px",
                borderRadius: "10px",
                position: "relative",
                // padding: "10px",
                height: "90vh",
                zIndex: 9999999999,
                border: "1px solid #47C5DD"

            }}>
                <div style={{
                    display: "flex",
                    background: "#47C5DD",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 10px",
                    height: "8vh"
                }}>
                    <div onClick={() => {
                        setIsActive(!isActive);
                    }} style={{
                        display: "flex",
                        alignItems: "center",
                    }}>

                        <img src="https://storage.googleapis.com/educobot-statics/SpongeBOT_Blue.svg" style={{
                            maxWidth: "40px",
                            maxHeight: "40px",
                            cursor: "pointer",
                            transition: "all 2s ease-out",
                            transformOrigin: "right bottom",
                        }} alt="" />
                        <img src="https://storage.googleapis.com/educobot-statics/SpongeBOT.svg" style={{
                            width: "100px",
                            transition: "all 2s ease-out",
                            transformOrigin: "right bottom",
                        }} alt="" />
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px"
                    }}>
                        <Button onClick={() => {
                            setIsMicroPythonRestricted(true);
                        }} style={{
                            width: "20px",
                            minWidth: "20px",
                        }} >
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/MicroPython_new_logo.svg/768px-MicroPython_new_logo.svg.png" style={{
                                maxWidth: "15px",

                            }} alt="" />
                        </Button>
                        <div onClick={() => {
                            setIsMicroPythonRestricted(!isMicroPythonRestricted);
                        }} style={{
                            width: "50px",
                            height: "20px",
                            border: "1px solid #00000055",
                            backgroundColor: "#A9D1E2",
                            borderRadius: "40px",
                            position: "relative",
                            cursor: "pointer",
                        }}>
                            <div style={{
                                position: "absolute",
                                left: !isMicroPythonRestricted ? "31px" : "2.2px",
                                top: "1px",
                                width: "16px",
                                height: "16px",
                                border: "1px solid #00000055",
                                backgroundColor: "#202C62",
                                borderRadius: "40px",
                                transition: "all 0.2s linear",
                            }}></div>
                        </div>
                        <Button onClick={() => {
                            setIsMicroPythonRestricted(false);
                        }} style={{
                            width: "30px",
                            minWidth: "30px",
                        }}>
                            <img src="/images/arduino.png" style={{
                                maxWidth: "30px"
                            }} alt="" />
                        </Button>
                        <Button variant='contained' color='warning' onClick={() => {
                            setIsActive(false)
                        }}>
                            <CloseIcon />
                        </Button>
                    </div>
                </div>

                <div style={{
                    // background: "red",
                    display: "flex",
                    // justifyContent: "center",
                    flexDirection: "column",
                    // alignItems: "center",
                    cursor: "pointer",
                    flexBasis: "100%",
                    maxHeight: "75vh",

                }}>



                    <div ref={(ref) => {
                        chatScrollRef.current = ref as HTMLDivElement;
                    }} style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        cursor: "pointer",
                        flexDirection: "column",
                        overflowY: "scroll",
                        scrollBehavior: "smooth",
                    }}>
                        {
                            interactions.map((interaction, index) => {
                                return <ChatComponent text={interaction.text} key={index} fromUser={interaction.fromUser} />;
                            })
                        }


                        {
                            isThinking && <>
                                <div style={{
                                    width: "100%",
                                    padding: "20px 50px",
                                }}>

                                    <ThreeDots
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#2065D1"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                            </>
                        }





                    </div>
                </div>


                <div style={{
                    display: "flex",
                    height: "7vh",
                    // borderTop: "1px solid #47C5DD"
                }}>
                    <FormControl onSubmit={(event) => {
                        event.preventDefault();
                        getResponse()
                    }} style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        // padding: "3px",
                    }}>

                        <OutlinedInput
                            disabled={isThinking}
                            type='text'
                            value={textInput}
                            onChange={(event) => {
                                setTextInput(event.target.value)
                            }} style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                // color: "white",
                                fontSize: 18,
                                // padding: 5,
                                cursor: "pointer",
                            }}
                            placeholder='Ask anything you want'
                            // label="Ask anything what you want"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        color='primary'
                                        onClick={getResponse}
                                        edge="end"
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            }

                        />

                    </FormControl>

                </div>





            </Card>

        </animated.div>
    }

}



import React, { useContext, useState } from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardTopbar from './DashboardTopBar'
import { SettingContext } from '../../context/SettingsContext'
import { SwippleDrawer } from '../../components/drawers'
type Props = {
    children?: React.ReactNode,
    title?: string
}

function DashboardLayout(props: Props) {
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    const [isNavBarOpen, setIsNavBarOpen] = useState(settings.screen === "desktop" ? true : false)
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: settings.screen === "mobile" ? "1fr" : "2fr 10fr",
                height: "100vh",
                width: "100%",
                overflow: "auto",
            }}
        >
            {
                settings.screen === "mobile" ?
                    <SwippleDrawer
                        isOpen={isNavBarOpen}
                        onClose={() => setIsNavBarOpen(false)}
                    >
                        <DashboardNavbar />
                    </SwippleDrawer>
                    :
                    <DashboardNavbar />
            }
            <div style={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                overflow: "auto",
                // padding: "0 10px",
                // background: "url(https://storage.googleapis.com/educobot-robotics/images/IOT/IOT-Background.gif) no-repeat",
                // backgroundSize: "cover",
                zIndex: -1,
                opacity: 0.6,
            }}>

            </div>
            <div style={{
                height: "100vh",
                overflow: "auto",
                // padding: "0 10px",
                // width: "100%",
                background: `${settings.themeMode==="dark"?" linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))":"linear-gradient(to bottom, rgba(255, 258, 258, 0.8), rgba(255, 258, 258, 0.8))"}, url(https://storage.googleapis.com/educobot-robotics/images/IOT/IOT-Background.gif) no-repeat `,
                backgroundSize: "cover",
                // filter: "blur(3px) brightness(0.5)",

            }}>
                <DashboardTopbar title={props.title as string} toggleNavBarOpen={() => {
                    setIsNavBarOpen(!isNavBarOpen)
                }} />
                <div style={{
                    height: "94vh",
                    overflow: "auto",
                    padding: "10px",
                }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout
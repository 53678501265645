function GetCardColor(lessonNumber: number) {
    if (lessonNumber % 16 == 0) return "linear-gradient(45deg, #21CBF3, #21CBF3)";
    if (lessonNumber % 16 == 1) return "linear-gradient(45deg, #FE6B8B, #FF8E53)";
    if (lessonNumber % 16 == 2) return "linear-gradient(45deg, #FF8E53, #FF4500)";
    if (lessonNumber % 16 == 3) return "linear-gradient(45deg, #21CBF3, #00FF7F)";
    if (lessonNumber % 16 == 4) return "linear-gradient(45deg, #FF6B6B, #FFD700)";
    if (lessonNumber % 16 == 5) return "linear-gradient(45deg, #21CBF3, #4ECDC4)";
    if (lessonNumber % 16 == 6) return "linear-gradient(45deg, #FF6347, #FFD700)";
    if (lessonNumber % 16 == 7) return "linear-gradient(45deg, #7B68EE, #00FF7F)";
    if (lessonNumber % 16 == 8) return "linear-gradient(45deg, #FF1493, #FFDAB9)";
    if (lessonNumber % 16 == 9) return "linear-gradient(45deg, #FF69B4, #FF4500)";
    if (lessonNumber % 16 == 10) return "linear-gradient(45deg, #FF4500, #FFC0CB)";
    if (lessonNumber % 16 == 11) return "linear-gradient(45deg, #4ECDC4, #21CBF3)";
    if (lessonNumber % 16 == 12) return "linear-gradient(45deg, #FF83FA, #FFD700)";
    if (lessonNumber % 16 == 13) return "linear-gradient(45deg, #FF69B4, #21CBF3)";
    if (lessonNumber % 16 == 14) return "linear-gradient(45deg, #FFDAB9, #00FF00)";
    if (lessonNumber % 16 == 15) return "linear-gradient(45deg, #00FF7F, #87CEEB)";
    return "linear-gradient(45deg, #FFFFFF, #000000)";
}
export default GetCardColor


import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import { LessonDataType } from '../../../types/Lesson';
import { SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { APIResponse } from '../../../types/Response';
import { UserContext } from '../../../context/UserContext';
import TeacherLessons from './TeacherLessons/TeacherLessons';
import StudentLessons from './StudentLessons/StudentLessons';
import SAASLessons from './SAASLessons/Lessons';
import { isReactNative } from '../../../utils/ReactNativeHandler';

export type LessonsPageProps = {
}



export default function LessonsPage(props: LessonsPageProps) {
    const { settings, handleGlobalLoading } = React.useContext(SettingContext)
    const { user } = React.useContext(UserContext)
    const [currentLevel, setCurrentLevel] = React.useState(0);
    const [completedStep, setCompletedStep] = React.useState(0);
    const [screen, setScreen] = React.useState<"Level" | "Lesson">("Level");
    if (!user || !user.userData) return null;

    if (isReactNative()) {
        return<div>
            {
                user.userData.role?.toLowerCase() === "teacher" ?
                    <TeacherLessons /> : user.userData.role?.toLowerCase() === "student" ? <StudentLessons /> : <SAASLessons />
            }
        </div>
    }
    return (
        <div>
            <DashboardLayout title={screen === "Level" ? "Curriculum" : `${currentLevel + 1} Level Lessons`}>
                {
                    user.userData.role?.toLowerCase() === "teacher" ?
                        <TeacherLessons /> : user.userData.role?.toLowerCase() === "student" ? <StudentLessons /> : <SAASLessons />
                }
            </DashboardLayout>
        </div>
    );
}
